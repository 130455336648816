import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CarCardComponent } from './car-card/car-card.component';
import { IonicRatingModule } from 'ionic-rating';
import { CommonKitModule } from '../common-kit/common-kit.module';
import { RouterModule } from '@angular/router';
import { AddOrEditCarContentComponent } from './add-or-edit-car-content/add-or-edit-car-content.component';
import { FormKitModule } from '../form-kit/form-kit.module';
import { CarsBlockComponent } from './cars-block/cars-block.component';

@NgModule({
  declarations: [CarCardComponent, AddOrEditCarContentComponent, CarsBlockComponent],
  imports: [
    CommonModule,
    CommonKitModule,
    IonicModule,
    FormKitModule,
    RouterModule,
    IonicRatingModule
  ],
  exports: [
    CarCardComponent,
    AddOrEditCarContentComponent,
    CarsBlockComponent
  ]
})
export class CarKitModule { }
