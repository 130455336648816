import { LoadingController } from "@ionic/angular";
import { CatchAllErrors } from "../static/errors";

export class LoadingClass {
    public completed = false;
    public error?: string;

    public loader: HTMLIonLoadingElement;

    constructor(public loadingController: LoadingController) {}

    @CatchAllErrors
    public async presentLoading(): Promise<void> {
        this.error = undefined;
        this.loader = await this.loadingController.create({
          message: 'Please wait...',
          backdropDismiss: false
        });
        await this.loader.present();
    }

    public stopLoading(): void {
        if (this.loader) {
            this.loader.remove();
        }
    }

    public complete() {
        this.stopLoading();
        this.completed = true;
        setTimeout(() => {
            this.completed = false;
        }, 2000 );
    }

    public setError(error?: string) {
        this.error = error;
        this.completed = false;
        this.stopLoading();
    }

    public reset() {
        this.stopLoading();
        this.error = undefined;
        this.completed = false;
    }

    public handleValueChange(): void {
        this.error = undefined;
    }
}