import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { forkJoin, Unsubscribable } from 'rxjs';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { CarClass } from 'src/app/classes/CarClass';
import { CarTrimClass } from 'src/app/classes/CarTrimClass';
import { LoadingClass } from 'src/app/classes/LoadingClass';
import { Equipment, Body, Model, ModelVariant } from 'src/app/interface/CarAdvert';
import { AdvertImage } from 'src/app/interface/Image';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { ModalService } from 'src/app/services/modal.service';
import { StoredDataService } from 'src/app/services/stored-data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { owners } from 'src/app/static/data';
import { DestroySubscribers, CombineSubscriptions } from 'src/app/static/destroySubscribers';
import { PlateNumberPattern, UKPostcodePattern } from 'src/app/static/patterns';
import * as stringSimilarity from 'string-similarity';

@Component({
  selector: 'app-add-or-edit-car-content',
  templateUrl: './add-or-edit-car-content.component.html',
  styleUrls: ['./add-or-edit-car-content.component.scss'],
})
@DestroySubscribers()
export class AddOrEditCarContentComponent extends LoadingClass implements OnInit, OnChanges, OnDestroy {
  @Input() currentAdvert?: CarClass;

  public firstFormGroup = new FormGroup({
    type: new FormControl(2),
    latitude: new FormControl(null, [Validators.required]),
    longitude: new FormControl(null, [Validators.required]),
    plateNumber: new FormControl(null, [Validators.pattern(PlateNumberPattern)]),
    address: new FormControl(null, [Validators.required, Validators.pattern(UKPostcodePattern)]),
    brandId: new FormControl(null, [Validators.required]),
    modelId: new FormControl(null, [Validators.required]),
    modelVariantId: new FormControl(null, [Validators.required]),
    year: new FormControl(null, [Validators.required]),
    bodyId: new FormControl(null, [Validators.required]),
    trimId: new FormControl(null, [Validators.required]),
    equipmentId: new FormControl(null, [Validators.required]),
    mileage: new FormControl()
  });

  public secondFormGroup = new FormGroup({
    fuelTypeId: new FormControl(null, [Validators.required]),
    transmissionId: new FormControl(null, [Validators.required]),
    gearBoxId: new FormControl(null, [Validators.required]),
    engine: new FormControl(null, [Validators.required]),
    power: new FormControl(null, [Validators.required]),
    colourId: new FormControl(null, [Validators.required])
  });

  public thirdFormGroup = new FormGroup({
    price: new FormControl(null, [Validators.required]),
    exchange: new FormControl(false),
    description: new FormControl(null, [Validators.required]),
    acceleration: new FormControl(),
    videoLink: new FormControl(),
    owners: new FormControl(),
    images: new FormControl([]),
    accident: new FormControl(false),
    manufactureProof: new FormControl(false),
    dealerGuarantee: new FormControl(false),
    delivery: new FormControl(false),
    callsAvailable: new FormControl(true),
    messagesAvailable: new FormControl(true),
    onlinePayment: new FormControl(false)
  });

  public options: {
    models: Model[];
    modelVariants: ModelVariant[];
    bodies: Body[];
    trims: CarTrimClass[];
    equipments: Equipment[];
  } = {
    models: [],
    modelVariants: [],
    bodies: [],
    trims: [],
    equipments: []
  };

  public page = 1;

  public owners = owners;

  public uploadedImages?: AdvertImage[];

  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  constructor(private api: ApiService, public commonData: StoredDataService, private geocoder: GeocodingService, private utils: UtilsService, loadingCtrl: LoadingController, private router: Router, private alertCtrl: AlertController, private auth: AuthService, private modalService: ModalService) {
    super(loadingCtrl);
  }

  ngOnInit(): void {
    if (this.currentAdvert) {
      this.loadCar(this.currentAdvert);
    }

    this._attachBrandListener();
    this._attachModelListener();
    this._attachModelVariantListener();
    this._attachBodyListener();
    this._attachTrimListener();
    this._attachAddressListener();
    this._attachOnlinePaymentListener();
  }

  private _attachBrandListener(): void {
    this.subscriber = this.firstFormGroup.controls.brandId.valueChanges.pipe(
      startWith(undefined),
      pairwise()
    ).subscribe(value => {
      this.loadModel(value[0], value[1]);
    });
  }

  private _attachModelListener(): void {
    this.subscriber = this.firstFormGroup.controls.modelId.valueChanges.pipe(
      startWith(undefined),
      pairwise()
    ).subscribe(value => {
      this.loadModelVariant(value[0], value[1]);
    });
  }

  private _attachModelVariantListener(): void {
    this.subscriber = this.firstFormGroup.controls.modelVariantId.valueChanges.pipe(
      startWith(undefined),
      pairwise()
    ).subscribe(value => {
      this.loadBody(value[0], value[1]);
    });
  }

  private _attachBodyListener(): void {
    this.subscriber = this.firstFormGroup.controls.bodyId.valueChanges.pipe(
      startWith(undefined),
      pairwise()
    ).subscribe(value => {
      this.loadTrim(value[0], value[1]);
    });
  }

  private _attachTrimListener(): void {
    this.subscriber = this.firstFormGroup.controls.trimId.valueChanges.pipe(
      startWith(undefined),
      pairwise()
    ).subscribe(value => {
      console.log(value, this.options.trims);
      this.loadEquipment(value[0], value[1]);
    });
  }

  private _attachAddressListener(): void {
    this.subscriber = this.firstFormGroup.controls.address.valueChanges.pipe(
      debounceTime(1000),
      startWith(undefined),
      pairwise()
    ).subscribe(value => {
      this.onPostcodeChange(value[0], value[1]);
    });
  }

  private _attachOnlinePaymentListener(): void {
    this.subscriber = this.thirdFormGroup.controls.onlinePayment.valueChanges.subscribe(value => {
      if (value) {
        this.auth.userData$.subscribe(userData => {
          if (!userData.stripeOnboardingComplete) {
            this.thirdFormGroup.controls.onlinePayment.patchValue(false);
            this.modalService.openPaymentVerificationMessage();
          }
        })
      }
    });
  }

  ngOnChanges(): void {
    if (this.currentAdvert) {
      this.loadCar(this.currentAdvert);
    }
  }

  ngOnDestroy(): void {}

  loadModel(oldBrand: number, newBrand: number): any {
    return new Promise((resolve) => {
      if (!this.firstFormGroup.controls.brandId.invalid && newBrand) {
        if (oldBrand !== newBrand) {
          this._resetModel();
          this._resetModelVariant();
          this._resetBody();
          this._resetTrim();
          this._resetEquipment();
          this.secondFormGroup.reset();
          this.firstFormGroup.controls.modelId.enable({emitEvent: false});
          this.api.getModelsById(newBrand, true).subscribe(value => {
            this.options.models = value;
            this.firstFormGroup.controls.modelId.enable({emitEvent: false});
            if (this.options.models.length === 1) {
              this.firstFormGroup.controls.modelId.patchValue(this.options.models[0].id);
            }
            resolve(true);
          });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    })

  }

  loadModelVariant(oldModel: number, newModel: number): any {
    console.log(oldModel, newModel);
    return new Promise((resolve) => {
      if (newModel && oldModel !== newModel) {
        this.firstFormGroup.controls.modelVariantId.reset(null, {emitEvent: false});
        this.options.modelVariants = [];
        this._resetBody();
        this._resetTrim();
        this._resetEquipment();
        this.secondFormGroup.reset();
        this.api.getModelVariantsById(newModel, true).subscribe(value => {
          this.options.modelVariants = value;
          this.firstFormGroup.controls.modelVariantId.enable({emitEvent: false});
          if (this.options.modelVariants.length === 1) {
            this.firstFormGroup.controls.modelVariantId.patchValue(this.options.modelVariants[0].id);
          }
          resolve(true);
          
        });
      } else {
        resolve(true);
      }
    })

  }

  loadBody(oldModelVariant: number, newModelVariant: number): any {
    return new Promise((resolve) => {

      if (newModelVariant && oldModelVariant !== newModelVariant) {
        this.firstFormGroup.controls.bodyId.reset(null, {emitEvent: false});
        this.options.bodies = [];
        this._resetTrim();
        this._resetEquipment();
        this.secondFormGroup.reset();
        this.api.getBodiesByModelVariant(newModelVariant).subscribe(value => {
          this.options.bodies = value;
          this.firstFormGroup.controls.bodyId.enable({emitEvent: false});
          if (this.options.bodies.length === 1) {
            this.firstFormGroup.controls.bodyId.patchValue(this.options.bodies[0].id);
          }
          resolve(true);
        });
      } else {
        resolve(true);
      }
    })
  }

  loadTrim(oldBody: number, newBody: number): any {
    return new Promise((resolve) => {

      if (newBody && oldBody !== newBody) {
        this.firstFormGroup.controls.trimId.reset(null, {emitEvent: false});
        this.options.trims = [];
        this._resetEquipment();
        this.secondFormGroup.reset();
        this.api.getTrimsByBody(newBody, this.commonData).subscribe(value => {
          this.options.trims = value;
          this.firstFormGroup.controls.trimId.enable({emitEvent: false});
          if (this.options.trims.length === 1) {
            this.firstFormGroup.controls.trimId.patchValue(this.options.trims[0].id);
          }
          resolve(true);
        });
      } else {
        resolve(true);
      }
    })
  }

  loadEquipment(oldTrim: number, newTrim: number): any {
    return new Promise((resolve) => {
      if (newTrim && oldTrim !== newTrim) {
        this.firstFormGroup.controls.equipmentId.reset(null, {emitEvent: false});
        this.options.equipments = [];
        this.secondFormGroup.reset();
        this.fillInSpecifications();
        this.api.getEquipmentsByTrim(newTrim).subscribe(value => {
          this.options.equipments = value;
          this.firstFormGroup.controls.equipmentId.enable({emitEvent: false});
          if (this.options.equipments.length === 1) {
            this.firstFormGroup.controls.equipmentId.patchValue(this.options.equipments[0].id);
          }
          resolve(true);
        });
      } else {
        resolve(true);
      }
    })
  }

  async onPostcodeChange(oldPostcode: string, newPostcode: string) {
    if (oldPostcode !== newPostcode) {
      if (!this.firstFormGroup.controls.address.invalid) {
        try {
          const location = await this.geocoder.geocode(newPostcode);
          this.firstFormGroup.controls.latitude.patchValue(location.lat);
          this.firstFormGroup.controls.longitude.patchValue(location.lng);
        } catch (err) {
          console.log(err);
          this.firstFormGroup.controls.address.setErrors({
            error: 'Error locating the postcode'
          });
        }
      } else {
        this.firstFormGroup.controls.latitude.reset();
        this.firstFormGroup.controls.longitude.reset();
      }
    }
  }

  public findCar(): void {
    if (!this.firstFormGroup.controls.plateNumber.value || this.firstFormGroup.controls.plateNumber.value === '') {
      return;
    }
    if (this.firstFormGroup.controls.plateNumber.invalid) {
      this.firstFormGroup.controls.plateNumber.markAsTouched();
      this.firstFormGroup.controls.plateNumber.setErrors({
        error: 'Plate number is incorrect'
      });
    } else {
      this.api.checkPlate(this.firstFormGroup.value.plateNumber).subscribe(response => {
        const mot = JSON.parse(response.mot);
        const ves = JSON.parse(response.ves);
        const totalCarCheck = JSON.parse(response.totalCarCheck);

        if (ves.make) {
          const brandSimilarity = stringSimilarity.findBestMatch(
            ves.make.toUpperCase(),
            this.commonData.car.brands.map(e => e.name.toUpperCase())
          );
          if (brandSimilarity) {
            this.firstFormGroup.controls.brandId.patchValue(this.commonData.car.brands[brandSimilarity.bestMatchIndex].id);
          }
        }

        if (ves.yearOfManufacture) {
          this.firstFormGroup.controls.year.patchValue(ves.yearOfManufacture);
        }

        if (ves.fuelType) {
          const fuelTypeSimilarity = stringSimilarity.findBestMatch(
            ves.fuelType.toUpperCase(),
            this.commonData.car.fuelTypes.map(e => e.name.toUpperCase())
          );
          if (fuelTypeSimilarity) {
            this.secondFormGroup.controls.fuelTypeId.patchValue(this.commonData.car.fuelTypes[fuelTypeSimilarity.bestMatchIndex].id);
          }
        }

        if (ves.colour) {
          const colourSimilarity = stringSimilarity.findBestMatch(ves.colour, this.commonData.colours.map(e => e.name));
          if (colourSimilarity) {
            this.secondFormGroup.controls.colourId.patchValue(this.commonData.colours[colourSimilarity.bestMatchIndex].id);
          }
        }

        
      });
    }
  }

  private fillInSpecifications(): void {
    const trim  = this.options.trims.find(e => e.id === this.firstFormGroup.controls.trimId.value);

    if (trim) {
      this.secondFormGroup.controls.fuelTypeId.patchValue(trim.fuelType);
      this.secondFormGroup.controls.power.patchValue(trim.power);
      this.secondFormGroup.controls.engine.patchValue(trim.engineSize);
      this.secondFormGroup.controls.transmissionId.patchValue(trim.drivetrain);
      this.secondFormGroup.controls.gearBoxId.patchValue(trim.gearbox);
      this.thirdFormGroup.controls.acceleration.patchValue(trim.acceleration);
    }
  }

  private _resetModel(): void {
    const model = this.firstFormGroup.controls.modelId;
    model.reset(null, {emitEvent: false});
    model.disable({emitEvent: false});
    this.options.models = [];
  }

  private _resetModelVariant(): void {
    const modelVariant = this.firstFormGroup.controls.modelVariantId;
    modelVariant.reset(null, {emitEvent: false});
    modelVariant.disable({emitEvent: false});
    this.options.modelVariants = [];
  }

  private _resetBody(): void {
    const body = this.firstFormGroup.controls.bodyId;
    body.reset(null, {emitEvent: false});
    body.disable({emitEvent: false});
    this.options.bodies = [];
  }

  private _resetTrim(): void {
    const trim = this.firstFormGroup.controls.trimId;
    trim.reset(null, {emitEvent: false});
    trim.disable({emitEvent: false});
    this.options.trims = [];
  }

  private _resetEquipment(): void {
    const equipment = this.firstFormGroup.controls.equipmentId;
    equipment.reset(null, {emitEvent: false});
    equipment.disable({emitEvent: false});
    this.options.equipments = [];
  }

  public reset(): void {
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.thirdFormGroup.reset();
  }

  public loadCar(currentAdvert?: CarClass): void {
    const car = currentAdvert || this.currentAdvert;
    if (car) {
      this.firstFormGroup.patchValue({
        latitude: car.location.y,
        longitude: car.location.x,
        type: car.type,
        plateNumber: car.plateNumber,
        address: car.address,
        brandId: car.brandId,
        modelId: car.modelId,
        modelVariantId: car.modelVariantId,
        year: car.year,
        bodyId: car.bodyId,
        trimId: car.trimId,
        equipmentId: car.equipmentId,
        mileage: car.mileage,
      });
      this.firstFormGroup.controls.modelId.enable();
      this.firstFormGroup.controls.modelVariantId.enable();
      this.firstFormGroup.controls.bodyId.enable();
      this.firstFormGroup.controls.trimId.enable();
      this.firstFormGroup.controls.equipmentId.enable();

      this.secondFormGroup.patchValue({
        fuelTypeId: car.fuelTypeId,
        transmissionId: car.transmissionId,
        gearBoxId: car.gearBoxId,
        power: car.power,
        colourId: car.colourId,
      });

      if (Number(car.engine) > 6.9) {
        this.secondFormGroup.controls.engine.patchValue('7');
      } else {
        const literFound: any = this.commonData.car.engines.find((e: any) =>
          e.name.startsWith((car as CarClass).engine.toString())
        );
        if (literFound) {
          this.secondFormGroup.controls.engine.patchValue(literFound.value);
        }
      }

      this.thirdFormGroup.patchValue({
        price: car.price,
        exchange: car.exchange,
        description: car.description,
        acceleration: car.acceleration ? car.acceleration.toString() : null,
        videoLink: car.videoLink,
        owners: car.owners,
        accident: car.accident,
        manufactureProof: !!car.manufactureProof,
        dealerGuarantee: !!car.dealerGuarantee,
        delivery: car.delivery,
        callsAvailable: car.callsAvailable,
        messagesAvailable: car.messagesAvailable,
        onlinePayment: car.onlinePayment
      });

      this.uploadedImages = car.images;

      forkJoin([
        this.api.getModelsById(this.currentAdvert.brandId, true),
        this.api.getModelVariantsById(this.currentAdvert.modelId, true),
        this.api.getBodiesByModelVariant(this.currentAdvert.modelVariantId),
        this.api.getTrimsByBody(this.currentAdvert.bodyId, this.commonData),
        this.api.getEquipmentsByTrim(this.currentAdvert.trimId)
      ]).subscribe(response => {
        this.options.models = response[0];
        this.options.modelVariants = response[1];
        this.options.bodies = response[2];
        this.options.trims = response[3];
        this.options.equipments = response[4];
      });
    }
  }

  changePage(page: number): void {
    this.page = page;
  }

  async submit(): Promise<void> {

    if (this.thirdFormGroup.invalid || (!this.thirdFormGroup.value.callsAvailable && !this.thirdFormGroup.value.messagesAvailable)) { return; }

    if (!this.thirdFormGroup.controls.images.value || (!this.currentAdvert && this.thirdFormGroup.controls.images.value.length < 3)) {
      this.thirdFormGroup.controls.images.setErrors({
        error: 'You need to add at least 3 images'
      });
      const alert = await this.alertCtrl.create({
        cssClass: 'my-custom-class',
        header: 'Check images',
        message: 'Check that you have at least 3 images and maximum 20.',
        buttons: ['OK']
      });
      return await alert.present();
      // return this.viewportScroller.scrollToAnchor('Images');
    }

    this.presentLoading();

    const brand = this.commonData.car.brands.find(e => e.id === this.firstFormGroup.value.brandId);
    const model = this.options.models.find(e => e.id === this.firstFormGroup.value.modelId);
    const body = this.options.bodies.find(e => e.id === this.firstFormGroup.value.bodyId);
    const trim = this.options.trims.find(e => e.id === this.firstFormGroup.value.trimId);

    // const location = await this.geocoder.addressToLatAndLng(this.firstFormGroup.value.address);
    const finance = this.utils.calculateFinance(this.thirdFormGroup.value.price);
    const name = brand.name + ' ' + model.name + ' ' + trim.name;

    const value = {
      bodyTypeId: body.bodyType.id,
      finance,
      name,
      order: this.currentAdvert ? this.currentAdvert.images.map(e => e.id).toString() : [],
      ...this.firstFormGroup.value,
      ...this.secondFormGroup.value,
      ...this.thirdFormGroup.value,
      dealerGuarantee: this.thirdFormGroup.value.dealerGuarantee ? '12-12-3000' : null,
      manufactureProof: this.thirdFormGroup.value.manufactureProof ? '12-12-3000' : null,
      mileage: this.firstFormGroup.value.type === 2 ? this.firstFormGroup.value.mileage : 0,
      owners: this.firstFormGroup.value.type === 2 ? this.thirdFormGroup.value.owners : 0,
    };

    if (this.currentAdvert) {
      this.api.editCar(this.currentAdvert.id, value).subscribe(response => {
        this.complete();
        this.router.navigate(['/car', this.currentAdvert.id], {state: {action: 'completed'}});
      }, error => this.setError(error));
    } else {
      this.api.addCar(value).subscribe(response => {
        this.complete();
        this.router.navigate(['/car', response.id], {state: {action: 'completed'}});
      }, error => this.setError(error));
    }
  }
}
