import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoUrlToIframeUrl'
})
export class VideoUrlToIframeUrlPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) {
      return null;
    }
    // if (value.includes('youtube')) { value = value.replace('//www.youtube.com/watch/', '//www.youtube.com/embed/'); }
    const regExp = /^.*(youtube\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = value.match(regExp);

    return (match && match[2]) ? '//www.youtube.com/embed/' + match[2] : null;
  }

}
