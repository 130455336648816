import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarClass } from 'src/app/classes/CarClass';
import { trackById } from 'src/app/static/utils';

@Component({
  selector: 'app-cars-block',
  templateUrl: './cars-block.component.html',
  styleUrls: ['./cars-block.component.scss'],
})
export class CarsBlockComponent implements OnInit {
  @Input() title?: string;
  @Input() headerButtonText?: string;
  @Input() headerButtonColor = 'primary';
  @Input() controlsEnabled = false;

  @Input() cars: CarClass[];

  @Output() headerButtonClick = new EventEmitter();
  @Output() closeButtonClick = new EventEmitter();

  trackBy = trackById;

  constructor() { }

  ngOnInit() {}

}
 