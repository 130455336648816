import { Component } from "@angular/core";

@Component({
  selector: 'app-see-more',
  template: `
      <div #ref [class.collapsed]="ref.innerText.length > 100 && isCollapsed">
          <ng-content></ng-content>
      </div>
      <div *ngIf="ref.innerText.length > 100" class="collapse-button" [class.collapsed]="isCollapsed" (click)="isCollapsed = !isCollapsed">{{!isCollapsed ? 'Hide' : 'See more'}}</div>
  `,
  styles: [`
      div {
        overflow: hidden;
        
      }
      div.collapsed {
          height: 100px;
          overflow: hidden;
      }
      div.collapse-button {
        position: relative;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 71%, rgba(255,255,255,0) 100%);
        padding: 15px;
        text-align: center;
        font-family: 'Nunito Sans';
        font-size: 14px;
        line-height: 19px;
        color: #0F71F0;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      div.collapse-button.collapsed{
        bottom: 0px;
        position: absolute;
        left: 0;
        right: 0;
      }
  `]
})

export class SeeMoreComponent {
  isCollapsed = true;
}