import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { BrandSelectModalPageRoutingModule } from './brand-select-modal-routing.module';

import { BrandSelectModalPage } from './brand-select-modal.page';
import { CommonKitModule } from 'src/app/kit/common-kit/common-kit.module';
import { FormKitModule } from 'src/app/kit/form-kit/form-kit.module';

@NgModule({
  imports: [
    CommonModule,
    CommonKitModule,
    FormKitModule,
    IonicModule,
    BrandSelectModalPageRoutingModule
  ],
  declarations: [BrandSelectModalPage],
  entryComponents: [
    BrandSelectModalPage
  ]
})
export class BrandSelectModalPageModule {}
