import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRHelperService {

  constructor(
    private network: NetworkService,
  ) { }

  public isHubDisconnected(hubConnection: signalR.HubConnection): boolean {
    return hubConnection && hubConnection.state === 'Disconnected';
  }

  public isHubDisconnecting(hubConnection: signalR.HubConnection): boolean {
    return hubConnection && hubConnection.state === 'Disconnecting';
  }

  public isHubReconnnecting(hubConnection: signalR.HubConnection): boolean {
    return hubConnection && hubConnection.state === 'Reconnecting';
  }

  public isHubConnected(hubConnection: signalR.HubConnection): boolean {
    return hubConnection && hubConnection.state === 'Connected';
  }

  public buildHub(url: string, params?: signalR.IHttpConnectionOptions): signalR.HubConnection {
    return new signalR.HubConnectionBuilder().withUrl(
      url,
      params
    ).build();
  }

  public reconnectOnNetworkAvailable(callback?: () => void): Observable<any> {
    return this.network.status$.pipe(
      filter(status => status === 'online'),
      take(1),
      tap(() => callback ? callback() : null)
    );
  }

  public connectionMonitorOnOnline(callback?: () => void): Observable<string> {
    return this.network.status$.pipe(
      filter(status => status === 'online'),
      tap(() => callback ? callback() : null)
    );
  }

  public destoryHub(hubConnection: signalR.HubConnection): void {
    if (hubConnection) {
      hubConnection.stop().then(() => hubConnection = undefined);
    }
  }

}
