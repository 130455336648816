import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { LikedCarsPageRoutingModule } from './liked-cars-routing.module';

import { LikedCarsPage } from './liked-cars.page';
import { CarKitModule } from 'src/app/kit/car-kit/car-kit.module';
import { CommonKitModule } from 'src/app/kit/common-kit/common-kit.module';
import { FormKitModule } from 'src/app/kit/form-kit/form-kit.module';

@NgModule({
  imports: [
    CommonModule,
    FormKitModule,
    CommonKitModule,
    IonicModule,
    CarKitModule,
    LikedCarsPageRoutingModule
  ],
  declarations: [LikedCarsPage]
})
export class LikedCarsPageModule {}
