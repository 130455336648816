import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { StripeService } from 'ngx-stripe';
import { Unsubscribable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoadingClass } from 'src/app/classes/LoadingClass';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { InAppBrowserService } from 'src/app/services/in-app-browser.service';
import { ModalService } from 'src/app/services/modal.service';
import { accountHolderType, country, currency, defaultCountry, defaultCurrency } from 'src/app/static/data';
import { CombineSubscriptions, DestroySubscribers } from 'src/app/static/destroySubscribers';
import { messages } from 'src/app/static/messages';
import { phoneHandler } from 'src/app/static/operators';
import { UKPhoneNumber } from 'src/app/static/patterns';

@Component({
  selector: 'app-verify-for-online-payment-form-modal',
  templateUrl: './verify-for-online-payment-form-modal.page.html',
  styleUrls: ['./verify-for-online-payment-form-modal.page.scss'],
})
@DestroySubscribers()
export class VerifyForOnlinePaymentFormModalPage extends LoadingClass implements OnInit, OnDestroy {
  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  public personalInfoFormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl('+44', [Validators.required, Validators.pattern(UKPhoneNumber)]),
    name: new FormControl(null, [Validators.required]),
  });

  public bankInfoFormGroup = new FormGroup({
    country: new FormControl(defaultCountry, [Validators.required]),
    currency: new FormControl(defaultCurrency, [Validators.required]),
    routing_number: new FormControl(null, [Validators.required]),
    account_number: new FormControl(null, [Validators.required]),
    account_holder_name: new FormControl(null, [Validators.required]),
    account_holder_type: new FormControl(null, [Validators.required])
  });

  accountHolderType = accountHolderType;
  currency = currency;
  country = country;

  page = 1;

  constructor(private auth: AuthService, private stripeService: StripeService, loadingCtrl: LoadingController, private api: ApiService, private modalCtrl: ModalController, private modalService: ModalService, public iab: InAppBrowserService) {
    super(loadingCtrl);
  }

  ngOnInit(): void {
    this.subscriber = this.personalInfoFormGroup.controls.phone.valueChanges.pipe(
      phoneHandler(this.personalInfoFormGroup.controls.phone)
    ).subscribe();
    this.auth.userData$.subscribe(userData => {
      if (userData) {
        this.personalInfoFormGroup.patchValue({
          phone: userData.phoneNumber,
          email: userData.email,
          name: userData.fullName,
        });
      }
    })
  }

  ngOnDestroy(): void {}

  close(): void {
    this.modalCtrl.dismiss();
  }

  submit(): void {
    this.presentLoading();

    this.stripeService
    .createToken('bank_account', this.bankInfoFormGroup.value)
    .subscribe((result) => {
      if (result.token) {
        this.sendRequest(result.token.id);
      } else if (result.error) {
        console.log(result.error.message);
        this.setError(this.error);
      }
    });
  }

  sendRequest(source: string): void {
    this.api.verifyForOnlinePayment({...this.personalInfoFormGroup.value, bankSource: source}).pipe(
      switchMap(() => this.auth.userData$)
    ).subscribe(() => {
      this.complete();
      this.close();
      this.modalService.openSuccess(messages.verificationSuccess);
    }, error => {
      this.setError(error?.message);
    });
  }
}