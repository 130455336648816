import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.page.html',
  styleUrls: ['./success-modal.page.scss'],
})
export class SuccessModalPage implements OnInit {
  @Input() message: string;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

}
