import Catch from 'catch-finally-decorator'

export const CatchAllErrors = Catch(Error, (err: any) => console.log(err.message));

export const errors = {
  phoneNumberIsTaken: {
    backend: 'Phone number is taken or invalid',
    frontend: 'Phone number is incorrect or is already taken. Please, try again.',
  },
  noPostcodeProvided: 'No postcode entered. Please, enter correct postcode'
};
