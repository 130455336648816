// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API = 'https://development.topcar.co.uk';

export const environment = {
  maintenance: false,
  production: false,
  googleMapsKey: 'AIzaSyC-XsRxi5ViWMoNQDoqD8JpCzVOQYODEII',
  googleAnalytics: 'UA-166923688-1',
  tokenLifetime: 60000 * 2.1,
  refreshInterval: 60000 * 5,

  API,

  /** Logs */
  logs: {
    error: {
      add: API + '/NodeService/Log/Error/Add',
      delete: API + '/NodeService/Log/Error/:id/Delete',
      complete: API + '/NodeService/Log/Error/:id/Complete',
      all: API + '/NodeService/Log/Error/All',
    }
  },

  /** Dashboard API */
  dashboardAPI: {
    main: API + '/Api/Dashboard',
    cars: API + '/Api/Dashboard/Cars'
  },

  /** Car reports APIs */
  carReportsAPI: {
    buy: API + '/Api/Cars/BuyCarReport',
    ownReports: API + '/Api/Cars/GetOwnCarReports',
    individualReport: API + '/Api/Cars/GetCarReport/:id',
    testReport: API + '/Api/Cars/TotalCarCheck'

  },

  /** Purchase APIs */
  purchaseAPI: {
    individual: API + '/Api/Purchase/:id',
    multiple: API + '/Api/Purchase',
    own: API + '/Api/Purchase/Own',
    close: API + '/Api/Purchase/Close/:id'
  },

  /** Contact APIs */
  contactAPI: {
    email: API + '/Api/Mailing/ContactDealer'
  },

  /** Disks APIs */
  disksAPI: {
    search: API + '/Api/Wheels/WheelDisks'
  },

  /** Tyre Manufacturers APIs */
  diskManufacturersAPI: {
    getAllManufacturers: API + '/Api/Wheels/WheelDiskManufacturers',
    individualManufacturer: API + '/Api/Wheels/WheelDiskManufacturers/:id'
  },

  /** Tyres APIs */
  tyresAPI: {
    search: API + '/Api/Wheels/Tyres',
  },

  /** Tyre Manufacturers APIs */
  tyreManufacturersAPI: {
    getAllManufacturers: API + '/Api/Wheels/TireManufacturers',
    individualManufacturer: API + '/Api/Wheels/TireManufacturers/:id'
  },

  /** Authentication APIs */
  authenticationAPI: {
    loginWithEmail: API + '/Api/Account/Login',
    loginWithGoogle: API + '/Api/Account/ExternalLoginGoogle',
    loginWithFacebook: API + '/Api/Account/ExternalLoginFacebook',
    forgotPassword: API + '/Api/Account/ForgotPassword',
    resetPassword: API + '/Api/Account/ResetPassword',
    registerWithEmail: API + '/Api/Account/Register',
    refreshToken: API + '/Api/Account/Refresh',
    changePassword: API + '/Api/Account/ChangePass',
    registerRequest: API + '/Api/Mailing/RegisterRequest',
  },

  /** Valuation APIs */
  valuationAPI: {
    all: API + '/Api/Cars/Valuation',
  },

  /** Verification APIs */
  verificationAPI: {
    confirmEmail: API + '/Api/Account/ConfirmEmail',
    confirmPhone: API + '/Api/Account/ConfirmPhone',
    addPhone: API + '/Api/Account/AddPhone',
  },

  /** Availability APIs */
  availabilityAPI: {
    isEmailAvailable: API + '/Api/Account/EmailAvailable?email=:email',
    isPhoneAvailable: API + '/Api/Account/PhoneAvailable?phoneNumber=:phoneNumber',
  },

  /** Messages APIs */
  messagesAPI: {
    getTotalUnreadMessagesNumber: API + '/Api/Message/Unread',
    getChatUnreadMessagesNumber: API + '/Api/Message/Unread/:id',
    readChatMessages: API + '/Api/Message/Read',
    getAllChats: API + '/Api/Message',
    getChat: API + '/Api/Message/:id',
    support: API + '/Api/Message/Support',
    supportDialog: API + '/Api/Message/Support/:id',
    supportDialogsAvailable: API + '/Api/Message/Support/Available',
    supportDialogsPicked: API + '/Api/Message/Support/Picked',
    supportDialogsPickTicket: API + '/Api/Message/Support/PickTicket/:id',
    supportDialogsLeaveTicket: API + '/Api/Message/Support/LeaveTicket/:id',
    supportDialogsAnswerTicket: API + '/Api/Message/Support/AnswerTicket/:id',
  },

  /** Car search APIs */
  carSearchAPI: {
    saveSearch: API + '/Api/Cars/SaveSearch',
    getAllSavedSearches: API + '/Api/Cars/SavedSearches',
    deleteSavedSearch: API + '/Api/Cars/SavedSearches/:id',
    isSearchSaved: API + '/Api/Cars/IsSearchSaved',
  },

  /** Dealer APIs */
  dealerAPI: {
    saveDealer: API + '/Api/Account/SaveDealer/:id',
    getSavedDealers: API + '/Api/Account/SavedDealers',
    deleteDealer: API + '/Api/Account/SavedDealers/:id',
  },

  /** Saved Car Ads */
  carSaveAPI: {
    getSavedAdvertsNumber: API + '/Api/Cars/SavedAdsNumber',
    saveAdvert: API + '/Api/Cars/SaveAdvert/:id',
    savedAdvert: API + '/Api/Cars/SavedAdverts/:id',
    getAllSavedAdverts: API + '/Api/Cars/SavedAdverts',
  },

  /** Car Advert APIs */
  carAdvertAPI: {
    advertReject: API + '/Api/Cars/Reject/:id',
    advertClose: API + '/Api/Cars/Close/:id',
    allAdverts: API + '/Api/Cars/All',
    searchAdverts: API + '/Api/Cars',
    searchAdvertsById: API + '/Api/Cars/ById',
    advertEdit: API + '/Api/Cars/Edit/:id',
    advertID: API + '/Api/Cars/:id',
    advertsByUser: API + '/Api/Cars/User/:id',
    advertsOwn: API + '/Api/Cars/Own',
    advertsLatest: API + '/Api/Cars/Latest',
    advertsLastViewed: API + '/Api/Cars/LastViewed',
    deleteAdvertImage: API + '/Api/Cars/AdvertImages/:id',
    moderatingAds: API + '/Api/Cars/Moderating',
    advertPublish: API + '/Api/Cars/Publish/:id',
    checkPlate: API + '/Api/Cars/DvlaApi?value=:plate',
    carDbRequest: API + '/Api/Cars/CarDB',
    bestDeals: API + '/Api/Cars/BestDeals',
    shuffle: API + '/Api/Cars/Shuffle'
  },

  /** Appointment APIs */
  appointments: {
    own: API + '/Api/Appointments/Own',
    individual: API + '/Api/Appointment/:id',
    all: API + '/Api/Appointments',
    accept: API + '/Api/Appointments/Accept/:id',
    edit: API + '/Api/Appointments/Edit',
    close: API + '/Api/Appointments/Close/:id'
  },

  /** Car Brands APIs */
  carBrandsAPI: {
    getAllBrands: API + '/Api/Cars/Brands',
    individualBrand: API + '/Api/Cars/Brands/:id',
  },

  /** Car Equipments APIs */
  carEquipmentsAPI: {
    getAllEquipments: API + '/Api/Cars/Equipment',
    individualEquipment: API + '/Api/Cars/Equipment/:id',
  },

  /** Car Specifications APIs */
  carSpecificationsAPI: {
    getAllSpecifications: API + '/Api/Cars/Specifications',
    individualSpecification: API + '/Api/Cars/Specifications/:id',
  },

  /** Car SpecificationValues APIs */
  carSpecificationValuesAPI: {
    getAllSpecifications: API + '/Api/Cars/SpecificationValues',
    individualSpecificationValue: API + '/Api/Cars/SpecificationValues/:id',
  },

  /** Car Options APIs */
  carOptionsAPI: {
    getAllOptions: API + '/Api/Cars/Options',
    individualOptions: API + '/Api/Cars/Options/:id',
  },

  /** Car OptionValues APIs */
  carOptionValuesAPI: {
    getAllOptionValues: API + '/Api/Cars/OptionValues',
    individualOptionValues: API + '/Api/Cars/OptionValues/:id',
  },

  /** Car Trims APIs */
  carTrimsAPI: {
    getAllTrims: API + '/Api/Cars/Trims',
    getTrimsByVariant: API + '/Api/Cars/TrimsByVariant?modelVariantId=:modelVariantId',
    individualTrim: API + '/Api/Cars/Trims/:id',
  },

  /** Car Models APIs */
  carModelsAPI: {
    getAllModels: API + '/Api/Cars/Models',
    individualModel: API + '/Api/Cars/Models/:id',
  },

  /** Car Model Variants APIs */
  carModelVariantsAPI: {
    getAllModelVariants: API + '/Api/Cars/ModelVariants',
    individualModelVariant: API + '/Api/Cars/ModelVariants/:id',
  },

  /** Car Gearboxes APIs */
  carGearboxesAPI: {
    getAllGearboxes: API + '/Api/Cars/GearBoxes',
    individualGearbox: API + '/Api/Cars/GearBoxes/:id',
  },

  /** Car Body Type APIs */
  carBodyTypeAPI: {
    getAllBodyTypes: API + '/Api/Cars/BodyTypes',
    individualBodyType: API + '/Api/Cars/BodyTypes/:id',
  },

  /** Car Bodies APIs */
  carBodiesAPI: {
    getAllBodies: API + '/Api/Cars/Bodies',
    individualBody: API + '/Api/Cars/Bodies/:id',
  },

  /** Car Fuel Type APIs */
  carFuelTypeAPI: {
    getAllFuelTypes: API + '/Api/Cars/FuelTypes',
    individualFuelType: API + '/Api/Cars/FuelTypes/:id',
  },

  /** Car Transmission APIs */
  carTransmissionAPI: {
    getAllTransmissions: API + '/Api/Cars/Transmissions',
    individualTransmission: API + '/Api/Cars/Transmissions/:id',
  },

  /** Car Transmission APIs */
  colourAPI: {
    getAllColours: API + '/Api/Cars/Colours',
    individualColour: API + '/Api/Cars/Colours/:id',
  },
 
  /** Stripe APIs */
  stripeAPI: {
    key: 'pk_test_51HOfflLrx0Loo9JfZcxCjDBELqoHTC1FeuJAfcWTGS2Ep6POvB8GRf0Ft04YtGNQO1QWkZhrI3o3UGlHCO9zOy5o004sP4tfcz',
    // key: 'pk_live_51HOfflLrx0Loo9JfvGH6mFLlHefSD1TpnhRtODKEjaxsRP8jrVNSWPQayznohhJhNMNe7ETVI2jNRLiuFAc7Dmwt00arKDdJ8m',
    balance: API + '/Api/Account/Balance',
    cards: API + '/Api/Account/PaymentMethods',
    addCard: API + '/Api/Account/PaymentMethods',
    deleteCard: API + '/Api/Account/PaymentMethods/:id',
    createCharge: API + '/Api/Account/CreateCharge',
    addFunds: API + '/Api/Account/AddFunds',
    bankAccount: API + '/Api/Account/BankAccounts',
    individualBankAccount: API + '/Api/Account/BankAccounts/:id',
    payouts: API + '/Api/Account/Payouts',
    payoutsAmount: API + '/Api/Account/Payouts/:amount',
    allCharges: API + '/Api/Account/PaymentIntents',
    charge: API + '/Api/Cars/Charge/:id',
    rentCharge: API + '/Api/Cars/RentCharge/:id',
    rentRequest: API + '/Api/Cars/RentRequest/:id',
    rentOwnerRequests: API + '/Api/Cars/RentOwnerRequests',
    rentCustomerRequests: API + '/Api/Cars/RentCustomerRequests',
    allRentRequests: API + '/Api/Cars/RentRequests',
  },

  /** Account APIs */
  accountAPI: {
    accounts: API + '/Api/Account',
    Tin: API + '/Api/Account/TinApi?value=:value',
    accountsDealer: API + '/Api/Account/Dealers',
    account: API + '/Api/Account/:id',
    deleteAvatar: API + '/Api/Account/DeleteProfileImage',
    elevateUser: API + '/Api/Roles/ElevateUser',
    demoteUser: API + '/Api/Roles/DemoteUser',
    allowPreload: API + '/Api/Account/AllowPreload/:id',
    forbidPreload: API + '/Api/Account/ForbidPreload/:id',
    becomeDealer: API + '/Api/Account/BecomeDealer',
    changeDealerInfo: API + '/Api/Account/ChangeDealerInfo',
    emailNotifications: API + '/Api/Account/EmailNotifications',
    completeDealerInformation: API + '/Api/Account/CompleteDealerInformation',
    stripeOnboard: API + '/Api/Account/StripeOnboard',
    stripeOnboardingComplete: API + '/Api/Account/StripeOnboardComplete'
  },

  /** Rating APIs */
  ratingAPI: {
    getRatingUser: API + '/Api/Account/Rating/:id',
    apiReviewsUser: API + '/Api/Account/Reviews/:id',
    getReviews: API + '/Api/Account/Reviews',
  },

  /** Current Plan APIs */
  currentPlanAPI: {
    getCurrentPlan: API + '/Api/Cars/GetCurrentPlan',
    changePublicationPlan: API + '/Api/Cars/ChangePublicationPlan',
  },

  /** Blog APIs */
  blogAPI: {
    published: API + '/Api/Blog/Published?pageNumber=:pageNumber',
    all: API + '/Api/Blog/All?pageNumber=:pageNumber',
    post: API + '/Api/Blog/:id',
    blog: API + '/Api/Blog',
    makePublic: API + '/Api/Blog/Publish/:id',
    makePrivate: API + '/Api/Blog/Hide/:id',
  },

  /** FAQ APIs */
  faqAPI: {
    faq: API + '/Api/Faq',
    faqGroups: API + '/Api/Faq/Groups',
    faqByGroup: API + '/Api/Faq/:id',
    faqPost: API + '/Api/Faq/:groupId/:id',
  },

  /** Vehicle APIs */
  vehiclePayment: {
    own: API + '/Api/Cars/VehiclePayments/Own',
    individual: API + '/Api/Cars/VehiclePayments/:id',
    general: API + '/Api/Cars/VehiclePayments',
    accept: API + '/Api/Cars/VehiclePayments/Accept/:id',
    cancel: API + '/Api/Cars/VehiclePayments/Cancel/:id',
    invoice: API + '/Api/Cars/VehiclePayments/Invoice/:id',
    confirm: API + '/Api/Cars/VehiclePayments/Confirm/:id'
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
