import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CarClass } from '../classes/CarClass';
import { CarTrimClass } from '../classes/CarTrimClass';
import { AuthTokensResponse } from '../interface/AuthTokensResponse';
import { Balance } from '../interface/BalanceResponse';
import { CarAdvertsResponse, CarAdvert, Brand, Equipment, Trim, Model, ModelVariant, Body } from '../interface/CarAdvert';
import { CardReponse, PaymentMethod } from '../interface/CardsResponse';
import { ChargesResponse } from '../interface/ChargesResponse';
import { Chat, Message } from '../interface/ChatsResponse';
import { ResponseWithName, ResponseWithNameFormatted, ResponseWithImageFormatted, ResponseWithHexFormatted } from '../interface/CommonResponses';
import { DashboardResponse, DashboardCarsResponse } from '../interface/DashboardResponse';
import { FAQGroup } from '../interface/FAQ';
import { CarImage, AdvertImage } from '../interface/Image';
import { AddCarReverseBuyParameters } from '../interface/parameters/AddCarReverseBuyParameters';
import { LoginParametersInterface } from '../interface/parameters/auth/LoginParameters';
import { RefreshParameters } from '../interface/parameters/auth/RefreshParameters';
import { RegisterParametersInterface } from '../interface/parameters/auth/RegisterParameters';
import { RegisterRequestParametersInterface } from '../interface/parameters/auth/RegisterRequestParameters';
import { BuyCarReportParameters } from '../interface/parameters/reports/BuyCarReportParameters';
import { SearchCarReverseBuyParameters } from '../interface/parameters/SearchCarReverseBuyParameters';
import { Photo } from '../interface/Photo';
import { ReportResponse, ReportResponseParsed } from '../interface/ReportResponse';
import { RequestCarAdvertsResponse } from '../interface/RequestCarAdvert';
import { SavedSearch } from '../interface/SavedSearchResponse';
import { StripeAccountLink } from '../interface/Stripe';
import { TotalCarCheckReportResponse } from '../interface/TotalCarCheckReportResponse';
import { UserInfo } from '../interface/User';
import { VehiclePurchase } from '../interface/VehiclePurchase';
import { errors } from '../static/errors';
import { StoredDataService } from './stored-data.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private utils: UtilsService,
    public router: Router,
  ) {}

  sortByOrder = (a: CarImage | AdvertImage, b: CarImage | AdvertImage) => a.order - b.order;

  mapCarAdvertsResponses = (response: CarAdvertsResponse) => {
    return {
      adverts: response.adverts.map(advert => {
        return new CarClass(
          advert,
          this,
          'car',
        );
      }),
      total: response.total
    };
  }

  mapCommonResponsesWithImage = (array: any[]) => {
    const formattedArray: any[] = array.map(e => {
      return {
        ...e,
        image: environment.API + '/' + e.imagePath,
        value: e.id
      };
    });

    return formattedArray;
  }

  mapCommonResponsesWithName = (array: ResponseWithName[]) => {
    const formattedArray: ResponseWithNameFormatted[] = array.map(e => {
      return {
        ...e,
        value: e.id
      };
    });

    return formattedArray;
  }

  mapReportsResponse = (reports: ReportResponse[]) => {
    return reports.map(report => {
      return {
        ...report,
        advert: new CarClass(report.advert, this, 'car'),
        report: JSON.parse(report.report)
      };
    });
  }

  mapReportResponse = (report: ReportResponse) => {
    return {
      ...report,
      report: JSON.parse(report.report)
    };
  }

  getCompanyByNumber(companyNumber: any): Observable<any> {
    return this.http.get(
      environment.accountAPI.Tin.replace(':value', companyNumber)
    );
  }

  /** Log Error */
  // @Share()
  logError(error: object): Observable<unknown> {
    return this.http.post(environment.logs.error.add, {error});
  }

  /** Delete avatar */
  // @Share()
  deleteAvatar(): Observable<string> {
    return this.http.put(environment.accountAPI.deleteAvatar, {}, {
      responseType: 'text'
    });
  }

  /** Dashboard */
  // @Share()
  getDashboard(): Observable<DashboardResponse> {
    return this.http.get<DashboardResponse>(environment.dashboardAPI.main);
  }

  // @Share()
  getDashboardCars(options: {pageSize: number; pageNumber: number}): Observable<DashboardCarsResponse> {
    const params = this.utils.buildQueryParams(options);
    return this.http.get<DashboardCarsResponse>(environment.dashboardAPI.cars, {params});
  }

  /** Wallet */
  // @Share()
  getBalance(): Observable<Balance> {
    return this.http.get<Balance>(environment.stripeAPI.balance);
  }

  // @Share()
  getCards(): Observable<CardReponse> {
    return this.http.get<CardReponse>(environment.stripeAPI.cards);
  }

  // @Share()
  addCard(source: string): Observable<PaymentMethod> {
    return this.http.post<PaymentMethod>(environment.stripeAPI.addCard, {source});
  }

  // @Share()
  deleteCard(id: string): Observable<PaymentMethod> {
    return this.http.delete<PaymentMethod>(environment.stripeAPI.deleteCard.replace(':id', id));
  }

  // @Share()
  getCharges(): Observable<ChargesResponse> {
    return this.http.get<ChargesResponse>(environment.stripeAPI.allCharges);
  }

  // @Share()
  charge(source: string, amount: number): Observable<any> {
    return this.http.post<any>(environment.stripeAPI.createCharge, {source, amount});
  }

  // @Share()
  addFunds(amount: number): Observable<any> {
    return this.http.post<any>(environment.stripeAPI.addFunds, {amount});
  }

  // @Share()
  getBankAccounts(): Observable<any[]> {
    return this.http.get<any[]>(environment.stripeAPI.bankAccount);
  }

  // @Share()
  addBankAccount(source: string): Observable<any> {
    return this.http.post<any>(environment.stripeAPI.bankAccount, {source});
  }

  // @Share()
  deleteBankAccount(source: string): Observable<any> {
    return this.http.delete<any>(environment.stripeAPI.individualBankAccount.replace(':id', source));
  }

  // @Share()
  getPayouts(): Observable<any> {
    return this.http.get(environment.stripeAPI.payouts);
  }

  // @Share()
  addPayout(amount: number): Observable<any> {
    return this.http.post(environment.stripeAPI.payoutsAmount.replace(':amount', amount.toString()), {});
  }

  /** Car Reports */
  // @Share()
  buyCarReport(params: BuyCarReportParameters): Observable<any> {
    return this.http.post<any>(environment.carReportsAPI.buy, params);
  }

  // @Share()
  getOwnCarReports(): Observable<ReportResponse[]> {
    return this.http.get<ReportResponse[]>(environment.carReportsAPI.ownReports).pipe(
      map(this.mapReportsResponse)
    );
  }

  // @Share()
  getTestCarReport(plate: string, type: any): Observable<TotalCarCheckReportResponse> {
    const params = this.utils.buildQueryParams({
      source: plate,
      type
    });
    return this.http.get<TotalCarCheckReportResponse>(environment.carReportsAPI.testReport, {params});
  }

  // @Share()
  getCarReport(id: number): Observable<ReportResponseParsed> {
    return this.http.get<ReportResponse>(environment.carReportsAPI.individualReport.replace(':id', id.toString())).pipe(
      map(this.mapReportResponse)
    );
  }

  /** Purchase (Reverse buy) */
  // @Share()
  getReverseBuyCars(options: SearchCarReverseBuyParameters): Observable<RequestCarAdvertsResponse> {
    const params = this.utils.buildQueryParams(options);
    return this.http.get<RequestCarAdvertsResponse>(environment.purchaseAPI.multiple, {params}).pipe(
      map((e: RequestCarAdvertsResponse) => {
        return {
          requests: e.requests.map(item =>
            new CarClass({
              ...item,
              images: item.modelVariant.images as any[]
            }, this, 'reverse')
          ),
          total: e.total
        };
      })
    );
  }

  // @Share()
  getReverseBuyCar(id: any): Observable<CarClass> {
    return this.http.get<CarAdvert>(environment.purchaseAPI.individual.replace(':id', id)).pipe(
      map((e: CarAdvert) => {
        return new CarClass({
          ...e,
          images: e.modelVariant.images as any[]
        }, this, 'reverse');
      })
    );
  }

  // @Share()
  addReverseBuyCar(options: AddCarReverseBuyParameters): Observable<CarAdvert> {
    return this.http.post<CarAdvert>(environment.purchaseAPI.multiple, options);
  }

  // @Share()
  editReverseBuyCar(options: AddCarReverseBuyParameters): Observable<CarAdvert> {
    return this.http.put<CarAdvert>(environment.purchaseAPI.multiple, options);
  }

  // @Share()
  closeReverseBuyCar(id: any): Observable<any> {
    return this.http.put<any>(environment.purchaseAPI.close.replace(':id', id), {});
  }

  // @Share()
  getOwnReverseBuyCars(options: any): Observable<RequestCarAdvertsResponse> {
    const params = this.utils.buildQueryParams(options);
    return this.http.get<RequestCarAdvertsResponse>(environment.purchaseAPI.own, {params}).pipe(
      map((e: RequestCarAdvertsResponse) => {
        return {
          requests: e.requests.map(item =>
            new CarClass({
              ...item,
              images: item.modelVariant.images as any[]
            }, this, 'reverse')
          ),
          total: e.total
        };
      })
    );
  }

  // @Share()
  getFinance(price: number): Observable<number> {
    return this.http.get<number>(environment.API + '/NodeService/finance/calculate?price=' + price);
  }

  /** Send Email */
  // @Share()
  sendEmail(sellerId: string, email: string, name: string, message: string) {
    return this.http.post<any>(environment.contactAPI.email, {
      dealerId: sellerId,
      email,
      name,
      message
    });
  }

  // @Share()
  registerRequest(params: RegisterRequestParametersInterface) {
    return this.http.post<any>(environment.authenticationAPI.registerRequest, params);
  }


  /** Generate excel */
  // @Share()
  generateExcel(carsData: any[]): Observable<any> {
    return this.http.post(environment.API + '/NodeService/excel/cars', carsData, {
      responseType: 'arraybuffer'
    });
  }

  /** Disk Manufacturers APIs */
  // @Share()
  getDealers(search?: string, lat?: number, lng?: number, radius?: number, pageNumber?: number, pageSize?: number, onlyGeo?: boolean): Observable<any> {
    const params = this.utils.buildQueryParams({
      contains: search,
      latitude: radius && lat ? lat.toString() : null,
      longitude: radius && lng ? lng.toString() : null,
      radiusMeters: radius && lat && lng ? radius.toString() : null,
      pageNumber,
      pageSize,
      onlyGeo
    });

    return this.http.get<any[]>(environment.accountAPI.accountsDealer, {
      params
    });
  }

  /** Disk Manufacturers APIs */
  // @Share()
  getDiskManufacturers() {
    return this.http.get<any[]>(environment.diskManufacturersAPI.getAllManufacturers);
  }

  /** Appointment API */
  // @Share()
  createAppointment(value: any) {
    return this.http.post<any>(environment.appointments.all, value);
  }

  // @Share()
  viewAppointments(status: number) {
    return this.http.get<any>(environment.appointments.own, {
      params: {
        status: status ? status.toString() : null
      }
    });
  }

  // @Share()
  acceptAppointment(id: number) {
    return this.http.put<any>(environment.appointments.accept.replace(':id', id.toString()), {});
  }

  // @Share()
  declineAppointment(id: number) {
    return this.http.put<any>(environment.appointments.close.replace(':id', id.toString()), {});
  }

  // @Share()
  editAppointment(value: any) {
    return this.http.put<any>(environment.appointments.edit, value);
  }

  /** Valuation API */
  // @Share()
  valuation(id: number) {
    return this.http.get<any>(environment.valuationAPI.all, {params: {id: id.toString()}});
  }

  /** Disks API */
  // @Share()
  searchDisks(options: any) {
    const params = this.utils.buildQueryParams(options);

    return this.http.get<any>(environment.disksAPI.search, {params});
  }

  /** Tyres API */
  // @Share()
  searchTyres(options: any) {
    const params = this.utils.buildQueryParams(options);

    return this.http.get<any>(environment.tyresAPI.search, {params});
  }

  /** Tyre Manufacturer API */
  // @Share()
  getTyreManufacturers() {
    return this.http.get<any[]>(environment.tyreManufacturersAPI.getAllManufacturers);
  }

  /** Check plate */
  // @Share()
  checkPlate(plateNumber: string): Observable<{
    mot: string;
    ves: string;
    totalCarCheck: string;
  }> {
    return this.http.get<{
      mot: string;
      ves: string;
      totalCarCheck: string;
    }>(environment.carAdvertAPI.checkPlate.replace(':plate', plateNumber));
  }

  /** Reset Password API */
  // @Share()
  forgotPassword(email: string) {
    return this.http.post(environment.authenticationAPI.forgotPassword, {email}, {
      responseType: 'text'
    });
  }

  // @Share()
  resetPassword(email: string, password: string, code: any) {
    return this.http.post(environment.authenticationAPI.resetPassword, {email, password, code}, {
      responseType: 'text'
    });
  }


  /** Unread Messages API */
  // @Share()
  getUnreadMessagesTotal() {
    return this.http.get<number>(environment.messagesAPI.getTotalUnreadMessagesNumber);
  }

  // @Share()
  readMessagesByChat(id: any) {
    return this.http.post<number>(environment.messagesAPI.readChatMessages + '/' + id, {});
  }

  // @Share()
  getUnreadMessagesByChat(id: any) {
    return this.http.get<number>(environment.messagesAPI.getChatUnreadMessagesNumber.replace(':id', id));
  }

  /* Single Account API */
  // @Share()
  getAccount(id: any): Observable<UserInfo> {
    return this.http.get<UserInfo>(environment.accountAPI.account.replace(':id', id));
  }

  /* Load account if required refresh API */
  getOwnAccount(userId: string, refresh: Observable<any>): Observable<UserInfo> {
    return this.getAccount(userId).pipe(
      switchMap((val) => {
        if (typeof val.emailConfirmed === 'boolean') {
          return of(val);
        } else {
          return refresh.pipe(
            switchMap(() => this.getAccount(userId))
          );
        }
      })
    );
  }

  /* Login with email API */
  // @Share()
  loginWithEmail(authParams: LoginParametersInterface) {
    return this.http.post <AuthTokensResponse>(environment.authenticationAPI.loginWithEmail, authParams);
  }

  /* Login with google API */
  // @Share()
  loginWithGoogle(token: string) {
    return this.http.get(environment.authenticationAPI.loginWithGoogle + '?googleTokenId=' + token);
  }

  /* Login with facebook API */
  // @Share()
  loginWithFacebook(token: string) {
    return this.http.get(environment.authenticationAPI.loginWithFacebook + '?facebookToken=' + token);
  }

  /* Refresh token API */
  refreshToken(refreshParams: RefreshParameters) {
    return this.http.post<AuthTokensResponse>(environment.authenticationAPI.refreshToken, refreshParams);
  }

  /* Change password API */
  // @Share()
  changePassword(params: any): Observable<any> {
    return this.http.post<any>(environment.authenticationAPI.changePassword, params);
  }

  /* Request phone verification sms API */
  // @Share()
  requestSMS(phone: string) {
    return this.http.get(environment.verificationAPI.confirmPhone + '?phoneNumber=' + phone);
  }

  /* Verify phone API */
  // @Share()
  verifyPhone(requestId: any, code: any, phoneNumber: any) {
    return this.http.post(environment.verificationAPI.confirmPhone, {
      requestId,
      code,
      phoneNumber
    });
  }

  /* Add phone number API */
  // @Share()
  addPhoneNumber(phoneNumber: any) {
    return this.http.post(environment.verificationAPI.addPhone, {
      phoneNumber
    });
  }

  /* Is email available API */
  isEmailAvailable(email: string): Observable<string> {
    return this.http.get(environment.availabilityAPI.isEmailAvailable.replace(':email', email), {responseType: 'text'});
  }

  /* Is phone available API */
  isPhoneAvailable(phoneNumber: string): Observable<string> {
    return this.http.get(environment.availabilityAPI.isPhoneAvailable.replace(':phoneNumber', phoneNumber), {responseType: 'text'});
  }

  /* Handle phone number check */
  handlePhoneCheckAPI(phoneNumber: AbstractControl, tel: string): Observable<any> {
    return this.isPhoneAvailable(tel).pipe(tap(isPhoneAvailable => {
      phoneNumber.updateValueAndValidity({
        emitEvent: false
      });
    }, error => {
      phoneNumber.setErrors({
        error: errors.phoneNumberIsTaken.frontend
      });
    }));
  }

  /* Change dealer info API */
  // @Share()
  changeDealerInfo(
    description: string,
    personalWebsite?: string,
    image?: Photo,
    TIN?: any,
    name?: string,
    emailNotifications?: boolean,
    phoneNumber?: string
  ): Observable<any> {
    const formData = this.utils.buildFormData({
      description, personalWebsite, TIN, name, emailNotifications, phoneNumber
    });

    return from(this.utils.addImageToFormData(formData, image, 'image')).pipe(
      switchMap(() => this.http.put(environment.accountAPI.changeDealerInfo, formData))
    );
  }

  /* Save dealer API */
  // @Share()
  saveDealer(id: any) {
    return this.http.post(environment.dealerAPI.saveDealer.replace(':id', id), {});
  }

  /* Get saved dealer API */
  // @Share()
  getSavedDealers() {
    return this.http.get<any[]>(environment.dealerAPI.getSavedDealers);
  }

  /* Delete saved dealer API */
  // @Share()
  deleteSavedDealer(id: any) {
    return this.http.delete(environment.dealerAPI.deleteDealer.replace(':id', id));
  }

  /* Get rating API */
  // @Share()
  getRating(id: any) {
    return this.http.get(environment.ratingAPI.getRatingUser.replace(':id', id));
  }

  /* Get pending reviews API */
  // @Share()
  getPendingReviews() {
    return this.http.get<any[]>(environment.ratingAPI.getReviews);
  }

  /* Get user reviews API */
  // @Share()
  getUserReviews(id: any) {
    return this.http.get<any[]>(environment.ratingAPI.apiReviewsUser.replace(':id', id));
  }

  /* Add review API */
  // @Share()
  addReview(id: any, rating: number, content: string) {
    return this.http.post(environment.ratingAPI.apiReviewsUser.replace(':id', id), {rating, content});
  }

  /* Update email notifications API */
  // @Share()
  updateEmailNotifications(setting: boolean) {
    return this.http.put(environment.accountAPI.emailNotifications, {setting});
  }

  /* Get chats API */
  // @Share()
  getChats(): Observable<Chat[]> {
    return this.http.get<Chat[]>(environment.messagesAPI.getAllChats);
  }

  /* Get chat messages API */
  // @Share()
  getChatMessages(id: number): Observable<Message[]> {
    return this.http.get<Message[]>(environment.messagesAPI.getChat.replace(':id', id.toString()));
  }

  /* Send message API */
  // @Share()
  sendMessage(receiverId: any, text: string) {
    return this.http.post(environment.messagesAPI.getAllChats, {
      receiverId,
      text
    });
  }

  /* Get support chat API */
  // @Share()
  getSupportChat() {
    return this.http.get<any[]>(environment.messagesAPI.support);
  }

  /* Send message to support API */
  // @Share()
  sendMessageToSupport(text: string) {
    return this.http.post(environment.messagesAPI.support, {text});
  }

  /* Get information about support dialog API */
  // @Share()
  getSupportDialog(id: any) {
    return this.http.get(environment.messagesAPI.supportDialog.replace(':id', id));
  }

  /* Get available support dialogs API */
  // @Share()
  getAvailableSupportDialogs() {
    return this.http.get<any[]>(environment.messagesAPI.supportDialogsAvailable);
  }

  /* Get picked support dialogs API */
  // @Share()
  getPickedSupportDialogs() {
    return this.http.get<any[]>(environment.messagesAPI.supportDialogsPicked);
  }

  /* Pick ticket API */
  // @Share()
  pickSupportTicket(id: any) {
    return this.http.put(environment.messagesAPI.supportDialogsPickTicket.replace(':id', id), {});
  }

  /* Leave ticket API */
  // @Share()
  leaveSupportTicket(id: any) {
    return this.http.put(environment.messagesAPI.supportDialogsLeaveTicket.replace(':id', id), {});
  }

  /* Answer ticket API */
  // @Share()
  answerSupportTicket(id: any, text: string) {
    return this.http.post(environment.messagesAPI.supportDialogsAnswerTicket.replace(':id', id), {text});
  }

  /* Search car API */
  // @Share()
  searchCarsById(
    id: string
  ): Observable<CarAdvertsResponse> {
    const params = this.utils.buildQueryParams({
      id
    });

    return this.http.get<CarAdvertsResponse>(environment.carAdvertAPI.searchAdvertsById, {params}).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  // @Share()
  searchCars(
    options: any
  ): Observable<CarAdvertsResponse> {
    const params = this.utils.buildQueryParams(options);

    return this.http.get<CarAdvertsResponse>(environment.carAdvertAPI.searchAdverts, {params}).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  // @Share()
  getBestDealsCars(pageSize: number, pageNumber: number): Observable<CarAdvertsResponse> {
    const params = this.utils.buildQueryParams({
      pageSize, pageNumber
    });
    return this.http.get<CarAdvertsResponse>(environment.carAdvertAPI.bestDeals, {params}).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  // @Share()
  getShuffleCars(pageSize: number, typeId?: number): Observable<CarClass[]> {
    const params = this.utils.buildQueryParams({
      pageSize, typeId
    });

    return this.http.get<CarAdvert[]>(environment.carAdvertAPI.shuffle, {params}).pipe(
      map((adverts: CarAdvert[]) =>
        adverts.map(advert =>
          new CarClass(
            advert,
            this,
            'car'
          )
        )
      )
    );
  }

  /* Get latest cars API */
  // @Share()
  getLatestCars(pageNumber: number, pageSize: number): Observable<CarAdvertsResponse> {
    return this.http.get<any>(environment.carAdvertAPI.advertsLatest + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  /* Get user cars API */
  // @Share()
  getUserCars(id: string, statusId?: number, pageNumber?: number, pageSize?: number): Observable<CarAdvertsResponse> {
    const params = this.utils.buildQueryParams({
      statusId, pageNumber, pageSize
    });

    return this.http.get<CarAdvertsResponse>(environment.carAdvertAPI.advertsByUser.replace(':id', id), {
      params
    }).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  /* Get own cars API */
  // @Share()
  getOwnCars(statusId: number, pageNumber: number, pageSize: number): Observable<CarAdvertsResponse> {
    const params = this.utils.buildQueryParams({
      statusId, pageNumber, pageSize
    });
    return this.http.get<CarAdvertsResponse>(environment.carAdvertAPI.advertsOwn, {
      params
    }).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  /* Get last viewed API */
  // @Share()
  getLastViewedCar(pageNumber: number, pageSize: number): Observable<CarAdvertsResponse> {
    const params = this.utils.buildQueryParams({
      pageNumber, pageSize
    });
    return this.http.get<CarAdvertsResponse>(
      environment.carAdvertAPI.advertsLastViewed,
      {params}
    ).pipe(
      map(this.mapCarAdvertsResponses)
    );
  }

  /* Add car API */
  // @Share()
  addCar(
    params: any
  ): Observable<CarAdvert> {
    const formData = this.utils.buildFormData(params);

    return this.http.post<CarAdvert>(environment.carAdvertAPI.searchAdverts, formData);
  }

  /* Edit car API */
  // @Share()
  editCar(
    id: any,
    params: any
  ): Observable<CarAdvert> {
    const formData = this.utils.buildFormData(params);

    return this.http.put<CarAdvert>(environment.carAdvertAPI.advertEdit.replace(':id', id), formData);
  }

  /* Delete car image API */
  // @Share()
  deleteCarImage(id: any) {
    return this.http.delete(environment.carAdvertAPI.deleteAdvertImage.replace(':id', id));
  }

  /* Close car API */
  // @Share()
  closeCar(id: number) {
    return this.http.put(environment.carAdvertAPI.advertClose.replace(':id', id.toString()), {});
  }

  /* Delete car API */
  // @Share()
  deleteCar(id: number) {
    return this.http.delete(environment.carAdvertAPI.searchAdverts + '/' + id, {});
  }

  /* Get car API */
  // @Share()
  getCar(id: number): Observable<CarClass> {
    return this.http.get<CarAdvert>(environment.carAdvertAPI.advertID.replace(':id', id.toString())).pipe(
      map((e: CarAdvert) => {
        return new CarClass(e, this, 'car');
      })
    );
  }

  /* Save car API */
  // @Share()
  saveCar(id: any) {
    return this.http.post(environment.carSaveAPI.saveAdvert.replace(':id', id), {});
  }

  /* Get saved cars API */
  // @Share()
  getSavedCars(): Observable<CarAdvertsResponse> {
    return this.http.get<CarAdvertsResponse>(environment.carSaveAPI.getAllSavedAdverts).pipe(
      map(this.mapCarAdvertsResponses),
    );
  }

  /* Get saved cars number */
  // @Share()
  getSavedCarsNumber(): Observable<number> {
    return this.http.get<number>(environment.carSaveAPI.getSavedAdvertsNumber);
  }


  /* Delete saved car API */
  // @Share()
  deleteSavedCar(id: any): Observable<any> {
    return this.http.delete(environment.carSaveAPI.savedAdvert.replace(':id', id));
  }

  /* Save car search API */
  // @Share()
  saveCarSearch(
    body: any
  ): Observable<any> {
    return this.http.post<any>(environment.carSearchAPI.saveSearch, body);
  }

  /* Delete saved car search API */
  // @Share()
  deleteSavedCarSearch(id: any) {
    return this.http.delete(environment.carSearchAPI.deleteSavedSearch.replace(':id', id));
  }

  /* Get saved car searches API */
  // @Share()
  getSavedCarSearches(): Observable<SavedSearch[]> {
    return this.http.get<SavedSearch[]>(environment.carSearchAPI.getAllSavedSearches);
  }

  /* Is car search saved API */
  // @Share()
  isSavedSearch(
    options: any
  ): Observable<boolean> {
    return this.http.post<boolean>(environment.carSearchAPI.isSearchSaved, options);
  }

  /* Get current plan API */
  // @Share()
  getCurrentPlan(planId: any) {
    return this.http.get<boolean>(environment.currentPlanAPI.getCurrentPlan + '?planId=' + planId);
  }

  /* Pay for car listing API */
  // @Share()
  payForCarListing(id: number, stripeToken: string) {
    return this.http.post(environment.stripeAPI.charge.replace(':id', id.toString()), {stripeToken});
  }

  /* Rent car charge API */
  // @Share()
  chargeForCarRent(id: any, stripeToken: string) {
    return this.http.post(environment.stripeAPI.rentCharge.replace(':id', id.toString()), {stripeToken});
  }

  /* Request car rent API */
  // @Share()
  requestCarRent(id: any, daysDuration: number, comment: string) {
    return this.http.post(environment.stripeAPI.rentRequest.replace(':id', id.toString()), {daysDuration, comment});
  }

  /* Get request car rent API */
  // @Share()
  getRequestCarRent(id: any) {
    return this.http.get(environment.stripeAPI.rentRequest.replace(':id', id.toString()));
  }

  /* Get owner car requests API */
  // @Share()
  getOwnerCarRequest(id: any) {
    return this.http.get(environment.stripeAPI.rentOwnerRequests.replace(':id', id.toString()));
  }

  /* Get customer car requests API */
  // @Share()
  getCustomerCarRequest(id: any) {
    return this.http.get(environment.stripeAPI.rentCustomerRequests.replace(':id', id.toString()));
  }

  /* Answer to car request API */
  // @Share()
  answerToCarRequest(id: any, status: number) {
    return this.http.put(environment.stripeAPI.rentRequest.replace(':id', id.toString()), {status});
  }

  /* Get all car requests API */
  // @Share()
  getAllCarRequests(pageSize: number, pageNumber: number) {
    const params = this.utils.buildQueryParams({
      pageNumber, pageSize
    });
    return this.http.get(environment.stripeAPI.allRentRequests, {params});
  }

  /** Brands */
  getBrands(): Observable<Brand[]> {
    return this.http.get<Brand[]>(environment.carBrandsAPI.getAllBrands).pipe(
      map(this.mapCommonResponsesWithImage)
    );
  }

  /** Specification */
  // @Share()
  getSpecification() {
    return this.http.get<any[]>(environment.carSpecificationsAPI.getAllSpecifications);
  }

  /** Specification Values */
  // @Share()
  getSpecificationValues() {
    return this.http.get<any[]>(environment.carSpecificationValuesAPI.getAllSpecifications);
  }

  /** Options */
  // @Share()
  getOptions() {
    return this.http.get<any[]>(environment.carOptionsAPI.getAllOptions);
  }

  /** Options Values */
  // @Share()
  getOptionValues() {
    return this.http.get<any[]>(environment.carOptionValuesAPI.getAllOptionValues);
  }

  /** Equipment */
  getEquipmentsByTrim(trimId: number): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(environment.carEquipmentsAPI.getAllEquipments + '?trimId=' + trimId).pipe(
      map((e: Equipment[]) => e.map(item => ({...item, value: item.id})))
    );
  }

  /** Trim */
  getTrimsByBody(body: number, storage: StoredDataService): Observable<CarTrimClass[]> {
    return this.http.get<Trim[]>(
      environment.carTrimsAPI.getAllTrims + '?bodyId=' + body
    ).pipe(
      map((e: Trim[]) => {
        return e.map(item => new CarTrimClass({...item, value: item.id}, storage));
      })
    );
  }

  /** Bodies */
  getBodiesByModelVariant(modelVariantId: number): Observable<Body[]> {
    return this.http.get<Body[]>(environment.carBodiesAPI.getAllBodies + '?modelVariantId=' + modelVariantId).pipe(
      map((e: Body[]) => {
        return e.map(item => {
          return {
            ...item,
            name: item.name + ' (' + item.doors + ' doors)',
            value: item.id
          };
        });
      })
    );
  }

  /** Models */
  getModelsById(id: any, disableSubModels = false): Observable<Model[]> {
    return this.http.get<Model[]>(environment.carModelsAPI.getAllModels + '?brandId=' + id).pipe(
      map((array: Model[]) => {
        const newArray = [];
        for (const element of array) {
          element.subModels = element.subModels.sort((a: Model, b: Model) => a.name.localeCompare(b.name));
          if (element.subModels && element.subModels.length > 0) {
            newArray.push({
              ...element,
              hasChildren: true,
              value: element.id,
              openned: false
            });
            if (!disableSubModels) {
              for (const item of element.subModels) {
                newArray.push({
                  ...(item),
                  name: element.name + ' ' + item.name,
                  value: item.id,
                  parentId: element.id,
                  hasChildren: false,
                  hide: true
                });
              }
            }
          } else {
            newArray.push({
              ...element,
              value: element.id,
              hasChildren: false,
            });
          }
        }
        return newArray;
      })
    );
  }

  /** Model Variant */
  getModelVariantsById(id: any, disableGroups = false): Observable<ModelVariant[]> {
    return this.http.get<Array<any>>(environment.carModelVariantsAPI.getAllModelVariants + '?modelId=' + id).pipe(
      map((array: any) => {
        const newArray: ModelVariant[] = [];
        for (const element of array) {
          element.images = element.images.sort((a: CarImage, b: CarImage) => a.order - b.order);
          if (disableGroups) {
            element.imagePath = !element.images[0] || element.images[0].imagePath.startsWith('http') ? /*element.images[0].imagePath*/ 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png' : (environment.API + '/' + element.images[0].imagePath);
            newArray.push({
              ...element,
              value: element.id
            });
          } else {
            if (!element.groupId) {
              element.imagePath = !element.images[0] || element.images[0].imagePath.startsWith('http') ? /*element.images[0].imagePath*/ 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png' : (environment.API + '/' + element.images[0].imagePath);
              newArray.push({
                ...element,
                value: element.id
              });
            } else if (!newArray.find(e => e.groupId === element.groupId)) {
              element.imagePath = !element.images[0] || element.images[0].imagePath.startsWith('http') ? /*element.images[0].imagePath*/ 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png' : (environment.API + '/' + element.images[0].imagePath);
              newArray.push({
                ...element,
                value: element.id
              });
            }
          }

        }
        return newArray.reverse();
      })
    );
  }

  /** Body Type */
  getBodyTypes(): Observable<ResponseWithImageFormatted[]> {
    return this.http.get<ResponseWithImageFormatted[]>(environment.carBodyTypeAPI.getAllBodyTypes).pipe(
      map(this.mapCommonResponsesWithImage)
    );
  }

  /** Fuel type */
  getFuelTypes(): Observable<ResponseWithNameFormatted[]> {
    return this.http.get<ResponseWithNameFormatted[]>(environment.carFuelTypeAPI.getAllFuelTypes).pipe(
      map(this.mapCommonResponsesWithName)
    );
  }

  /** Transmissions */
  getTransmissions(): Observable<ResponseWithNameFormatted[]> {
    return this.http.get<ResponseWithNameFormatted[]>(environment.carTransmissionAPI.getAllTransmissions).pipe(
      map(this.mapCommonResponsesWithName)
    );
  }

  /** Gearboxes */
  getGearboxes(): Observable<ResponseWithNameFormatted[]> {
    return this.http.get<ResponseWithNameFormatted[]>(environment.carGearboxesAPI.getAllGearboxes).pipe(
      map(this.mapCommonResponsesWithName)
    );
  }

  /** Colors */
  getColours(): Observable<ResponseWithHexFormatted[]> {
    return this.http.get<ResponseWithHexFormatted[]>(environment.colourAPI.getAllColours).pipe(
      map((colours: ResponseWithHexFormatted[]) => {
        const arraySorted: ResponseWithHexFormatted[] = [];
        for (let index = 0; index < colours.length; index++) {
          const element = colours[index];
          if (element.name === 'Any') {
            arraySorted[0] = {
              ...element,
              value: element.id
            };
          } else if (element.name === 'Black') {
            arraySorted[1] = {
              ...element,
              value: element.id
            };
          } else if (element.name === 'White') {
            arraySorted[2] = {
              ...element,
              value: element.id
            };
          } else if (element.name === 'Grey') {
            arraySorted[3] = {
              ...element,
              value: element.id
            };
          } else {
            arraySorted[3 + index] = {
              ...element,
              value: element.id
            };
          }
        }
        return arraySorted.filter(e => typeof e === 'object');
      })
    );
  }

  /** Blog API */
  // @Share()
  getAllBlogPosts(pageNumber: number = 1) {
    return this.http.get<any[]>(environment.blogAPI.all.replace(':pageNumber', pageNumber.toString()));
  }

  // @Share()
  getBlogPost(id: number) {
    return this.http.get<any>(environment.blogAPI.post.replace(':id', id.toString()));
  }

  // @Share()
  getPublishedBlogPosts(pageNumber: number = 1) {
    return this.http.get<any[]>(environment.blogAPI.published.replace(':pageNumber', pageNumber.toString()));
  }

  getImage(path: string): Observable<Blob> {
    return this.http.get(environment.API + '/' + path, {
      responseType: 'blob'
    });
  }

  /** FAQ */
  // @Share()
  getFaqByGroup(id: any): Observable<FAQGroup[]> {
    return this.http.get<FAQGroup[]>(environment.faqAPI.faqByGroup.replace(':id', id));
  }

  // @Share()
  getFaqGroups(): Observable<any[]> {
    return this.http.get<any[]>(environment.faqAPI.faqGroups);
  }

  // @Share()
  getAllFaqs(): Observable<any[]> {
    return this.http.get<any[]>(environment.faqAPI.faq);
  }

  // @Share()
  register(
    value: RegisterParametersInterface
  ): Observable<AuthTokensResponse> {
    const params = this.utils.buildFormData(value);

    return this.http.post <AuthTokensResponse>(environment.authenticationAPI.registerWithEmail, params);
  }

  // @Share()
  confirmEmail(email: string): Observable<any> {
    return this.http.get(environment.verificationAPI.confirmEmail.replace(':email', email));
  }

  /** Vehicle Payment API */
  getOwnVehiclePayments(statusId?: any): Observable<VehiclePurchase[]> {
    const params = this.utils.buildQueryParams({
      statusId
    });
    return this.http.get<VehiclePurchase[]>(environment.vehiclePayment.own, {params});
  }

  getIndividualVehiclePayment(id: any): Observable<VehiclePurchase> {
    return this.http.get<VehiclePurchase>(environment.vehiclePayment.individual.replace(':id', id));
  }

  createVehiclePayment(advertId: any): Observable<any> {
    return this.http.post(environment.vehiclePayment.individual.replace(':id', advertId), {});
  }

  acceptVehiclePayment(id: any): Observable<any> {
    return this.http.put(environment.vehiclePayment.accept.replace(':id', id), {});
  }

  cancelVehiclePayment(id: any): Observable<any> {
    return this.http.put(environment.vehiclePayment.cancel.replace(':id', id), {});
  }

  invoiceVehiclePayment(id: any): Observable<any> {
    return this.http.put(environment.vehiclePayment.invoice.replace(':id', id), {});
  }

  confirmVehiclePayment(id: any): Observable<any> {
    return this.http.put(environment.vehiclePayment.confirm.replace(':id', id), {});
  }

  /**
   * 
   * @param params 
   * @deprecated
   */
  verifyForOnlinePayment(params: {phone: string; name: string; email: string; bankSource: string;}): Observable<any> {
    return this.http.post(environment.accountAPI.completeDealerInformation, params);
  }

  /**
   * 
   */
  requestLinkForOnlinePaymentVerification(): Observable<StripeAccountLink> {
    return this.http.post<StripeAccountLink>(environment.accountAPI.stripeOnboard, {});
  }

  completeOnlinePaymentVerification(): Observable<void> {
    return this.http.post<void>(environment.accountAPI.stripeOnboardingComplete, {});
  }
}