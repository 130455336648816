import {
  HttpParams
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import * as deep from 'fast-deep-equal';
import * as moment from 'moment';
import { Photo } from '../interface/Photo';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {}

  public clean(obj: any, deleteIfObject = false): void {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      } else if (typeof obj[propName] === 'object' && !obj[propName].min && !obj[propName].max && !Array.isArray(obj[propName])){
        delete obj[propName];
      } else if (typeof obj[propName] === 'object' && deleteIfObject) {
        delete obj[propName];
      }
    }
  }
  
  public transformOptionsForIonicPicker(options: any[]): {text: string; value: any}[] {
    return options.map(e => ({
      ...e,
      text: e.name
    }));
  }

  /**
   * Method for clearing active subscriptions.
   * @function
   * @param {Subscription[]} subscriptions - Array with subscriptions to unsubscribe
   */
  public clearSubscriptions(subscriptions: Subscription[]): void {
    subscriptions.map(subscription => subscription.unsubscribe());
    subscriptions = [];
  }

  /**
   * Method for capitalising string first letter.
   * @function
   * @param {string} str - string to capitalise
   */
  public capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Method for converting object to html text (separated by <br>) by using object keys and values.
   * @function
   * @param {any} object - object to convert
   */
  public objectToEmailMessage(object: any): string {
    const data = Object.keys(object);

    let stringData = '<br/>';

    for (const element of data) {
      const key = this.capitalizeFirstLetter(element.split(/(?=[A-Z])/).join(' '));

      stringData += key + ': ' + object[element] + '<br/>';
    }

    return stringData;
  }

  calculateFinance(price: number): string | null {
    if (price <= 1000) {
      return null;
    }
    const month = 48;
    const interest = 10.8;
    const deposit = 1000;
    const total = price - deposit;
    if (!total || total <= 0) {
      return 'N/A';
    }
    return Number((total * interest / 12 / 100) + total / month).toFixed(0);
  }

  deepEqual(object1: any, object2: any): boolean {
    return deep(object1, object2);
  }

  isObject(object: any): boolean {
    return object != null && typeof object === 'object';
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  public downLoadFile(data: any, type: string): void {
    const blob = new Blob([data], {
      type
    });
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  public buildQueryParams(source: any): HttpParams {
    let target: HttpParams = new HttpParams();
    Object.keys(source).forEach((key: string) => {
      const value: string | number | boolean | Date | number[] = source[key];

      if (typeof value !== 'undefined' && value !== null) {
        if (Array.isArray(value)) {
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            target = target.append(key, element.toString());
          }
        } else if (typeof value !== 'object') {
          target = target.append(key, value.toString());
        }
      }
    });
    return target;
  }

  public getParamValueFromQueryString( paramName ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  public buildFormData(source: any): FormData {
    const formData = new FormData();
    Object.keys(source).forEach(key => {
      if (Array.isArray(source[key])) {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < source[key].length; index++) {
          const element = source[key][index];

          formData.append(key + '[]', element);
        }
      } else {

        if ((typeof source[key] !== 'undefined') && (source[key] !== null)) {
          formData.append(key, source[key]);
        } else {
          // console.log(source[key], key);
        }
      }
    });
    return formData;
  }

  async addImageToFormData(formData: FormData, image: Photo | Photo[] | string, key: string): Promise<void> {
   
    if (image && typeof image !== 'string') {
      if (Array.isArray(image)) {
        for (let index = 0; index < image.length; index++) {
          const element = image[index];
          const blob = await fetch(element.webviewPath).then(r => r.blob());
          formData.append(key, blob, element.filepath);
        }
      } else {
        const blob = await fetch(image.webviewPath).then(r => r.blob());
        formData.append(key, blob, image.filepath);
      }
    }
  }

  public formatCash(n: number): string | number | undefined {
    if (n < 1e3) {
      return n;
    }
    if (n >= 1e3 && n < 1e6) {
      return +(n / 1e3).toFixed(1) + 'K';
    }
    if (n >= 1e6 && n < 1e9) {
      return +(n / 1e6).toFixed(1) + 'M';
    }
    if (n >= 1e9 && n < 1e12) {
      return +(n / 1e9).toFixed(1) + 'B';
    }
    if (n >= 1e12) {
      return +(n / 1e12).toFixed(1) + 'T';
    }
  }

  public getYoutubeId(url: string): string | null {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11) ?
      match[2] :
      null;
  }

  public getYoutubeEmbedURL(url: string): string | null {
    const id = this.getYoutubeId(url);

    if (id) {
      return '//www.youtube.com/embed/' + id;
    } else {
      return null;
    }

  }

  public getKeyByValue(object: any, value: any): string | undefined {
    return Object.keys(object).find(key => object[key] === value);
  }

  public countSpecificCharacterInString(str: string, letter: string): number {
    let letterCount = 0;
    for (let position = 0; position < str.length; position++) {
      if (str.charAt(position) === letter) {
        letterCount += 1;
      }
    }
    return letterCount;
  }

  /**
   * Remove single character at particular index from string
   * @param index index of character you want to remove
   * @param str string from which character should be removed
   */
  public removeCharAtIndex(index: number, str: string): string {
    const tmp = str.split(''); // convert to an array
    tmp.splice(index - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
    return tmp.join(''); // reconstruct the string
  }

  public addNumberSeparator(nStr: string): string | null {
    if (nStr) {
      if (nStr.includes('.')) {
        const parts = nStr.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      } else {
        return nStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      return null;
    }

  }

  public setCaretPosition(elem: any, currentPosition: number, caretPos: number): boolean | undefined {
    const el = elem;

    el.value = el.value;
    // ^ this is used to not only get "focus", but
    // to make sure we don't have it everything -selected-
    // (it causes an issue in chrome, and having it doesn't hurt any other browser)

    if (el !== null) {

      if (el.createTextRange) {
        const range = el.createTextRange();
        range.move('character', caretPos);
        range.select();
        return true;
      } else {
        // (el.selectionStart === 0 added for Firefox bug)
        if (el.selectionStart || el.selectionStart === 0) {
          el.focus();
          el.setSelectionRange(caretPos, caretPos);
          return true;
        } else { // fail city, fortunately this never happens (as far as I've tested) :)
          el.focus();
          return false;
        }
      }
    }
  }

  public waitFor = async (condFunc: () => boolean): Promise<void> => {
    return new Promise((resolve) => {
      if (condFunc()) {
        resolve();
      }
      else {
        setTimeout(async () => {
          await this.waitFor(condFunc);
          resolve();
        }, 100);
      }
    });
  };

  generateDaysArray(numberOfDaysToSubtract: number): string[] {
    const days = [];
    const dateStart = moment().subtract(numberOfDaysToSubtract, 'days');
    const dateEnd = moment();
    while (dateEnd.diff(dateStart, 'days') >= 0) {
     days.push(dateStart.format('D MMM'));
     dateStart.add(1, 'days');
    }
    return days;
  }
}