import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonVirtualScroll, ModalController } from '@ionic/angular';
import { Unsubscribable, forkJoin } from 'rxjs';
import { distinctUntilChanged, debounceTime, startWith, pairwise, map } from 'rxjs/operators';
import { SearchClass } from 'src/app/classes/SearchClass';
import { ApiService } from 'src/app/services/api.service';
import { SearchCarService } from 'src/app/services/car/search-car.service';
import { StoredDataService } from 'src/app/services/stored-data.service';
import { DestroySubscribers, CombineSubscriptions } from 'src/app/static/destroySubscribers';
import { trackById } from 'src/app/static/utils';
import { CarSearchFilterModalPage } from '../car-search-filter-modal/car-search-filter-modal.page';

@Component({
  selector: 'app-brand-select-modal',
  templateUrl: './brand-select-modal.page.html',
  styleUrls: ['./brand-select-modal.page.scss'],
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class BrandSelectModalPage extends SearchClass implements OnInit, OnDestroy {
  @CombineSubscriptions()
  private subscriber: Unsubscribable;

  @ViewChild(IonContent) content: IonContent;
  @ViewChild("vScroll") public virtualScroll: IonVirtualScroll;

  @Input() openFromModal = false;
  public alphabet: String[] = [];
  trackBy = trackById;
  
  constructor(public carSearch: SearchCarService, public storage: StoredDataService, private api: ApiService, private modalCtrl: ModalController) {
    super();
    this.alphabet.push(String.fromCharCode(35));
    for (let i = 0; i < 26; i++) {
      this.alphabet.push(String.fromCharCode(65 + i));
    }
  }

  goLetter(letter: string) {
    
    const firstContact = (this.carSearch.formGroup.controls.brandId.value ? this.carSearch.options.models : this.storage.car.brands).find((c) => {
      return c.name.toUpperCase().charAt(0) === letter.toUpperCase();
    });
    const wantedIndex = this.virtualScroll.items.findIndex(
      (item) => item === firstContact
    );
    this.virtualScroll.positionForItem(wantedIndex).then((offset: number) => {
      this.content.scrollToPoint(0, offset);
    });
  }

  ngOnInit() {
    this.attachBrandListener();
    this.attachModelListener();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  close(): void {
    this.modalCtrl.dismiss();
    if (!this.openFromModal) {
      this.openFilterSelect(CarSearchFilterModalPage);
    }
  }

  async openFilterSelect(page: typeof CarSearchFilterModalPage): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: page,
      cssClass: 'car-search-modal-css'
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  destroy(): void {}

  getFirstLetter(name: string): string {
    return name.slice(0, 1);
  }

  checkPreviousValue(i: number, name: string): boolean {
    const element = this.carSearch.formGroup.controls.brandId.value ? this.carSearch.options.models[i - 1] : this.storage.car.brands[i - 1];
    if (element) {
      return element.name.slice(0, 1) !== name.slice(0, 1);
    } else {
      return true;
    }
  }

  myHeaderFn = (record, recordIndex, records) => {
    let result = null;
    if (recordIndex !== 0) {
      const prevRec = records[recordIndex - 1];
      const currRec = record;
      const prevName = prevRec.name;
      const currName = currRec.name;
      if (prevName !== null && currName !== null) {
        let prevCharCode = prevName.toUpperCase().charCodeAt(0);
        let currCharCode = currName.toUpperCase().charCodeAt(0);
        if (prevCharCode !== currCharCode) {
          let prevChar = prevName.toUpperCase().charAt(0);
          let currChar = currName.toUpperCase().charAt(0);
          let prevIsLetter = this.isLetter(prevChar);
          if (!prevIsLetter) {
            let currIsLetter = this.isLetter(currChar);
            result = currIsLetter ? currName.toUpperCase().charAt(0) : null;
          } else {
            result = currName.toUpperCase().charAt(0);
          }
        }
      }
    } else {
      const name = record.name;
      if (name !== null) {
        let nameChar = name.toUpperCase().charAt(0);
        let headerChar = this.isLetter(nameChar) ? nameChar : "#";
        result = headerChar.toUpperCase();
      }
    }
    return result;
  };

  public isLetter(char: any): boolean {
    return /[a-zA-Z]/.test(char);
  }

  private attachBrandListener = () => {
    this.subscriber = this.carSearch.formGroup.controls.brandId.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(10),
      startWith(null),
      pairwise(),
    ).subscribe(value => {
      this.carSearch.formGroup.controls.contains.reset();
      this.loadModels(value[0], value[1]);
    });
  }

  loadModels(oldValue: number[], newValue: number[] | any): any[] {
    newValue = [newValue];
    if (!newValue || newValue?.length === 0 || (Array.isArray(newValue) && (newValue?.includes(undefined) || newValue?.includes(null)))) {
      this.carSearch.formGroup.controls.modelVariantId.disable({emitEvent: false});
      this.carSearch.formGroup.controls.modelVariantId.reset(null, {emitEvent: false});
      this.carSearch.options.modelVariants = [];
      this.carSearch.formGroup.controls.modelId.disable({emitEvent: false});
      this.carSearch.formGroup.controls.modelId.reset(null, {emitEvent: false});
      this.scrollToTop();
      return this.carSearch.options.models = [];
    }
    const array2Sorted = newValue.slice().sort();
    const isEqual = oldValue && oldValue.length === newValue.length && oldValue.slice().sort().every((value, index) => {
        return value === array2Sorted[index];
    });
    if (!isEqual) {
      this.carSearch.options.models = [];
      this.carSearch.options.modelVariants = [];
      if (this.carSearch.formGroup.controls.modelVariantId.enabled) {
        this.carSearch.formGroup.controls.modelVariantId.disable({emitEvent: false});
      }
      if (this.carSearch.formGroup.controls.modelVariantId.value && this.carSearch.formGroup.controls.modelVariantId.value.length > 0) {
        this.carSearch.formGroup.controls.modelVariantId.reset(null, {emitEvent: false});
      }
      if (this.carSearch.formGroup.controls.modelId.value && this.carSearch.formGroup.controls.modelId.value.length > 0) {
        this.carSearch.formGroup.controls.modelId.reset(null, {emitEvent: false});
      }
      if (!this.carSearch.formGroup.controls.modelId.enabled) {
        this.carSearch.formGroup.controls.modelId.enable({emitEvent: false});
      }
      const requests = newValue.map(brandId => {
        const brand = this.storage.car.brands.find(e => e.id === brandId);
        return this.api.getModelsById(brandId).pipe(
          map(models => {
            return {
              name: brand.name,
              options: models
            };
          })
        );
      });
      forkJoin(requests).subscribe(async (value: any) => {
        this.carSearch.options.models = value[0]?.options;
        this.scrollToTop();
        this.resetSearch();
      });
    }
  }

  private attachModelListener = () => {
    this.subscriber = this.carSearch.formGroup.controls.modelId.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(10),
      startWith(null),
      pairwise(),
    ).subscribe(value => {
      this.loadModelVariants(value[0], value[1]);
    });
  }

  loadModelVariants(oldValue: number[], newValue: number[] | any): any[] {
    newValue = [newValue];
    if (!newValue || newValue?.length === 0 || (Array.isArray(newValue) && (newValue?.includes(undefined) || newValue?.includes(null)))) {
      this.carSearch.formGroup.controls.modelVariantId.disable({emitEvent: false});
      this.carSearch.formGroup.controls.modelVariantId.reset(null, {emitEvent: false});
      return this.carSearch.options.modelVariants = [];
    }
    const array2Sorted = newValue.slice().sort();
    const isEqual = oldValue && oldValue.length === newValue.length && oldValue.slice().sort().every((value, index) => {
        return value === array2Sorted[index];
    });
    if (!isEqual) {
      this.carSearch.options.modelVariants = [];
      if (this.carSearch.formGroup.controls.modelVariantId.value && this.carSearch.formGroup.controls.modelVariantId.value.length > 0) {
        this.carSearch.formGroup.controls.modelVariantId.reset(null, {emitEvent: false});
      }
      if (!this.carSearch.formGroup.controls.modelVariantId.enabled) {
        this.carSearch.formGroup.controls.modelVariantId.enable({emitEvent: false});
      }

      const requests = newValue.map(modelId => {
        // const allOptions = [].concat.apply([], this.carSearch.options.models.map(e => e.options));
        const model = this.carSearch.options.models.find(e => e.id === modelId);
        return this.api.getModelVariantsById(model.parentId || model.id).pipe(
          map(e => {
            return {
              name: model.name,
              options: e
            };
          })
        );
      });
      forkJoin(requests).subscribe((value: any) => {
        this.carSearch.options.modelVariants = value[0]?.options;
        this.close();
      });
    }
  }

  async scrollToTop(): Promise<void> {
    if (this.content) {
      const element = await this.content.getScrollElement();
      element.scrollTo({top: 0});
    }
  }
}
