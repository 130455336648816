import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from 'src/app/interface/Option';
import { PickerService } from 'src/app/services/picker.service';

@Component({
  selector: 'app-min-max-select',
  templateUrl: './min-max-select.component.html',
  styleUrls: ['./min-max-select.component.scss'],
})
export class MinMaxSelectComponent implements OnInit, ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() title: string;
  @Input() options: Option[];
  @Input() lines: 'full' | 'none' | 'inset' = 'inset';
  @Input() showDefaultOption = true;

  constructor(public picker: PickerService, private controlContainer: ControlContainer) { }

  ngOnInit() {}

  selectedValue(type: 'min' | 'max'): string {
    if (!this.control.value[type]) {
      return type[0].toUpperCase() + type.slice(1);
    }
    if (Array.isArray(this.control.value[type])) {
      return this.options.filter(e => this.control.value[type].includes(e.value)).map(e => {
        if (e.startYear) {
          return `${e.startYear} - ${e.endYear || 'now'} ${e.name ? ('(' + e.name + ')') : ''}`
        } else {
          return e.name;
        }
      }).join();

    } else {
      const option = this.options.find(e => e.value === this.control.value[type]);
      if (option?.startYear) {
        return `${option.startYear} - ${option.endYear || 'now'} ${option.name ? ('(' + option.name + ')') : ''}`
      } else {
        return option?.name;
      }
    }
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
     if (!this.control) {
      return false;
     }

     const { dirty, touched } = this.control;

     return this.invalid ? (dirty || touched) : false;
  }
}
