import { Pipe, PipeTransform } from '@angular/core';
import { Photo } from 'src/app/interface/Photo';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(image: string | Photo): string {
    if (image) {
      if (typeof image === 'string') {
        if (image.startsWith('data:') || image.startsWith('http') || image.startsWith('blob:')) {
          return image;
        }
      } else {
        return image.webviewPath;
      }
    }
    return image ? (environment.API + '/' + image) : '/assets/icon/not-found.svg';
  }

}
