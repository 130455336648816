import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'website'
})
export class WebsitePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return null;
    }

    return value.startsWith('http') ? value : 'http://' + value;
  }

}
