import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopPropogation]'
})
export class StopPropogationDirective {

  constructor() { }

  @HostListener('click', ['$event'])
  onClick($event: Event) {
    if ($event) { $event.stopPropagation(); }
  } 

}
