import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'engine'
})
export class EnginePipe implements PipeTransform {

  transform(value: number): string {
    return value === 0 || value < 0 ? '-' : (value + ' l');
  }

}
