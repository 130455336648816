import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mileage'
})
export class MileagePipe implements PipeTransform {

  transform(value: number, status: number, reverseBuy?: boolean): string {
    if (!reverseBuy) {
      if (status === 1) {
        return 'New';
      } else {
        if (!value || value <= 0) {
          return 'Contact seller';
        } else if (value > 0) {
          return new DecimalPipe('en-US').transform(value) + ' ' + (value === 1 ? 'mile' : 'miles');
        }
      }
    } else {
      if (value === 0) {
        return 'New';
      } else {
        return new DecimalPipe('en-US').transform(value) + ' ' + (value === 1 ? 'mile' : 'miles');
      }
    }
    return null;
  }

}
