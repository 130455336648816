import 'reflect-metadata';
import { Unsubscribable } from 'rxjs/internal/types';

export function DestroySubscribers(params: {destroyFunc: string} = {
  destroyFunc: 'ngOnDestroy'
}): any {

  return (target: any) => {
    const unsubscribableLike: {subscriptions: Unsubscribable[], unsubscribe: () => void} = {
      subscriptions: [],
      unsubscribe,
    };
    const subscriber: string = Reflect.getMetadata('subscription:name', target.prototype, 'subscriber');

    Object.defineProperty(target.prototype, subscriber ? subscriber : 'subscriber', {
      get: () => unsubscribableLike,
      set: subscription => unsubscribableLike.subscriptions.push(subscription),
    });

    if (typeof target.prototype[params.destroyFunc] !== 'function') {
      throw new Error(`${target.prototype.constructor.name} must implement ${params.destroyFunc}() lifecycle hook`);
    }

    target.prototype[params.destroyFunc] = ngOnDestroyDecorator(target.prototype[params.destroyFunc]);

    function ngOnDestroyDecorator(f: Function): Function {
      return () => {
        unsubscribe();
        return f.apply(this, arguments);
      };
    }

    function unsubscribe(): void {
      do {
        const sub: Unsubscribable | undefined = unsubscribableLike.subscriptions.shift();
        if ( sub && typeof sub.unsubscribe === 'function') { sub.unsubscribe(); }
      } while (unsubscribableLike.subscriptions.length);
    }

    return target;
  };
 }

export function CombineSubscriptions(): Function {
  return (target: any, propertyKey: string | symbol) => {
    Reflect.defineMetadata('subscription:name', propertyKey, target, 'subscriber');
  };
 }
