import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError, of, from } from 'rxjs';
import { filter, tap, mapTo, catchError, map, take, switchMap } from 'rxjs/operators';
import { AuthTokensResponse } from '../interface/AuthTokensResponse';
import { LoginParametersInterface } from '../interface/parameters/auth/LoginParameters';
import { RegisterParametersInterface } from '../interface/parameters/auth/RegisterParameters';
import { ApiService } from './api.service';
import { StoredDataService } from './stored-data.service';
import * as moment from 'moment';
import { UserInfo } from '../interface/User';
import { UtilsService } from './utils.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isInitialising = true;
  public isRefreshing = false;
  public authParams?: AuthTokensResponse;

  public userData: UserInfo;

  public refreshSubject = new BehaviorSubject(undefined);

  constructor(
    public api: ApiService,
    private router: Router,
    private storage: StoredDataService,
    private utils: UtilsService
  ) {
    this._initialiseAuthService();
  }

  private async _initialiseAuthService(): Promise<void> {
    await this.loadAuthParams();
    this.userData$.subscribe();
  }

  get userData$(): Observable<UserInfo | undefined> {
    if (this.userId) {
      return this.api.getAccount(this.userId).pipe(
        tap(userData => {
          if (userData) {
            this.userData = userData;
          }
        })
      );
    } else {
      return of(undefined).pipe(take(1));
    }
  }

  public async setUserDataToFormGroup(formGroup: FormGroup): Promise<void> {
    await this.utils.waitFor(() => !this.isInitialising);
    this.userData$.subscribe(() => {
      formGroup.patchValue(this.userData);
    });
  }

  public authParamsPromise(): Promise<AuthTokensResponse | null> {
    return this.storage.authParams();
  }

  private async loadAuthParams(): Promise<void> {
    const authParams = await this.storage.authParams();

    if (authParams) {
      this.authParams = authParams;
    }

    this.isInitialising = false;
  }

  public register(registerParams: RegisterParametersInterface): Observable<any> {
    return this.api.register(registerParams).pipe(
      tap(tokens => this.doLoginUser(tokens)),
      mapTo(true),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  public login(authParams: LoginParametersInterface): Observable<UserInfo> {
    return this.api.loginWithEmail(authParams).pipe(
      switchMap(response => from(this.doLoginUser(response))),
      switchMap(() => from(this.storage.userData(this.api.getAccount(this.authParams.userId)))),
      catchError(error => {
        return throwError(error.error);
      })
    );
  }

  public refresh(): Observable<any> {
    this.refreshSubject.next(undefined);
    this.setRefreshing(true);
    return this.api.refreshToken({
      token: this.accessToken,
      refreshToken: this.refreshToken
    }).pipe(
      tap(value => {
        this.doLoginUser(value);
      }, err => {
        console.log(err);

        this.setRefreshing(false);

        if (err.status === 400) {
          this.logout();
        }
      })
    );
  }

  public handleRefresh(): Observable<any> {
    
    if (!this.accessToken && !this.refreshToken) {
      return throwError('Unauthorised request');
    }
    if (!this.isAccessTokenExpired) {
      return of(this.accessToken).pipe(take(1));
    }
    if (!this.isRefreshing) {
      return this.refresh().pipe(
        map((token: AuthTokensResponse) => {
          return token.accessToken;
        }),
      );
    } else {
      return this.refreshSubject.pipe(
        filter(token => token != null),
        take(1),
        map((token: AuthTokensResponse) => {
          return token.accessToken;
        })
      );
    }
  }

  get refreshToken(): string {
    return this.authParams?.refreshToken;
  }

  get accessToken(): string {
    return this.authParams?.accessToken;
  }

  get expiration(): moment.Moment {
    return moment(this.authParams?.expiration);
  }

  get isAccessTokenExpired(): boolean {
    return moment().isAfter(this.expiration);
  }

  get userId(): string {
    return this.authParams?.userId;
  }

  get isLoggedIn(): boolean {
    return !!this.authParams;
  }

  private removeTokens(): void {
    this.setRefreshing(false);
    this.refreshSubject.next(undefined);
    this.authParams = null;
  }

  public async doLoginUser(authParams: AuthTokensResponse): Promise<void> {
    this.setRefreshing(false);
    await this.storeTokens(authParams);
  }

  public setRefreshing(active: boolean): void {
    this.isRefreshing = active;
  }

  private async storeTokens(tokens: AuthTokensResponse): Promise<void> {
    await this.storage.setParameter('authParams', tokens);
    this.refreshSubject.next(tokens.accessToken);
    this.authParams = tokens;
  }

  public async logout(): Promise<void> {
    this.removeTokens();
    this.userData = undefined;
    await this.storage.clearSensitiveData();
    await this.router.navigate(['/auth', 'login']);
  }

}
