import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findInArrayOfObjects',
  pure: false
})
export class FindInArrayOfObjectsPipe implements PipeTransform {

  transform(array: any[], key: string, value: any): unknown {
    return array.some(e => e[key] === value);
  }

}
