import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { CarClass } from 'src/app/classes/CarClass';
import { AuthService } from 'src/app/services/auth.service';
import { CompareCarsService } from 'src/app/services/car/compare-cars.service';
import { NotificationsHubService } from 'src/app/services/notifications-hub.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-car-card',
  templateUrl: './car-card.component.html',
  styleUrls: ['./car-card.component.scss'],
})
export class CarCardComponent implements OnInit {
  @Input() car: CarClass;
  @Input() controlsEnabled = false;
  @Input() switchPriceAndFinance = false;
  @Input() isProfile = false;
  @Input() nextLineFinance = false;

  @Output() closed = new EventEmitter();

  public domain = environment.API;
  public hovered = false;

  constructor(
    public notificationsHub: NotificationsHubService,
    public utils: UtilsService,
    public alertController: AlertController,
    public compare: CompareCarsService,
    private router: Router,
    public auth: AuthService
  ) { }

  async close(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Confirm action',
      message: 'By closing car advert it will disappear from search. Do you still wish to close car advert?',
      buttons: [
        {
          text: 'Close',
          handler: () => {
            this.car.close$.subscribe(() => {
              this.closed.emit(this.car.id);
            });
          }
        },
        'Cancel'
      ]
    });

    await alert.present();
  }

  async saveCar(): Promise<void> {
    this.notificationsHub.saveCar(this.car);
  }

  ngOnInit(): void {}

  onMouseEnter($event: MouseEvent): void {
    $event.stopPropagation();
    this.hovered = true;
  }

  onMouseLeave($event: MouseEvent): void {
    $event.stopPropagation();
    this.hovered = false;
  }

  onMouseLeaveImage($event: MouseEvent): void {}

  ngOnDestroy(): void {}

  navigateToCar(): void {
    this.router.navigate(["/car", this.car?.id]);
  }

}
