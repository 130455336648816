import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceRating'
})
export class PriceRatingPipe implements PipeTransform {

  transform(value: number): {color: string; name: string} {
    let response: {color: string; name: string};
    switch(value) {
      case 1: {
        response = {color: '#12DD00', name: 'Lower price'};
        break;
      }
      case 2: {
        response = {color: 'rgba(18, 221, 0, 0.7)', name: 'Lower price'};
        break;
      }
      case 3: {
        response = {color: '#F4A100', name: 'Good price'};
        break;
      }
      case 4: {
        response = {color: 'rgba(255, 87, 51, 0.7)', name: 'Fair price'};
        break;
      }
      case 5: {
        response = {color: '#FF5733', name: 'Higher price'};
        break;
      }
      default: { 
        response = {color: 'grey', name: 'Unknown'};
        break;
     } 
    }
    return response;
  }

}
