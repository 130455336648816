import { FormControl, FormGroup } from "@angular/forms";

export class SearchClass {
    public searchGroup = new FormGroup({
        search: new FormControl(),
        pageSize: new FormControl(20),
        pageNumber: new FormControl(1)
    });

    constructor() {}

    resetSearch(): void {
        this.searchGroup.reset({
            search: null,
            pageSize: 20,
            pageNumber: 1
        });
    }
}