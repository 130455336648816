import { interval, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AbstractControl } from "@angular/forms";

export const autoRefresh$ = interval(environment.refreshInterval).pipe(
    startWith(0),
    filter(() => document.visibilityState === 'visible')
);

export const phoneNumberSubscriber = (formControl: AbstractControl): Observable<any> => {
    return formControl.valueChanges.pipe(
        filter(() => !formControl.invalid),
        distinctUntilChanged(),
        map(value => value.startsWith('+44') ? value : ('+44' + value))
    );
}

export const emailSubscriber = (formControl: AbstractControl, isEmailAvailable: Function): Observable<any> => {
    return formControl.valueChanges.pipe(
        filter(() => !formControl.invalid),
        distinctUntilChanged(),
        switchMap(value => isEmailAvailable(value)),
        tap(reponse => {
            formControl.updateValueAndValidity({
                emitEvent: false
            });
        }, error => {
            if (error.error === 'Non valid email address') {
                formControl.setErrors({
                  error: 'Please, enter correct email address'
                });
              } else if (error.error === 'Email address is already taken') {
                formControl.setErrors({
                  error: 'We are sorry but this email is already in use'
                });
              }
        })
    );
}

export const millisecondsToHumanReadableTime = (ms, digits= 1) => {
    const levels = [
      ['ms', 1000],
      ['sec', 60],
      ['min', 60],
      ['hrs', 24],
      ['days', 7],
      ['weeks', (30 / 7)], // Months are intuitively around 30 days
      ['months', 12.1666666666666666], // Compensate for bakari-da in last step
      ['years', 10],
      ['decades', 10],
      ['centuries', 10],
      ['millenia', 10],
    ];
    let value = ms;
    let name: string | number = '';
    let step = 1;
    for (let i = 0, max = levels.length; i < max; ++i){
        value /= step;
        name = levels[i][0];
        step = levels[i][1] as number;
        if (value < step){
            break;
        }
    }
    return value.toFixed(digits) + ' ' + name;
};

export const trackById = (index: number, obj: any): number => {
    return obj.id;
};