import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acceleration'
})
export class AccelerationPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) {
      return 'N/A';
    }
    return value === 0 || value < 0 ? '-' : (value + ' s');
  }

}
