import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-all-chats',
  templateUrl: './all-chats.page.html',
  styleUrls: ['./all-chats.page.scss'],
})
export class AllChatsPage implements OnInit {

  constructor(public chat: ChatService, public auth: AuthService) { }

  ngOnInit() {}

  ionViewDidEnter(): void {
    this.chat.allowMessagesRequest = true;
  }

  ionViewDidLeave(): void {
    this.chat.allowMessagesRequest = false;
  }

}
