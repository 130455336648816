import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffix'
})
export class SuffixPipe implements PipeTransform {

  transform(value: any, suffix: any): unknown {
    if (!value) {
      return 'Contact seller';
    }
    return value + ' ' + suffix;
  }

}
