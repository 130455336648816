import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOutById',
  pure: false
})
export class FilterOutByIdPipe implements PipeTransform {

  transform(value: any[], id: any): unknown {
    if (!value) {
      return [];
    }
    return value.filter(e => e.id !== id);
  }

}
