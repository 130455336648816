import { Pipe, PipeTransform } from '@angular/core';
import {CarAdvert} from '../../interface/CarAdvert';

@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {

  transform(data: any[], value: string): any[] {
    if (!value) {
      return data;
    }
    return data.filter(e => e.name.toUpperCase().includes(value.toUpperCase()));
  }

}
