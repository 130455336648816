import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountNavigationPageModule } from '../scenes/account-navigation/account-navigation.module';
import { AddCarPageModule } from '../scenes/add-car/add-car.module';
import { AllChatsPageModule } from '../scenes/all-chats/all-chats.module';
import { HomeModule } from '../scenes/home/home.module';
import { LikedCarsPageModule } from '../scenes/liked-cars/liked-cars.module';

import { TabsPage } from './tabs.page';

const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children:
      [
        {
          path: 'home',
          children:
            [
              {
                path: '',
                loadChildren: () => HomeModule
              }
            ]
        },
        {
          path: 'account',
          children:
            [
              {
                path: '',
                loadChildren: () => AccountNavigationPageModule
              }
            ]
        },
        {
          path: 'add-car',
          children:
            [
              {
                path: '',
                loadChildren: () => AddCarPageModule
              }
            ]
        },
        {
          path: 'liked-cars',
          children:
            [
              {
                path: '',
                loadChildren: () => LikedCarsPageModule
              }
            ]
        },
        {
          path: 'chat',
          children:
            [
              {
                path: '',
                loadChildren: () => AllChatsPageModule
              }
            ]
        },
        {
          path: '',
          redirectTo: '/tabs/home',
          pathMatch: 'full'
        }
      ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TabsPageRoutingModule {}
