import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { CarClass } from 'src/app/classes/CarClass';
import { LoadingClass } from 'src/app/classes/LoadingClass';
import { CarAdvertsResponse, Model, ModelVariant } from 'src/app/interface/CarAdvert';
import { SavedSearch } from 'src/app/interface/SavedSearchResponse';
import { UKPostcodePattern } from 'src/app/static/patterns';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { StoredDataService } from '../stored-data.service';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class SearchCarService extends LoadingClass {
  public isSaved?: SavedSearch;
  public isFormInitial = true;
  public savedSearches: SavedSearch[] = [];

  public formGroup = new FormGroup({
    typeId: new FormControl(null),
    paymentType: new FormControl('Price'),
    longitude: new FormControl(),
    latitude: new FormControl(),
    contains: new FormControl(),
    brandId: new FormControl(),
    modelId: new FormControl(),
    modelVariantId: new FormControl(),
    owners: new FormControl(),
    sort: new FormControl('Relevance'),
    engine: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    year: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    price: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    finance: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    mileage: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    acceleration: new FormGroup({
      min: new FormControl(),
      max: new FormControl()
    }),
    delivery: new FormControl(),
    postcode: new FormControl(null, [Validators.pattern(UKPostcodePattern)]),
    radiusMeters: new FormControl('National'),
    video: new FormControl(false),
    gearBoxId: new FormControl(),
    dealerGuarantee: new FormControl(),
    fuelTypeId: new FormControl(),
    bodyTypeId: new FormControl(),
    colourId: new FormControl(),
    manufacturerApproved: new FormControl(),
    transmissionId: new FormControl(),
    sellerType: new FormControl(),
    exchange: new FormControl(),
    insuranceWriteOff: new FormControl(),
    pageSize: new FormControl(20),
    pageNumber: new FormControl(1)
  });
  totalCarsFound?: number;
  carsFound?: CarClass[];
  totalPages?: number;
  public carsFound$ = new BehaviorSubject(undefined);
  public options = {
    models: [],
    modelVariants: []
  };

  constructor(private api: ApiService, private router: Router, private utils: UtilsService, private auth: AuthService, private loadingCtrl: LoadingController, private storage: StoredDataService) {
    super(loadingCtrl);
    this.reset();
  }

  get brandName(): string {
    if (!this.formGroup.controls.brandId.value) {
      return 'Brand';
    } else {
      return this.storage.car.brands.find(e => e.id === this.formGroup.controls.brandId.value)?.name;
    }
  }

  get modelName(): string {
    if (!this.formGroup.controls.modelId.value || this.options.models.length === 0) {
      return 'model';
    } else {
      return this.options.models.find(e => e.id === this.formGroup.controls.modelId.value)?.name;
    }
  }

  async reset(): Promise<void> {
    this.formGroup.reset({
      pageSize: 20,
      pageNumber: 1,
      radiusMeters: 'National',
      sort: 'Relevance',
      video: false,
      paymentType: 'Price'
    });
    this.formGroup.controls.modelId.disable();
    this.formGroup.controls.modelVariantId.disable();

    this.options.models = [];
    this.options.modelVariants = [];
  }

  public checkIfFormInitial(formGroup: FormGroup): boolean {
    const value = {
      ...formGroup.value,
      pageNumber: null,
      pageSize: null,
      paymentType: null,
      typeId: null
    };
    this.utils.clean(value);
    const initialSample = {
      radiusMeters: 'National',
      sort: 'Relevance',
      video: false
    };
    return this.utils.deepEqual(initialSample, value);
  }

  saveSearch(): Promise<unknown> {
    if (!this.auth.isLoggedIn) {
      return this.router.navigate(['/auth', 'login']);
    }
    const value = this._loadValue();
    this.utils.clean(value);
    const formattedValue = {...value, pageSize: null, pageNumber: null};

    this.api.saveCarSearch(formattedValue).subscribe(response => {
      this.isSaved = response;
      this.savedSearches.push(response);
    });
  }

  search(redirect = false, showLoading = true, event?: any): void {
    if (showLoading) {
      this.presentLoading();
    }
    this.isFormInitial = this.checkIfFormInitial(this.formGroup);

    const value = this._loadValue();

    if (this.auth.isLoggedIn) {
      this.checkIfSearchIsSaved({...value, pageNumber: null, pageSize: null}).then((isSaved) => {
        this.isSaved = isSaved;
      });
    }

    let observer: Observable<CarAdvertsResponse>;
    if (value.contains && value.contains.startsWith('#') && value.contains.length > 4) {
      observer = this.api.searchCarsById(value.contains.replace('#', ''));
    } else {
      observer = this.api.searchCars(value);
    }

    observer.subscribe(response => {
      if (value.pageNumber === 1) {
        this.carsFound = [];
      }
      this.totalCarsFound = response.total;
      this.carsFound = [...this.carsFound, ...response.adverts];
      this.carsFound$.next(this.carsFound);
      this.totalPages = Math.ceil(this.totalCarsFound / this.formGroup.value.pageSize);
      if (showLoading) {
        this.complete();
      }
      if (event) {
        event.target.complete();
      }
      if (redirect) {
        this.router.navigate(['car', 'search']);
      }
    }, error => {
      if (showLoading) {this.setError(error.message);}
    });
  }

  loadSavedSearches(): void {
    this.api.getSavedCarSearches().subscribe(searches => {
      this.savedSearches = searches;
    });
  }

  private _loadModelAndVariant(): { models: Model[]; modelVariants: ModelVariant[] } {
    let models: Model[] = [];
    let modelVariants: ModelVariant[] = [];

    const modelValue = this.formGroup.controls.modelId.value;
    const modelVariantValue = this.formGroup.controls.modelVariantId.value;

    if (modelValue) {
      const model = this.options.models.find(e => e.id === modelValue);
      models.push(model);
    }
    if (modelVariantValue) {
      modelVariants.push(this.options.modelVariants.find(e => e.id === modelVariantValue));
    }
    return {models, modelVariants};
  }


  private _loadValue(): any {
    const {models, modelVariants} = this._loadModelAndVariant();
    return {
      ...this.formGroup.value,
      modelId:
        models.length === 0 || models.filter(e => !e.parentId).length === 0 ? null : models.filter(e => !e.parentId).map(e => e.id),
      subModelId:
        models.length === 0 ||
        models.filter(e => e.parentId).length === 0 ? null : models.filter(e => e.parentId).map(e => e.id),
      modelVariantId:
        modelVariants.length === 0 ||
        modelVariants.filter(e => !e.groupId).length === 0 ? null : modelVariants.filter(e => !e.groupId).map(e => e.id),
      MVarGroupId:
        modelVariants.length === 0 ||
        modelVariants.filter(e => e.groupId).length === 0 ? null : modelVariants.filter(e => e.groupId).map(e => e.id),
      radiusMeters: this.formGroup.value.radiusMeters !== 'National' ? this.formGroup.value.radiusMeters : null,
      minEngine: this.formGroup.value.engine.min,
      maxEngine: this.formGroup.value.engine.max,
      minPrice: this.formGroup.value.price.min,
      maxPrice: this.formGroup.value.price.max,
      minFinance: this.formGroup.value.finance.min,
      maxFinance: this.formGroup.value.finance.max,
      minYear: this.formGroup.value.year.min,
      maxYear: this.formGroup.value.year.max,
      minMileage: this.formGroup.value.mileage.min,
      maxMileage: this.formGroup.value.mileage.max,
      minAcceleration: this.formGroup.value.acceleration.min,
      maxAcceleration: this.formGroup.value.acceleration.max,
      sort: this.formGroup.value.sort !== 'Relevance' ? this.formGroup.value.sort : null,
      paymentType: null,
      price: null,
      year: null,
      mileage: null,
      finance: null,
      engine: null,
      acceleration: null,
    };
  }

  async removeSaveSearch(id?: number): Promise<void> {
    if (id) {
      this.api.deleteSavedCarSearch(id).subscribe(() => {
        this.isSaved = undefined;
        this.savedSearches = this.savedSearches.filter(e => e.id !== id);
      });
    } else {
      this.isSaved = await this.checkIfSearchIsSaved({...this._loadValue(), pageSize: null, pageNumber: null});
      if (this.isSaved) {
        this.removeSaveSearch(this.isSaved.id);
      }
    }
  }

  private async checkIfSearchIsSaved(value: any): Promise<undefined | SavedSearch> {
    const valueToCheck = {...value, MVarGroupId: null, mVarGroupId: value.MVarGroupId};
    this.utils.clean(valueToCheck);

    return new Promise((resolve) => {
      const exists = this.savedSearches.find(e => {
        const search = {
          ...e,
          userId: null,
          id: null,
          brands: null,
          models: null,
          subModels: null,
          modelVariants: null,
          mVarGroups: null,
          bodyTypes: null,
          colours: null,
          fuelTypes: null,
          transmissions: null,
          gearBox: null,
        };
        this.utils.clean(search);
        return this.utils.deepEqual(search, valueToCheck);
      });
      if (exists) {
        return resolve(exists);
      } else {
        return resolve(undefined);
      }
    });
  }
}
