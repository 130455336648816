import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fallback'
})
export class FallbackPipe implements PipeTransform {

  transform(value: any, fallback: any): unknown {
    return value ? value : fallback;
  }

}
