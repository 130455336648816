import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-required-content',
  templateUrl: './login-required-content.component.html',
  styleUrls: ['./login-required-content.component.scss'],
})
export class LoginRequiredContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
