import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SelectModalPage } from 'src/app/modals/select-modal/select-modal.page';
import { Option } from '../../../interface/Option';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() formControlName: string;
  @Input() options: Option[];
  @Input() multiple = false;
  @Input() type: 'list' | 'grid' = 'list';
  @Input() lines: 'full' | 'none' | 'inset' = 'inset';
  @Input() showDefaultOption = true;

  constructor(private controlContainer: ControlContainer, private modalCtrl: ModalController) {}

  ngOnInit(): void {}

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  selectedValue(): string {
    if (Array.isArray(this.control.value)) {
      return this.options.filter(e => this.control.value.includes(e.value)).map(e => {
        if (e.startYear) {
          return `${e.startYear} - ${e.endYear || 'now'} ${e.name ? ('(' + e.name + ')') : ''}`
        } else {
          return e.name;
        }
      }).join();

    } else {
      const option = this.options.find(e => e.value === this.control.value);
      if (option?.startYear) {
        return `${option.startYear} - ${option.endYear || 'now'} ${option.name ? ('(' + option.name + ')') : ''}`
      } else {
        return option?.name;
      }
    }
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  async openSelectModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SelectModalPage,
      cssClass: 'select-modal-css',
      componentProps: {
        title: this.title,
        options: this.options,
        type: this.type,
        formControl: this.control,
        multiple: this.multiple,
        showDefaultOption: this.showDefaultOption
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
     if (!this.control) {
      return false;
     }

     const { dirty, touched } = this.control;

     return this.invalid ? (dirty || touched) : false;
  }
}
