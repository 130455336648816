import { AbstractControl } from "@angular/forms";
import { MonoTypeOperatorFunction, Subscription } from "rxjs";
import { tap } from "rxjs/operators";

export function phoneHandler(phoneController: AbstractControl): MonoTypeOperatorFunction<any> {
  return tap({
    next(value: string) {
      if (!value || !value.startsWith('+44')) {
        phoneController.patchValue('+44');
      }
    },
    error(error: any) {},
    complete() {}
  });
}

export function unsubscribeFromSubscriptionIfExists(subscription: Subscription | undefined): MonoTypeOperatorFunction<any> {
  return tap({
    next(value: string) {
      if (subscription) {
        subscription.unsubscribe();
        subscription = undefined;
      }
    },
    error(error: any) {
      if (subscription) {
        subscription.unsubscribe();
        subscription = undefined;
      }
    },
    complete() {
      if (subscription) {
        subscription.unsubscribe();
        subscription = undefined;
      }
    }
  });
}
