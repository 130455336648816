import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() formControlName: string;
  @Input() lines: 'full' | 'none' | 'inset' = 'inset';
  @Input() type: string = 'text';

  @Input() icon?: string;
  @Input() image?: string;

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit() {}

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
