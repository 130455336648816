import { Chat, Message } from "../interface/ChatsResponse";
import { UserInfo } from "../interface/User";
import { ApiService } from "../services/api.service";
import { AuthService } from "../services/auth.service";


interface ChatBuddy {
    name: string;
    id: string;
    online: boolean;
    userData?: UserInfo;
}

export class ChatClass implements Chat {
    id: number;
    fuser: string;
    suser: string;
    firstUserName: string;
    secondUserName: string;
    lastMessage: Message;
    unread: number;
    public messages: Message[] = [];
    online: boolean = false;
  
    public informationAboutChatBuddy?: ChatBuddy;
  
    constructor(
      chat: Chat,
      public auth: AuthService,
      public api: ApiService
    ) {
      this.id = chat.id;
      this.fuser = chat.fuser;
      this.suser = chat.suser;
      this.firstUserName = chat.firstUserName;
      this.secondUserName = chat.secondUserName;
      this.lastMessage = chat.lastMessage;
      this.unread = chat.unread;
      this.online = chat.online;
      this.informationAboutChatBuddy = this.getChatUser(this);
      if (this.informationAboutChatBuddy.name !== 'Administrator') {
        this.api.getAccount(this.informationAboutChatBuddy.id).subscribe(response => {
          this.informationAboutChatBuddy.userData = response;
        });
      }
    }
  
    public resetUnread = () => {
      this.unread = 0;
    }
  
    private getChatUser(chat: Chat): { name: string; id: string; online: boolean; } {
      if (chat.fuser === this.auth.userId) {
        return {
          name: chat.secondUserName,
          id: chat.suser,
          online: chat.online
        };
      } else {
        return {
          name: chat.firstUserName,
          id: chat.fuser,
          online: chat.online
        };
      }
    }
  
  }
  