import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {
  isWeb = false;

  constructor(private iab: InAppBrowser) {}

  openStripeHomePage(): void {
    const url = 'https://stripe.com';
    this.iab.create(url);
  }

  openStripeTermsAndConditions(): void {
    const url = 'https://stripe.com/connect-account/legal';
    this.iab.create(url);
  }

  openCustomLink(link: string): void {
    this.iab.create(link);
  }

}
