import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeKitModule } from '../pipe-kit/pipe-kit.module';
import { SeeMoreComponent } from './see-more/see-more.component';
import { DirectiveKitModule } from '../directive-kit/directive-kit.module';
import { LoginRequiredContentComponent } from './login-required-content/login-required-content.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NoContentFoundComponent } from './no-content-found/no-content-found.component';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { CreditCardComponent } from './credit-card/credit-card.component';
import { GridLayoutComponent } from './grid-layout/grid-layout.component';



@NgModule({
  declarations: [SeeMoreComponent, LoginRequiredContentComponent, NoContentFoundComponent, ContentLoaderComponent, CreditCardComponent, GridLayoutComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    PipeKitModule,
    DirectiveKitModule
  ],
  exports: [
    PipeKitModule,
    SeeMoreComponent,
    LoginRequiredContentComponent,
    NoContentFoundComponent,
    ContentLoaderComponent,
    CreditCardComponent,
    GridLayoutComponent,
    DirectiveKitModule
  ]
})
export class CommonKitModule { }
