import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@aspnet/signalr';
import { ToastController } from '@ionic/angular';
import { Unsubscribable } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CarClass } from '../classes/CarClass';
import { CombineSubscriptions, DestroySubscribers } from '../static/destroySubscribers';
import { CatchAllErrors } from '../static/errors';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { NetworkService } from './network.service';
import { SignalRHelperService } from './signal-rhelper.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
@DestroySubscribers()
export class NotificationsHubService {
  private hubConnection?: signalR.HubConnection;

  public totalSavedCars: number = 0;
  public savedCarsIds: number[] = [];
  public savingCars: CarClass[] = [];

  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  constructor(
    private auth: AuthService,
    private router: Router,
    private network: NetworkService,
    private api: ApiService,
    private toastController: ToastController,
    private signalRHelper: SignalRHelperService,
    private utils: UtilsService
  ) {}

  private createHub(): void {
    if (!this.hubConnection) {
      const hubParams: signalR.IHttpConnectionOptions = {
        accessTokenFactory: () => this.auth?.accessToken,
        logMessageContent: false,
        logger: 6,
      };
      this.hubConnection = this.signalRHelper.buildHub(
        environment.API + '/NotificationHub',
        hubParams
      );
      this.subscriber = this.signalRHelper.connectionMonitorOnOnline(this.saveWaitingCars).subscribe();
      this.registerSignalEvents();
    }
  }

  public startConnection = async () => {
    await this.utils.waitFor(() => !this.auth.isInitialising);
    this.createHub();

    if (this.signalRHelper.isHubDisconnected(this.hubConnection) && this.auth.isLoggedIn) {

        this.api.getSavedCars().pipe(
          tap((response) => {
            this.totalSavedCars = response.total;
            this.savedCarsIds = response.adverts.map(e => e.id);
          }),
          switchMap(() => this.hubConnection.start()),
          switchMap(() => this.hubConnection.invoke('Connect', this.auth.userId)),
          catchError(() => {
            return this.signalRHelper.reconnectOnNetworkAvailable(this.startConnection);
          })
        ).subscribe(() => {
          console.log('Notifications Hub Connected');
        });
  

    }
  }

  private registerSignalEvents = () => {
    this.registerSavedAdverts();
    this.registerNotifications();
    this.registerOnClose();
  }

  private registerNotifications = () => {
    this.hubConnection.on('RecieveNotification', (args) => {});
  }

  private registerSavedAdverts = () => {
    this.hubConnection.on('SavedAdverts', (args) => {
      this.totalSavedCars = args.item2;
      this.savedCarsIds = args.item1;
    });
  }

  private registerOnClose = () => {
    this.hubConnection.onclose((err) => {
      this.signalRHelper.reconnectOnNetworkAvailable(this.startConnection).subscribe();
    });
  }

  saveWaitingCars = () => {
    this.savingCars.map(car => this.saveCar(car));
    this.savingCars = [];
  }

  @CatchAllErrors
  private async presentToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: 'No internet connection available. We will save your request and retry when online.',
      duration: 2000
    });
    toast.present();
  }

  public saveCar(item: CarClass, $event?: Event, changeDetector?: ChangeDetectorRef): void {
    if ($event) { $event.stopPropagation(); }
    if (!this.auth.isLoggedIn) {
      this.router.navigate(['/auth', 'login'], { state: { redirect: this.router.url } });
    } else {
      if (this.network.status === 'offline') {
        this.toggleCarForLaterOnNetworkOffline(item);
      } else {
        this.toggleCarOnOnline(item, changeDetector)
      }
    }
  }

  isCarSaved(item: CarClass): boolean {
    if (!item) { return false; }
    return this.savedCarsIds.some(e => e === item.id);
  }

  private toggleCarForLaterOnNetworkOffline(item: CarClass): void {
    if (!this.savingCars.some(e => e.id === item.id)) {
      this.savingCars = [...this.savingCars, item];
    } else {
      this.savingCars = this.savingCars.filter(e => e.id === item.id);
    }
    this.presentToast();
  }

  private async toggleCarOnOnline(item: CarClass, changeDetector?: ChangeDetectorRef): Promise<void> {
    if (this.auth.isLoggedIn) {
      await this.hubConnection.invoke('SaveAdvert', this.auth.userId, item.id);
    }

    if (changeDetector) {
      changeDetector.detectChanges();
    }
  }

  get totalCarsSaved(): number {
    return this.savedCarsIds.length;
  }

  @CatchAllErrors
  public async disconnect(): Promise<void> {
    this.totalSavedCars = 0;
    this.savingCars = [];
    this.savedCarsIds = [];
    if (this.signalRHelper.isHubConnected(this.hubConnection)) {
      await this.hubConnection.stop();
    }
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {}

}
