import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: number): string {
    if (typeof value !== 'number') return value;
    return '£' + new DecimalPipe('en-US').transform(value);
  }

}
