import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Unsubscribable } from 'rxjs';
import { distinctUntilChanged, debounceTime, startWith, pairwise, map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SearchCarService } from 'src/app/services/car/search-car.service';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { ModalService } from 'src/app/services/modal.service';
import { PickerService } from 'src/app/services/picker.service';
import { StoredDataService } from 'src/app/services/stored-data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { booleanResponse, mileage, owners, sellerType } from 'src/app/static/data';
import { DestroySubscribers, CombineSubscriptions } from 'src/app/static/destroySubscribers';
import { BrandSelectModalPage } from '../brand-select-modal/brand-select-modal.page';

@Component({
  selector: 'app-car-search-filter-modal',
  templateUrl: './car-search-filter-modal.page.html',
  styleUrls: ['./car-search-filter-modal.page.scss'],
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class CarSearchFilterModalPage implements OnInit, OnDestroy {
  @CombineSubscriptions()
  private subscriber: Unsubscribable;
  public showAll = false;

  public mileage = mileage;
  public booleanOptions = booleanResponse;
  public owners = owners;
  public sellerType = sellerType;
  
  constructor(
    private modalCtrl: ModalController, public carSearch: SearchCarService, private auth: AuthService, private api: ApiService, public utils: UtilsService, public picker: PickerService, public storage: StoredDataService, private geocoder: GeocodingService, private modal: ModalService) {}

  ngOnInit(): void {
    this.loadOptionsIfRequired();
    this.attachPostcodeListener();
    if (this.auth.isLoggedIn) {
      this.carSearch.loadSavedSearches();
    }
  }
 
  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {}

  close(): void {
    this.modalCtrl.dismiss();
  }

  openBrandSelect(): void {
    this.openBrand(BrandSelectModalPage);
  }

  async openBrand(page: typeof BrandSelectModalPage): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: page,
      cssClass: 'select-brand-modal-css',
      componentProps: {
        openFromModal: true
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }
  

  private loadOptionsIfRequired = async () => {
    if (this.carSearch.formGroup.controls.brandId.value && this.carSearch.formGroup.controls.brandId.value.length > 0) {
      await new Promise((resolve) => {

        for (let index = 0; index < this.carSearch.formGroup.controls.brandId.value.length; index++) {
          this.carSearch.formGroup.controls.modelId.enable();
          const brandId = this.carSearch.formGroup.controls.brandId.value[index];
          const brand = this.storage.car.brands.find(e => e.id === brandId);
          this.api.getModelsById(brandId).subscribe((models) => {
            this.carSearch.options.models = [...this.carSearch.options.models, {name: brand.name, options: models}];
            if (index === (this.carSearch.formGroup.controls.brandId.value.length - 1)) {
              resolve(true);
            }
          });
        }
      });
    }
    if (this.carSearch.formGroup.controls.modelId.value && this.carSearch.formGroup.controls.modelId.value.length > 0) {
      this.carSearch.formGroup.controls.modelVariantId.enable();

      for (let index = 0; index < this.carSearch.formGroup.controls.modelId.value.length; index++) {
        const modelId = this.carSearch.formGroup.controls.modelId.value[index];
        const allOptions = [].concat.apply([], this.carSearch.options.models.map(e => e.options));
        const model = allOptions.find(e => e.id === modelId);

        this.api.getModelVariantsById(model.parentId || model.id).subscribe((modelVariants) => {
          this.carSearch.options.modelVariants = [...this.carSearch.options.modelVariants, {name: model.name, options: modelVariants}];
        });
      }
    }
    if (this.carSearch.formGroup.value.finance.min || this.carSearch.formGroup.value.finance.max) {
      this.carSearch.formGroup.controls.paymentType.patchValue('Finance');
    }
  }

  private attachPostcodeListener = () => {
    this.subscriber = this.carSearch.formGroup.controls.postcode.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      startWith(null),
      pairwise(),
    ).subscribe(async value => {
      if (value[0] !== value[1]) {
        this.carSearch.formGroup.controls.longitude.patchValue(null);
        this.carSearch.formGroup.controls.latitude.patchValue(null);
      }
      if (!this.carSearch.formGroup.controls.postcode.invalid && value[1]) {
        const {lat, lng} = await this.geocoder.geocode(this.carSearch.formGroup.value.postcode);
        this.carSearch.formGroup.controls.latitude.patchValue(lat);
        this.carSearch.formGroup.controls.longitude.patchValue(lng);
      }
    });
  }

  search(): void {
    this.carSearch.formGroup.controls.pageNumber.patchValue(1);
    this.carSearch.search(true);
    this.close();
  }
}
