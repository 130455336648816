import { Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { Plugins } from '@capacitor/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdvertImage } from 'src/app/interface/Image';
const { Device } = Plugins;

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true
    }
  ]
})
export class ImageUploaderComponent implements ControlValueAccessor, OnInit {
  @ViewChild('fileInput') myInputVariable: ElementRef;
  @Input() formControlName: string;
  @Input() formControl: FormControl;

  @Input()
  uploadedImages: AdvertImage[];

  files: any = [];

  constructor(private imagePicker: ImagePicker, private controlContainer: ControlContainer) { }

  ngOnInit() {}

  async onClick(): Promise<void> {

    const info = await Device.getInfo();

    if (info.platform === 'android' || info.platform === 'ios') {
      this.selectImagesOnMobile();
    } else {
      this.selectImagesOnWeb();
    }
  }

  private async selectImagesOnMobile(): Promise<any> {
    const permission = await this.imagePicker.hasReadPermission();

    if (!permission) {
      await this.imagePicker.requestReadPermission();
    }
    const options = {
      maximumImagesCount: 20,
      quality: 100,
    };
    const results = await this.imagePicker.getPictures(options);
    return results;
  }

  private async selectImagesOnWeb(): Promise<any> {
    this.myInputVariable.nativeElement.click();
  }

  uploadFile(event): void {
    for (const element of event) {
      this.files.push(element);
    }
    this.control.patchValue(this.files);
  }

  deleteAttachment(index): void {
    this.files.splice(index, 1);
    this.control.patchValue(this.files);
  }

  deleteUpploadedAttachment(index): any {
    if (this.uploadedImages.length === 3) {
      // return this.modal.openWarning('You cannot delete last three uploaded images. Upload some first and then try again');
    }
    this.uploadedImages.splice(index, 1);
    // this.control.patchValue(this.files);
  }

  get control(): AbstractControl {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
