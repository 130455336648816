import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { PaymentMethod } from 'src/app/interface/CardsResponse';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit {
  @Input() card: PaymentMethod;
  @Input() flipable = false;
  @Input() deletable = false;

  @Output() deletePaymentMethod = new EventEmitter();

  constructor(private api: ApiService, private loader: LoadingController) {}

  ngOnInit(): void {
  }

  deleteCard(): void {
    //this.modal.openConfirm().subscribe(response => {
      //if (response) {
        this.loader.create();
        this.api.deleteCard(this.card.id).subscribe(() => {
          this.deletePaymentMethod.emit(this.card.id);
          this.loader.dismiss();
        });
      //}
    //});
  }
}
