import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LoadingController, ModalController } from '@ionic/angular';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoadingClass } from 'src/app/classes/LoadingClass';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-card-modal',
  templateUrl: './add-card-modal.page.html',
  styleUrls: ['./add-card-modal.page.scss'],
})
export class AddCardModalPage extends LoadingClass implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  readonly cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        backgroundColor: '#f3f5f9',
        iconColor: 'black',
        color: '#31325F',
        fontWeight: '400',
        fontFamily: '"Roboto", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: 'black',
        },
      },
    },
  };
  readonly elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  readonly formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
  });

  constructor(
    private stripeService: StripeService,
    private api: ApiService,
    loadingController: LoadingController,
    private modalCtrl: ModalController,
    private iab: InAppBrowser
  ) {
    super(loadingController);
  }

  ngOnInit(): void {}

  reset(): void {
    this.formGroup.reset({emitEvent: false});
    this.card.element.clear();
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  createToken(): void {
    this.presentLoading();
    const name = this.formGroup.get('name').value;

    this.stripeService
    .createToken(this.card.element, { name })
    .subscribe((result) => {
      if (result.token) {
        // Use the token
        this.add(result.token.id);
        // this.tokenResponse.emit(result.token.id);
      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
        this.setError(this.error);
      }
    });
  }

  openStripe(): void {
    this.iab.create('https://stripe.com');
  }

  add(token: string): void {
    this.stripeService.createPaymentMethod({type: 'card', card: {token}}).pipe(switchMap(value => {
      if (!value.error) {
        return this.api.addCard(value.paymentMethod.id);
      } else {
        return throwError('Could not add payment method');
      }
    })).subscribe(() => {
      this.complete();
      setTimeout(() => {
        this.close();
      }, 2000 );
    }, error => {
      this.setError(error?.message);
    });
  }

}
