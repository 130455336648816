import {
  Injectable,
} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, delay, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthTokensResponse } from '../interface/AuthTokensResponse';
import { UtilsService } from '../services/utils.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  private authenticationLessApis = [
    environment.authenticationAPI.refreshToken,
    environment.carAdvertAPI.searchAdvertsById,
    environment.carAdvertAPI.shuffle,
    environment.carAdvertAPI.bestDeals,
    environment.carFuelTypeAPI.getAllFuelTypes,
    environment.carFuelTypeAPI.individualFuelType,
    environment.carGearboxesAPI.getAllGearboxes,
    environment.carGearboxesAPI.individualGearbox,
    environment.carModelsAPI.getAllModels,
    environment.carModelsAPI.individualModel,
    environment.carBodiesAPI.getAllBodies,
    environment.carBodiesAPI.individualBody,
    environment.carBodyTypeAPI.getAllBodyTypes,
    environment.carBodyTypeAPI.individualBodyType,
    environment.carBrandsAPI.getAllBrands,
    environment.carBrandsAPI.individualBrand,
    environment.carEquipmentsAPI.getAllEquipments,
    environment.carEquipmentsAPI.individualEquipment,
    environment.carModelVariantsAPI.getAllModelVariants,
    environment.carModelVariantsAPI.individualModelVariant,
    environment.carTransmissionAPI.getAllTransmissions,
    environment.carTransmissionAPI.individualTransmission,
    environment.colourAPI.getAllColours,
    environment.colourAPI.individualColour,
    // environment.availabilityAPI.isEmailAvailable,
    // environment.availabilityAPI.isPhoneAvailable,
  ];

  constructor(
    private auth: AuthService
  ) {}

  intercept(req: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    const regex = /http[s]?:\/\/((.*?)\.?)topcar\.co.uk[\/a-zA-Z]*/;
    const isTopCar = regex.test(req.url);
    const sendToken = isTopCar || !req.url.startsWith('http');

    if (!isTopCar || this.authenticationLessApis.includes(req.url)) {
      return next.handle(req);
    }

    return from(this.auth.authParamsPromise()).pipe(
      switchMap((authParams) => {
        this.auth.authParams = authParams;
        if (authParams && sendToken) {
          if (this.auth.isAccessTokenExpired) {
            return this.handle401Error(req, next);
          } else {
            req = this.addToken(req, authParams.accessToken);
          }
        }
    
        return next.handle(req).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401 && sendToken) {
              return this.handle401Error(req, next);
            } else {
              return throwError(error);
            }
          })
        );
      })
    )
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.auth.isRefreshing) {
      return this.auth.refresh().pipe(
        switchMap((token: AuthTokensResponse) => {
          return next.handle(this.addToken(request, token.accessToken))
        }),
      );
    } else {
      return this.auth.refreshSubject.pipe(
        filter(token => token),
        take(1),
        switchMap((accessToken: any) => {
          return next.handle(
            this.addToken(request, accessToken)
          );
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true
    });
  }

}
