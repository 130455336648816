import { Observable } from "rxjs";
import { Brand, Model, ModelVariant, Trim, Equipment, BodyType, FuelType, Transmission, GearBox, CarAdvert, Location, Body } from "../interface/CarAdvert";
import { ResponseWithHex } from "../interface/CommonResponses";
import { AdvertImage, CarImage } from "../interface/Image";
import { UserInfo } from "../interface/User";
import { VehiclePurchase } from "../interface/VehiclePurchase";
import { ApiService } from "../services/api.service";

export class CarClass implements CarAdvert {
    id: number;
    userId: string;
    status: number;
    created: Date;
    expiration: Date;
    location: Location;
    dealerType: number;
    rejectionReason ? : any;
    views: number;
    type: number;
    name: string;
    description: string;
    price: number;
    rentPrice: number;
    rentDuration: number;
    rentPriceMid: number;
    finance: number;
    address: string;
    plateNumber: string;
    brandId: number;
    brand: Brand;
    modelId: number;
    model: Model;
    modelVariantId: number;
    modelVariant: ModelVariant;
    bodyId: number;
    body: Body;
    trimId: number;
    trim: Trim;
    equipmentId: number;
    equipment: Equipment;
    bodyTypeId: number;
    bodyType: BodyType;
    fuelTypeId: number;
    fuelType: FuelType;
    transmissionId: number;
    transmission: Transmission;
    gearBoxId: number;
    gearBox: GearBox;
    colourId: number;
    colour: ResponseWithHex;
    engine: number;
    power: number;
    customColour ? : any;
    mileage: number;
    year: number;
    owners ? : any;
    exchange: boolean;
    videoLink ? : any;
    accident: boolean;
    dealerGuarantee ? : any;
    manufactureProof ? : any;
    delivery: boolean;
    acceleration: number;
    callsAvailable: boolean;
    messagesAvailable: boolean;
    onlinePayment: boolean;
    images: AdvertImage[];
    userInfo ? : UserInfo;
    saved: boolean;
    longitude: number;
    latitude: number;
    distance ? : any;
    order ? : any;
    vehiclePayment ?: VehiclePurchase;
    priceRating ? : 0 | 1 | 2 | 3 | 4 | 5;
    carReports: any[];

    thumbnailKey: any;


    similarCars$: Observable < any > = this.advertType === 'car' ? this.api.searchCars({
        brandId: this.car.brand?.id,
        modelId: this.car.model?.id,
        pageSize: 5,
        pageNumber: 1
    }) : this.api.getReverseBuyCars({
        brandId: this.car.brand?.id,
        modelId: this.car.model?.id,
        pageSize: 5,
        pageNumber: 1
    });
    otherCarsFromThisSeller$ = this.api.getUserCars(this.car.userId, 3, 1, 5);
    close$ = this.advertType === 'car' ? this.api.closeCar(this.car.id) : this.api.closeReverseBuyCar(this.car.id);
    userInfo$ = this.api.getAccount(this.car.userId);

    currentImage: string;
    urlParameters: any[];
    urlEditParameters: any[];

    rowBeginning = 0;
    rowEnd = 8;

    public shouldShowPhoneNumber = false;
    public shouldShowAppointment = false;
    public shouldShowContactViaEmail = false;
    public shouldShowSave = false;
    public shouldShowReports = false;
    public isSilverReportPurchased = false;
    public isGoldReportPurchased = false;

    private sortByOrder = (a: CarImage | AdvertImage, b: CarImage | AdvertImage) => a.order - b.order;

    constructor(
        public car: CarAdvert,
        private api: ApiService,
        public advertType: 'car' | 'reverse'
    ) {
        this.thumbnailKey = advertType === 'reverse' ? 'imagePath' : 'imagePathSmall';
        this.id = this.car.id;
        this.userId = this.car.userId;
        this.status = this.car.status;
        this.created = this.car.created;
        this.expiration = this.car.expiration;
        this.location = this.car.location;
        this.dealerType = this.car.dealerType;
        this.rejectionReason = this.car.rejectionReason;
        this.views = this.car.views;
        this.type = this.car.type;
        this.name = this.car.name;
        this.description = this.car.description;
        this.price = this.car.price;
        this.rentPrice = this.car.rentPrice;
        this.rentDuration = this.car.rentDuration;
        this.rentPriceMid = this.car.rentPriceMid;
        this.finance = this.car.finance;
        this.address = this.car.address;
        this.plateNumber = this.car.plateNumber;
        this.brandId = this.car.brandId;
        this.brand = this.car.brand;
        this.modelId = this.car.modelId;
        this.model = this.car.model;
        this.modelVariantId = this.car.modelVariantId;
        this.modelVariant = this.car.modelVariant;
        this.bodyId = this.car.bodyId;
        this.body = this.car.body;
        this.trimId = this.car.trimId;
        this.trim = this.car.trim;
        this.equipmentId = this.car.equipmentId;
        this.equipment = this.car.equipment;
        this.bodyTypeId = this.car.bodyTypeId;
        this.bodyType = this.car.bodyType;
        this.fuelTypeId = this.car.fuelTypeId;
        this.fuelType = this.car.fuelType;
        this.transmissionId = this.car.transmissionId;
        this.transmission = this.car.transmission;
        this.gearBoxId = this.car.gearBoxId;
        this.gearBox = this.car.gearBox;
        this.colourId = this.car.colourId;
        this.colour = this.car.colour;
        this.engine = this.car.engine;
        this.power = this.car.power;
        this.customColour = this.car.customColour;
        this.mileage = this.car.mileage;
        this.year = this.car.year;
        this.owners = this.car.owners;
        this.exchange = this.car.exchange;
        this.videoLink = this.car.videoLink;
        this.accident = this.car.accident;
        this.dealerGuarantee = this.car.dealerGuarantee;
        this.manufactureProof = this.car.manufactureProof;
        this.delivery = this.car.delivery;
        this.acceleration = this.car.acceleration;
        this.callsAvailable = this.car.callsAvailable;
        this.shouldShowPhoneNumber = this.callsAvailable;
        this.messagesAvailable = this.car.messagesAvailable;
        this.images = this.car.images.sort(this.sortByOrder);
        this.currentImage = this.advertType === 'reverse' ? this.car.images[0].imagePath : this.car.images[0].imagePathMedium;
        this.userInfo = this.car.userInfo;
        this.saved = this.car.saved;
        this.longitude = this.car.longitude;
        this.latitude = this.car.latitude;
        this.distance = this.car.distance;
        this.order = this.car.order;
        this.priceRating = this.car.priceRating;
        this.carReports = this.car.carReports;
        this.urlParameters = this.advertType === 'reverse' ? ['/reverse-buy', 'car', this.id] : ['/car', this.id];
        this.urlEditParameters = this.advertType === 'reverse' ? ['/reverse-buy', 'car', this.id, 'edit'] : ['/car', 'edit', this.id];
        this.currentImage = this.images[0][advertType === 'reverse' ? 'imagePath' : 'imagePathMedium'];

        this.vehiclePayment = this.car.vehiclePayment;
        
        this.shouldShowSave = this.checkIfShouldShowSave;
        this.shouldShowReports = this.checkIfShouldShowReports;

        this.isSilverReportPurchased = this.checkIfSilverReportPurchased;
        this.isGoldReportPurchased = this.checkIfGoldReportPurchased;

        this.onlinePayment = this.car.onlinePayment;
    }

    //#region Save
    get checkIfShouldShowSave(): boolean {
        if (this.advertType === 'reverse') {
            return false;
        } else {
            return true;
        }
    }
    //#endregion

    //#region Reports
    get checkIfShouldShowReports(): boolean {
        if (this.advertType === 'reverse' || !this.plateNumber) {
            return false;
        } else {
            return true;
        }
    }

    get checkIfSilverReportPurchased(): boolean {
        return this.carReports?.some(e => e.type === 1);
    }

    get checkIfGoldReportPurchased(): boolean {
        return this.carReports?.some(e => e.type === 2);
    }
    //#endregion

    //#region Parameters from specifications
    get fuelTypeInSpecifications(): string | null {
        const specification = this.trim.specificationValues.find((e: any) => e.specification.name === 'fuel type');
        if (specification) {
            return specification.value;
        }
        return null;
    }

    get gearboxInSpecifications(): string | null {
        const specification = this.trim.specificationValues.find((e: any) => e.specification.name === 'gearbox');
        if (specification) {
            return specification.value;
        }
        return null;
    }

    get engineSizeInSpecifications(): string | null {
        const specification = this.trim.specificationValues.find((e: any) => e.specification.name === 'engine oil capacity');

        if (specification) {
            return specification.value;
        }
        return null;
    }

    get accelerationInSpecifications(): string | null {
        const specification = this.trim.specificationValues.find((e: any) => e.specification.name === 'acceleration 0 - 100 km/h');
        if (specification) {
            return specification.value;
        }
        return null;
    }
    //#endregion

    //#region Compare
    get checkIfShouldShowCompare(): boolean {
        if (this.advertType === 'reverse') {
            return false;
        } else {
            return true;
        }
    }
    //#endregion
}