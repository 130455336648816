import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TogglerComponent),
      multi: true
    }
  ],
})
export class TogglerComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() formControlName: string;
  @Input() lines: 'full' | 'none' | 'inset' = 'inset';

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit() {}

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  clearInput(): void {
    this.control.setValue('');
  }

  registerOnTouched(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    // this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    // this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    // this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
