import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { interval, Unsubscribable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChatClass } from '../classes/ChatClass';
import { ChatFromSocket, Message } from '../interface/ChatsResponse';
import { UserInfo } from '../interface/User';
import { CombineSubscriptions, DestroySubscribers } from '../static/destroySubscribers';
import { CatchAllErrors } from '../static/errors';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { SignalRHelperService } from './signal-rhelper.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
@DestroySubscribers({
  destroyFunc: 'ngOnDestroy'
})
export class ChatService implements OnDestroy {
  public hubConnection?: signalR.HubConnection;

  public currentChat?: number;
  public chats: ChatClass[] = [];

  public totalUnreadMessages = 0;

  public allowMessagesRequest = false;

  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public auth: AuthService,
    private signalRHelper: SignalRHelperService,
    public api: ApiService,
    private router: Router,
    private utils: UtilsService
  ) { }

  private createHub(): void {
    if (!this.hubConnection) {
      const hubParams: signalR.IHttpConnectionOptions = {
        accessTokenFactory: () => this.auth?.accessToken,
        logMessageContent: false,
        logger: 6,
      };
      this.hubConnection = this.signalRHelper.buildHub(
        environment.API + '/MessageHub',
        hubParams
      );
      this.registerSignalEvents();
    }
  }

  public startConnection = async () => { 
    await this.utils.waitFor(() => !this.auth.isInitialising);

    this.createHub();

    if (this.signalRHelper.isHubDisconnected(this.hubConnection) && this.auth.isLoggedIn) {
      this.auth.handleRefresh().pipe(
        switchMap(() => this.hubConnection.start()),
        catchError(() => this.signalRHelper.reconnectOnNetworkAvailable(this.startConnection))
      ).subscribe(() => {
        console.log('Message Hub Connected');
        this.loadChats();
      }, error => console.log(error));
    }
  }

  public newChat(user: UserInfo): void {
    const chat = this.findChatByUser(user.id);
    if (chat) {
      this.router.navigate(['/chat', chat.id]);
    } else {
      this.router.navigate(['/chat', 'new'], {queryParams: user})
    }
  }

  private registerSignalEvents = () => {
    this.registerReceiveConversations();
    this.registerReceiveMessages();
    this.registerOnClose();
    this.refreshChatDataPeriodically();
  }

  private registerReceiveConversations = () => {
    this.hubConnection.on('RecieveConversations', (data: ChatFromSocket[]) => {
      let totalUnread = 0;

      for (let index = 0; index < data.length; index++) {
        const chat = data[index];

        if (!this.chats.some(e => e.id === chat.conversation.id)) {
          this.chats.push(new ChatClass({...chat.conversation, online: chat.online}, this.auth, this.api))
        } else {
          const index = this.chats.findIndex(chatClass => chatClass.id === chat.conversation.id);
          this.chats[index].online = chat.online;
        }

        totalUnread += chat.conversation.unread;
      }
      this.totalUnreadMessages = totalUnread;
    });
  }

  private registerReceiveMessages = () => {
    this.hubConnection.on('RecieveMessage', async (message: Message) => {
      if (!message?.id) { return; }

      const chat = this.findChatById(message.chatId);
      if (chat) {
        chat.messages.push(message);

        if (!this.currentChat) {
          chat.unread++;
          this.totalUnreadMessages = this.totalUnreadMessages + 1;
        } else {
          if (!chat?.id) {
            chat.id = message.chatId;
            chat.unread = 0;
          }
          if (this.currentChat) {
            this.readChat();
          }
        }
      }

      this.scrollToBottomChat();
      this.loadChats();
    });
  }

  @CatchAllErrors
  public async loadChats($event?: any): Promise<void> {
    await this.hubConnection.invoke('GetMessages');
    if ($event) { $event.target.complete(); }
  } 

  private registerOnClose = () => {
    this.hubConnection.onclose((err) => {
      this.signalRHelper.reconnectOnNetworkAvailable(this.startConnection).subscribe();
    });
  }

  private refreshChatDataPeriodically(): void {
    this.subscriber = interval(10000).pipe(
      filter(() => this.signalRHelper.isHubConnected(this.hubConnection) && this.allowMessagesRequest
      )
    ).subscribe(() => {
      this.loadChats();
    });
  }

  @CatchAllErrors
  public async readChat(): Promise<void> {
    if (this.currentChat) {
      await Promise.all([
        this.api.readMessagesByChat(this.currentChat).toPromise(),
        this.hubConnection.invoke('ReadMessages', this.auth.userId, this.currentChat)
      ]);
    }
  }

  get currentChatObject(): ChatClass {
    return this.chats.find(e => e.id === this.currentChat);
  }

  private findChatById(id: number): ChatClass {
    return this.chats.find(e => e.id === id);
  }

  public findChatByUser(id: string): ChatClass {
    return this.chats.find(e => e?.informationAboutChatBuddy?.id === id);
  }


  disconnect(): void {
    this.signalRHelper.destoryHub(this.hubConnection);
    this.currentChat = undefined;
    this.chats = [];
    this.totalUnreadMessages = 0;
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {}


  public scrollToBottomChat = () => {
    if (this.document.getElementById('CurrentChatScroll')) {
      setTimeout(() => {
        const currentChatScroll = this.document.getElementById('CurrentChatScroll');
        currentChatScroll.scrollTop = currentChatScroll.scrollHeight;
      }, 100);
    }
  }

}
