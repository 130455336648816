import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { retryWhen, tap } from 'rxjs/operators';
import { genericRetryStrategy } from './retry-strategy';
import { throwError, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private api: ApiService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(genericRetryStrategy({
        maxAttempts: 10
      })),
      tap(() => {}, (error: any) => {
        if (error instanceof HttpErrorResponse) {
          this.reportError(error);
          return throwError(error);
        }
      })
    );
  }

  reportError(error: HttpErrorResponse): void {
    if (this.shouldReport(error)) {
      this.api.logError(error).subscribe();
    }
  }

  shouldReport(error: HttpErrorResponse): boolean {
    if (
      !error.url.includes('topcar.co.uk') ||
      error.url.includes('/EmailAvailable') ||
      error.url.includes('/PhoneAvailable') ||
      error.url.includes('/NodeService') ||
      !environment.production
    ) {
      return false;
    }

    if (
      (error.status !== 401 && !error.url.includes('/Refresh')) ||
      error.url.includes('/Refresh')
    ) {
      return true;
    } else {
      return false;
    }
  }

}
