import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowserService } from 'src/app/services/in-app-browser.service';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.page.html',
  styleUrls: ['./warning-modal.page.scss'],
})
export class WarningModalPage implements OnInit {
  @Input() message: string;
  @Input() externalUrl?: string;
  @Input() externalUrlText?: string;

  constructor(private modalCtrl: ModalController, private iab: InAppBrowserService) { }

  ngOnInit() {
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  extenalLinkOpen(): void {
    this.iab.openCustomLink(this.externalUrl);
  }

}
