import { Equipment, Trim, Body } from "../interface/CarAdvert";
import * as stringSimilarity from 'string-similarity';
import { StoredDataService } from "../services/stored-data.service";

export class CarTrimClass {
    id: number;
    bodyId: number;
    body?: Body;
    name: string;
    specificationValues?: any;
    equipment: Equipment[];
    value?: number;
    specifications: {name: string; value: any}[];

    constructor(public trim: Trim, private commonData: StoredDataService) {
        this.id = trim.id;
        this.value = trim.id;
        this.bodyId = trim.bodyId;
        this.body = trim.body;
        this.name = trim.name;
        this.specificationValues = trim.specificationValues;
        this.equipment = trim.equipment;
        this.specifications = trim.specificationValues.map(e => {
            return {
              name: e.specification.name,
              value: e.value
            };
        });
    }

    get fuelType(): number | undefined {
        const fuelType = this.specifications.find(e => e.name === 'fuel type');
        if (fuelType) {
        const fuelTypeSimilarity = stringSimilarity.findBestMatch(fuelType.value, this.commonData.car.fuelTypes.map(e => e.name));
            if (fuelTypeSimilarity) {
                return this.commonData.car.fuelTypes[fuelTypeSimilarity.bestMatchIndex].id;
            }
        }
    }

    get power(): number | undefined {
        const power = this.specifications.find(e => e.name === 'power');
        if (power) {
          return power.value;
        }
    }

    get engineSize(): string | undefined {
        const engineSize = this.specifications.find(e => e.name === 'engine size');
        if (engineSize) {
          const liters = (Number(engineSize.value) * 0.001).toFixed(1);
    
          if (Number(liters) > 6.9) {
            return '7';
          } else {
            const literFound = this.commonData.car.engines.find(e => e.name.startsWith(liters));
            if (literFound) {
              return literFound.value;
            }
          }
        }
    }

    get drivetrain(): number | undefined {
        const drivetrain = this.specifications.find(e => e.name === 'drivetrain');
        if (drivetrain) {
          const drivetrainSimilarity = stringSimilarity.findBestMatch(drivetrain.value, this.commonData.car.drivetrains.map(e => e.name));
          if (drivetrainSimilarity) {
            return this.commonData.car.drivetrains[drivetrainSimilarity.bestMatchIndex].id;
          }
        }
    }

    get gearbox(): number | undefined {
        const gearbox = this.specifications.find(e => e.name === 'gearbox');
        if (gearbox) {
          const gearboxSimilarity = stringSimilarity.findBestMatch(gearbox.value, this.commonData.car.gearBoxes.map(e => e.name));
          if (gearboxSimilarity) {
            return this.commonData.car.gearBoxes[gearboxSimilarity.bestMatchIndex].id;
          }
        }
    }

    get acceleration(): string | undefined {
        const acceleration1 = this.specifications.find(e => e.name === 'acceleration 0 - 60 mph (calculated by auto-data.net)');
        const acceleration2 = this.specifications.find(e => e.name === 'acceleration 0 - 62 mph');
        const acceleration3 = this.specifications.find(e => e.name === 'acceleration 0 - 100 km/h');
    
        if (acceleration1) {
          return Number(acceleration1.value).toString();
        } else if (acceleration2) {
          return Number(acceleration2.value).toString();
        } else if (acceleration3) {
          return Number(acceleration3.value).toString();
        }
    }
}