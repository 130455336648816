import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'finance'
})
export class FinancePipe implements PipeTransform {

  transform(value: number, status: number): string {
    if (!value) {
      return 'N/A';
    } else {
      if (value <= 0) {
        return 'Contact seller';
      } else if (value > 0) {
        return '£' + new DecimalPipe('en-US').transform(value) + '/Month';
      }
    }
    return null;
  }

}
