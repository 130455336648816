import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'domain'
})
export class DomainPipe implements PipeTransform {

  transform(value: string): string {
    if (value.startsWith('http')) {
      return value;
    } else {
      return environment.API + (value.startsWith('/') ? value : ('/' + value));
    }
  }

}
