import { Component, OnInit } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { NotificationsHubService } from '../services/notifications-hub.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit {

  constructor(public chat: ChatService, public notificationsHub: NotificationsHubService) { }

  ngOnInit() {
  }

}
