import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  transform(value: number, reverseBuy = false): string {
    return value === 1 ? (!reverseBuy ? 'Private seller' : 'Private buyer') : 'Dealership';
  }

}
