import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'power'
})
export class PowerPipe implements PipeTransform {

  transform(value: number): string {
    return value === 0 || value < 0 ? '-' : value === 1 ? (value + ' horse') : (value + 'horses');
  }

}
