import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PickerController } from '@ionic/angular';
import { Option } from '../interface/Option';

@Injectable({
  providedIn: 'root'
})
export class PickerService {

  constructor(private pickerCtrl: PickerController) { }


  async openMinMaxPicker(options: Option[], formGroup: AbstractControl) {
    const params = options;
    const picker = await this.pickerCtrl.create({
      buttons: [{
        text: 'Cancel',
      },
      {
        text: 'Confirm',
        handler: (value) => {
          console.log(value);
          formGroup.patchValue({
            min: value.min.value,
            max: value.max.value
          });
        }
      }],
      columns: [
        {
          name: 'min',
          options: this.getColumnOptions(options)
        },
        {
          name: 'max',
          options: this.getColumnOptions(options)
        },
      ]
    });
    await picker.present();
  }

  getColumnOptions(numOptions: any[]) {
    let options = [{
      text: 'Any',
      value: null
    }];
    for (let index = 0; index < numOptions.length; index++) {
      const element = numOptions[index];
      options.push({
        text: element.name,
        value: element.value
      });
    }
    return options;
  }
}
