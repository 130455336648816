import { TotalCarCheckReportResponse } from 'src/app/interface/TotalCarCheckReportResponse';
 
export const carReport: TotalCarCheckReportResponse = {
  Id: null,
  Report: {
    BasicVehicleInformation: {
      AverageMileage: 54100,
      AverageMileagePerYear: 5400,
      Body: 'Coupe',
      Cc: '6208 cc',
      Colour: 'White',
      EngineNumber: 'Sample',
      Fuel: 'Petrol',
      HasVehicleAge: true,
      IsMileageAverageMessage: 'below average',
      IsUnderThreeYearsOld: false,
      Make: 'Sample',
      ManufacturedDate: '23072012',
      MileageBetweenLastMotPasses: 1864,
      Model: 'Sample',
      RegistrationPlateDate: '23 July 2012',
      UserEnteredV5CDate: null,
      UserEnteredV5CReference: null,
      UserEnteredVin: null,
      VehicleAge: '8 years 4 months',
      VehicleType: 'Car',
      VINEndsWith: '75',
      VINLastFourCharacters: '3675',
      VINMatches: null,
      VRM: 'Sample',
      WheelPlan: '2 Axle Rigid Body'
    },
    EnvironmentalInformation: {
      Co2LetterMarker: 'M',
      Co2OuputOfVehicle: '286',
      FuelEconomyDataModel: {
        CO2_Economy: '285',
        FuelCost12k_Economy: '4124',
        FuelDescription_Economy: 'Petrol',
        FuelEconomyDataExists: true,
        ImperialCombined_Economy: '23.2',
        ImperialExtraUrban_Economy: '33.2',
        ImperialUrban_Economy: '15.2',
        MetricCombined_Economy: '12.2',
        MetricExtraUrban_Economy: '8.5',
        MetricUrban_Economy: '18.6',
        Model_Economy: 'Sample',
        VehicleDescription_Economy: 'Sample',
        MetricLow_WLTP_Economy: null,
        MetricMedium_WLTP_Economy: null,
        MetricHigh_WLTP_Economy: null,
        MetricExtraHigh_WLTP_Economy: null,
        MetricCombined_WLTP_Economy: null,
        ImperialLow_WLTP_Economy: null,
        ImperialMedium_WLTP_Economy: null,
        ImperialHigh_WLTP_Economy: null,
        ImperialExtraHigh_WLTP_Economy: null,
        ImperialCombined_WLTP_Economy: null,
        CO2_WLTP_Economy: null,
        IsWldpScheme: false
      },
      HasCo2OutputOfVehicle: true,
      HasFuelEconomyDataModel: true
    },
    ExtendedVehicleInformation: {
      Bhp: '450 BHP',
      BodyStyle: 'Coupe',
      CylinderCount: '8 Cylinders',
      CylinderLayout: 'V',
      DoorCount: '2 Doors',
      DriveType: 'Rear Wheel Drive',
      DrivingPosition: 'RHD',
      EdiCode: '268154',
      EngineAlignment: 'Longitudinal',
      EngineCode: 'Not Available',
      EngineLocation: 'Front',
      EngineSize: '6.2 litres',
      EuroStatus: '5a',
      ExactCc: '6208 cc',
      FuelDelivery: 'Injection',
      FuelType: 'Petrol',
      GearsCount: '7 Gears',
      GrossWeight: '2160',
      InsuranceGroup: '46',
      KwOutputOfEngine: '336 Kw',
      ModelVersionWithStartAndEndDate: 'C204',
      SeatCount: '4 Seats',
      TransmissionType: 'Semi Automatic',
      TyreFront: 'Not Available',
      TyreRear: 'Not Available',
      ValveCount: '32 Valves',
      VehicleAccelerationMph: '4.4 Secs',
      VehicleHeight: '1391 mm',
      VehicleImageUrl: 'https://cdntcc.azureedge.net/home/VehicleImage/c-class-coupe-meyv-11c.jpg',
      VehicleLength: '4633 mm',
      VehicleMaxSpeedMph: '155 MPH',
      VehicleRecalls: null,
      VehicleSeats: '004',
      VehicleTypeDescription: 'Car',
      VehicleWidth: '1997 mm',
      WheelBaseOfVehicle: '2760 mm'
    },
    ImportantChecks: {
      Exported: false,
      ExportedDate: 'Not Available',
      HasMileageIssue: false,
      HasScrappedDate: false,
      HasVicTestResult: false,
      HasVrmChanges: true,
      Imported: false,
      ImportedDate: 'Not Available',
      ImportedFromNorthernIreland: false,
      IsStolen: false,
      IsWrittenOff: false,
      LastColourChangeDate: 'Not Available',
      LastColourCode: 'Not Available',
      MiaftrStolenVehicleDetails: {
        IsStolen: false,
        Make: null,
        Model: null,
        TheftDate: null,
        VIN: null,
        VRM: null,
        InsurerDetails: null
      },
      MileageIssueSummary: null,
      MotAndRoadTaxInformation: {
        AdditionalRateOfTaxEndDate: null,
        DateMotDue: '25 Nov 2020',
        DateRoadTaxDue: '01 Sep 2021',
        IsMOTDue: false,
        IsMOTNearExpiry: true,
        IsRoadTaxDue: false,
        IsRoadTaxNearExpiry: null,
        IsVehicleSORN: false,
        MotResultsSummary: {
          DvsaFirstMotDueDate: null,
          HasResults: true,
          MileageIssueIdentified: false,
          MileageIssueSummary: null,
          MotResults: [{
            AdvisoryNotices: null,
            AdvisoryNotices_V2: null,
            DateOfTest: '2019-11-26T17:01:40.001',
            HadMixedOdometerFormats: false,
            HasMileageIssue: false,
            MileageDifference: 1864,
            OdometerModel: {
              OdometerFormat: 'miles',
              OdometerReading: 40014,
              OdometerResultType: 'READ'
            },
            RefusalReasons: null,
            RefusalReasons_V2: null,
            Result: true,
            ResultText: 'Pass',
            TestDueDate: '0001-01-01T00:00:00',
            TestExpiryDate: '2020-11-25T00:00:00',
            TestNumber: 'Sample'
          }, {
            AdvisoryNotices: null,
            AdvisoryNotices_V2: null,
            DateOfTest: '2018-10-30T16:10:04.001',
            HadMixedOdometerFormats: false,
            HasMileageIssue: false,
            MileageDifference: 2389,
            OdometerModel: {
              OdometerFormat: 'miles',
              OdometerReading: 38150,
              OdometerResultType: 'READ'
            },
            RefusalReasons: null,
            RefusalReasons_V2: null,
            Result: true,
            ResultText: 'Pass',
            TestDueDate: '0001-01-01T00:00:00',
            TestExpiryDate: '2019-10-29T00:00:00',
            TestNumber: 'Sample'
          }, {
            AdvisoryNotices: null,
            AdvisoryNotices_V2: null,
            DateOfTest: '2017-10-28T13:09:33.001',
            HadMixedOdometerFormats: false,
            HasMileageIssue: false,
            MileageDifference: 1941,
            OdometerModel: {
              OdometerFormat: 'miles',
              OdometerReading: 35761,
              OdometerResultType: 'READ'
            },
            RefusalReasons: null,
            RefusalReasons_V2: null,
            Result: true,
            ResultText: 'Pass',
            TestDueDate: '0001-01-01T00:00:00',
            TestExpiryDate: '2018-10-27T00:00:00',
            TestNumber: 'Sample'
          }, {
            AdvisoryNotices: null,
            AdvisoryNotices_V2: null,
            DateOfTest: '2016-10-19T09:24:56.001',
            HadMixedOdometerFormats: false,
            HasMileageIssue: false,
            MileageDifference: 3327,
            OdometerModel: {
              OdometerFormat: 'miles',
              OdometerReading: 33820,
              OdometerResultType: 'READ'
            },
            RefusalReasons: null,
            RefusalReasons_V2: null,
            Result: true,
            ResultText: 'Pass',
            TestDueDate: '0001-01-01T00:00:00',
            TestExpiryDate: '2017-10-18T00:00:00',
            TestNumber: 'Sample'
          }, {
            AdvisoryNotices: null,
            AdvisoryNotices_V2: null,
            DateOfTest: '2015-07-21T14:43:02.001',
            HadMixedOdometerFormats: false,
            HasMileageIssue: false,
            MileageDifference: 0,
            OdometerModel: {
              OdometerFormat: 'miles',
              OdometerReading: 30493,
              OdometerResultType: 'READ'
            },
            RefusalReasons: null,
            RefusalReasons_V2: null,
            Result: true,
            ResultText: 'Pass',
            TestDueDate: '0001-01-01T00:00:00',
            TestExpiryDate: '2016-07-23T00:00:00',
            TestNumber: 'Sample'
          }]
        },
        RealDrivingEmissions: 'Not Available'
      },
      NoOfVrmChanges: 3,
      OriginalColourCode: 'Not Available',
      OutstandingFinanceDetails: {
        FinanceAgreements: undefined,
        HasOutstandingFinanceAgreements: false,
        HasOutstandingFinanceWarningMessages: false,
        IsOutstandingFinanceCheckIncluded: true,
        NumberOfOutstandingFinanceAgreements: 0,
        OutstandingFinanceWarningMessages: undefined
      },
      PoliceStolenVehicleDetails: {
        ChasisFrame: null,
        CreationDate: null,
        IsStolen: false,
        Make: null,
        Model: null,
        PoliceForce: null,
        ReportType: null,
        VRM: null
      },
      PreviousVrms: [{
        DateOfTransfer: '27 May 2014',
        DateOfTransferOff: '27 May 2014',
        DateOfTransferOffForDisplay: '27 May 2014',
        DateOfTransferOn: '23 July 2012',
        DateOfTransferOnForDisplay: '23 Jul 2012',
        PreviousVRM: 'Sample',
        Vin: null
      }, {
        DateOfTransfer: '05 April 2017',
        DateOfTransferOff: '05 April 2017',
        DateOfTransferOffForDisplay: '05 Apr 2017',
        DateOfTransferOn: '27 May 2014',
        DateOfTransferOnForDisplay: '27 May 2014',
        PreviousVRM: 'Sample',
        Vin: null
      }, {
        DateOfTransfer: '14 May 2018',
        DateOfTransferOff: '14 May 2018',
        DateOfTransferOffForDisplay: '14 May 2018',
        DateOfTransferOn: '05 April 2017',
        DateOfTransferOnForDisplay: '05 Apr 2017',
        PreviousVRM: 'Sample',
        Vin: null
      }],
      Scrapped: false,
      ScrappedDate: 'Not Available',
      Unscrapped: false,
      VehicleHadPreviousColours: false,
      VehiclePreviousColours: '0',
      VicTestDate: 'Not Available',
      VicTestResult: true,
      WrittenOffVehicleDetails: []
    },
    PreviousKeepersInformation: {
      CurrentOwnerAquiredDate: '10 May 2018',
      CurrentOwnershipDuration: '2 years 6 months',
      PreviousKeepers: '3',
      PreviousOwnerBoughtDate: '06 April 2017',
      PreviousOwnerSoldDate: '02 May 2018',
      V5CLogBookDate: '15/05/18',
      V5CLogBookDateMatches: null,
      V5CLogBookSerialNumberIsStolen: null,
      VehicleHasPreviousKeepers: true
    },
    ReportMetaData: {
      CustomerEmailAddress: null,
      DateOfCheck: '25 November 2020 21:20',
      ReferenceNumber: null,
      ReferrerId: 'XTX'
    },
    RoadTaxInformation: {
      HasRoadTaxInfo: true,
      SixMonthsRate: '319',
      TwelveMonthsRate: '580'
    },
    ValuationInformation: {
      HasValuationResults: false,
      Mileage: null,
      PlateYear: null,
      ShouldValuationBeIncluded: false,
      ValuationResults: null,
      VehicleDescription: null
    },
    ReportResult: {
      Message: 'Pass'
    }
  }
};

export const enquiryType = [{
    name: 'General inquiry',
    value: 'General inquiry'
}];

export const generalCondition = [{
        name: 'New',
        value: 'New'
    },
    {
        name: 'Used',
        value: 'Used'
    }
];

export const martialStatus = [{
        name: 'Single',
        value: 'Single',
    },
    {
        name: 'Married',
        value: 'Married',
    },
    {
        name: 'Widowed',
        value: 'Widowed',
    },
    {
        name: 'Separated',
        value: 'Separated',
    },
    {
        name: 'Divorced',
        value: 'Divorced',
    }
];

export const title = [{
        name: 'Mr',
        value: 'Mr'
    },
    {
        name: 'Ms',
        value: 'Ms',
    },
    {
        name: 'Mrs',
        value: 'Mrs',
    },
    {
        name: 'Miss',
        value: 'Miss',
    },
    {
        name: 'Doctor',
        value: 'Doctor',
    },
    {
        name: 'Judge',
        value: 'Judge',
    },
    {
        name: 'Professor',
        value: 'Professor',
    },
    {
        name: 'Councillor',
        value: 'Councillor',
    },
    {
        name: 'Madam',
        value: 'Madam',
    },
    {
        name: 'Mr & Mrs',
        value: 'Mr & Mrs',
    },
    {
        name: 'Rev',
        value: 'Rev',
    },
    {
        name: 'Sir',
        value: 'Sir',
    },
    {
        name: 'Major',
        value: 'Major',
    },
    {
        name: 'See Questions',
        value: 'See Questions',
    },
    {
        name: 'Lord',
        value: 'Lord',
    },
    {
        name: 'Lady',
        value: 'Lady',
    },
    {
        name: 'Prince',
        value: 'Prince',
    },
    {
        name: 'Dr',
        value: 'Dr',
    },
    {
        name: 'Eng',
        value: 'Eng',
    },
    {
        name: 'Lt Col',
        value: 'Lt Col',
    },
    {
        name: 'AirComm',
        value: 'AirComm',
    },
    {
        name: 'AirMarsh',
        value: 'AirMarsh',
    },
    {
        name: 'Archbishop',
        value: 'Archbishop',
    },
    {
        name: 'Archdeacon',
        value: 'Archdeacon',
    },
    {
        name: 'Baron',
        value: 'Baron',
    },
    {
        name: 'Baroness',
        value: 'Baroness',
    },
    {
        name: 'Bishop',
        value: 'Bishop',
    },
    {
        name: 'Bombadier',
        value: 'Bombadier',
    },
    {
        name: 'BrigGen',
        value: 'BrigGen',
    },
    {
        name: 'Brigadier',
        value: 'Brigadier',
    },
    {
        name: 'Brother',
        value: 'Brother',
    },
    {
        name: 'Cadet',
        value: 'Cadet',
    },
    {
        name: 'Canon',
        value: 'Canon',
    },
    {
        name: 'Captain',
        value: 'Captain',
    },
    {
        name: 'Cardinal',
        value: 'Cardinal',
    },
    {
        name: 'Chancellor',
        value: 'Chancellor',
    },
    {
        name: 'Chief',
        value: 'Chief',
    },
    {
        name: 'ChiefJust',
        value: 'ChiefJust',
    },
    {
        name: 'Colonel',
        value: 'Colonel',
    },
    {
        name: 'ColSgt',
        value: 'ColSgt',
    },
    {
        name: 'Commander',
        value: 'Commander',
    },
    {
        name: 'Commodore',
        value: 'Commodore',
    },
    {
        name: 'Constable',
        value: 'Constable',
    },
    {
        name: 'Corporal',
        value: 'Corporal',
    },
    {
        name: 'Count',
        value: 'Count',
    },
    {
        name: 'Countess',
        value: 'Countess',
    },
    {
        name: 'Dame',
        value: 'Dame',
    },
    {
        name: 'Deacon',
        value: 'Deacon',
    },
    {
        name: 'Deaconess',
        value: 'Deaconess',
    },
    {
        name: 'Dowager',
        value: 'Dowager',
    },
    {
        name: 'Duchess',
        value: 'Duchess',
    },
    {
        name: 'Duke',
        value: 'Duke',
    },
    {
        name: 'Earl',
        value: 'Earl',
    },
    {
        name: 'Father',
        value: 'Father',
    },
    {
        name: 'FldMarsh',
        value: 'FldMarsh',
    },
    {
        name: 'FlightLt',
        value: 'FlightLt',
    },
    {
        name: 'FltSgt',
        value: 'FltSgt',
    },
    {
        name: 'FlOfficer',
        value: 'FlOfficer',
    },
    {
        name: 'Frau',
        value: 'Frau',
    },
    {
        name: 'Fraulein',
        value: 'Fraulein',
    },
    {
        name: 'Fusilier',
        value: 'Fusilier',
    },
    {
        name: 'General',
        value: 'General',
    },
    {
        name: 'GrpCapt',
        value: 'GrpCapt',
    },
    {
        name: 'Guardsman',
        value: 'Guardsman',
    },
    {
        name: 'Gunner',
        value: 'Gunner',
    },
    {
        name: 'Herr',
        value: 'Herr',
    },
    {
        name: 'Inspector',
        value: 'Inspector',
    },
    {
        name: 'JusticeCL',
        value: 'JusticeCL',
    },
    {
        name: 'JusticeGN',
        value: 'JusticeGN',
    },
    {
        name: 'LanceBmdr',
        value: 'LanceBmdr',
    },
    {
        name: 'LanceCorp',
        value: 'LanceCorp',
    },
    {
        name: 'LieutCdr',
        value: 'LieutCdr',
    },
    {
        name: 'LieutCol',
        value: 'LieutCol',
    },
    {
        name: 'LieutGen',
        value: 'LieutGen',
    },
    {
        name: 'Lieutenant',
        value: 'Lieutenant',
    },
    {
        name: 'Madame',
        value: 'Madame',
    },
    {
        name: 'Mdmoiselle',
        value: 'Mdmoiselle',
    },
    {
        name: 'MajorGen',
        value: 'MajorGen',
    },
    {
        name: 'Mrchioness',
        value: 'Mrchioness',
    },
    {
        name: 'Marquess',
        value: 'Marquess',
    },
    {
        name: 'Marquis',
        value: 'Marquis',
    },
    {
        name: 'Marshal',
        value: 'Marshal',
    },
    {
        name: 'Master',
        value: 'Master',
    },
    {
        name: 'Matron',
        value: 'Matron',
    },
    {
        name: 'Mipshipman',
        value: 'Mipshipman',
    },
    {
        name: 'Mistress',
        value: 'Mistress',
    },
    {
        name: 'Monsieur',
        value: 'Monsieur',
    },
    {
        name: 'Monsignor',
        value: 'Monsignor',
    },
    {
        name: 'Mother',
        value: 'Mother',
    },
    {
        name: 'Nurse',
        value: 'Nurse',
    },
    {
        name: 'Officer',
        value: 'Officer',
    },
    {
        name: 'Pastor',
        value: 'Pastor',
    },
    {
        name: 'PC',
        value: 'PC',
    },
    {
        name: 'PtyOffcr',
        value: 'PtyOffcr',
    },
    {
        name: 'PltOffcr',
        value: 'PltOffcr',
    },
    {
        name: 'Princess',
        value: 'Princess',
    },
    {
        name: 'Private',
        value: 'Private',
    },
    {
        name: 'Rabbi',
        value: 'Rabbi',
    },
    {
        name: 'RearAdmr',
        value: 'RearAdmr',
    },
    {
        name: 'Rifleman',
        value: 'Rifleman',
    },
    {
        name: 'Sapper',
        value: 'Sapper',
    },
    {
        name: 'Senor',
        value: 'Senor',
    },
    {
        name: 'Senora',
        value: 'Senora',
    },
    {
        name: 'Senorina',
        value: 'Senorina',
    },
    {
        name: 'Sergeant',
        value: 'Sergeant',
    },
    {
        name: 'Signor',
        value: 'Signor',
    },
    {
        name: 'Signora',
        value: 'Signora',
    },
    {
        name: 'Signorina',
        value: 'Signorina',
    },
    {
        name: 'Sister',
        value: 'Sister',
    },
    {
        name: 'SqnLeader',
        value: 'SqnLeader',
    },
    {
        name: 'StaffSgt',
        value: 'StaffSgt',
    },
    {
        name: 'SubLieut',
        value: 'SubLieut',
    },
    {
        name: 'Superintd',
        value: 'Superintd',
    },
    {
        name: 'TheHon',
        value: 'TheHon',
    },
    {
        name: 'TheRtHon',
        value: 'TheRtHon',
    },
    {
        name: 'Trooper',
        value: 'Trooper',
    },
    {
        name: 'Vicar',
        value: 'Vicar',
    },
    {
        name: 'ViceAdmr',
        value: 'ViceAdmr',
    },
    {
        name: 'Viscount',
        value: 'Viscount',
    },
    {
        name: 'Viscountss',
        value: 'Viscountss',
    },
    {
        name: 'Warrntoff',
        value: 'Warrntoff',
    },
    {
        name: 'WingCmdr',
        value: 'WingCmdr',
    },
    {
        name: 'WPC',
        value: 'WPC',
    },
    {
        name: 'Dom',
        value: 'Dom',
    },
    {
        name: 'Deputy',
        value: 'Deputy',
    },
    {
        name: 'Rt. Honourable',
        value: 'Rt. Honourable',
    },
    {
        name: 'Venerable',
        value: 'Venerable',
    },
    {
        name: 'Sheikh',
        value: 'Sheikh',
    },
];

export const cardType = [{
        name: 'Visa',
        value: 'Visa'
    },
    {
        name: 'Mastercard',
        value: 'Mastercard'
    },
    {
        name: 'American Express',
        value: 'American Express'
    },
    {
        name: 'Diners Club',
        value: 'Diners Club'
    },
    {
        name: 'Discover',
        value: 'Discover'
    },
    {
        name: 'JCB',
        value: 'JCB'
    },
    {
        name: 'UnionPay',
        value: 'UnionPay'
    },
    {
        name: 'Maestro',
        value: 'Maestro'
    },
    {
        name: 'Mir',
        value: 'Mir'
    },
    {
        name: 'Elo',
        value: 'Elo'
    },
    {
        name: 'Hiper',
        value: 'Hiper'
    },
    {
        name: 'Hipercard',
        value: 'Hipercard'
    },
];

export const employmentStatus = [{
        name: 'Employed',
        value: 'Employed'
    },
    {
        name: 'Self-employed',
        value: 'Self-employed'
    },
    {
        name: 'Out of work',
        value: 'Out of work'
    },
    {
        name: 'Homemaker',
        value: 'Homemaker'
    },
    {
        name: 'Student',
        value: 'Student'
    },
    {
        name: 'Retired',
        value: 'Retired'
    },
    {
        name: 'Unable to work',
        value: 'Unable to work'
    },
];

export const sellerType = [{
        id: 1,
        name: 'Private seller',
        value: 1
    },
    {
        id: 2,
        name: 'Dealer',
        value: 2
    }
];

export const owners = [{
        id: 1,
        name: 'New',
        value: 0
    },
    {
        id: 2,
        name: '1 owner',
        value: 1
    },
    {
        id: 3,
        name: '1-2 owners',
        value: 2
    },
    {
        id: 4,
        name: '3+ owners',
        value: 3
    },
    {
        id: 5,
        name: 'Unlisted',
        value: 4
    }
];

export const mileage = [{
        id: 1,
        name: '100 miles',
        value: 100
    },
    {
        id: 2,
        name: '500 miles',
        value: 500
    },
    {
        id: 3,
        name: '5,000 miles',
        value: 5000
    },
    {
        id: 4,
        name: '10,000 miles',
        value: 10000
    },
    {
        id: 5,
        name: '15,000 miles',
        value: 15000
    },
    {
        id: 6,
        name: '20,000 miles',
        value: 20000
    },
    {
        id: 7,
        name: '25,000 miles',
        value: 25000
    },
    {
        id: 8,
        name: '30,000 miles',
        value: 30000
    },
    {
        id: 9,
        name: '35,000 miles',
        value: 35000
    },
    {
        id: 10,
        name: '40,000 miles',
        value: 40000
    },
    {
        id: 11,
        name: '45,000 miles',
        value: 45000
    },
    {
        id: 12,
        name: '50,000 miles',
        value: 50000
    },
    {
        id: 13,
        name: '60,000 miles',
        value: 60000
    },
    {
        id: 14,
        name: '70,000 miles',
        value: 70000
    },
    {
        id: 15,
        name: '80,000 miles',
        value: 80000
    },
    {
        id: 16,
        name: '90,000 miles',
        value: 90000
    },
    {
        id: 17,
        name: '100,000 miles',
        value: 100000
    },
    {
        id: 18,
        name: '125,000 miles',
        value: 125000
    },
    {
        id: 19,
        name: '150,000 miles',
        value: 150000
    },
    {
        id: 20,
        name: '200,000 miles',
        value: 200000
    },
];

export const booleanResponse = [{
        id: 1,
        name: 'Yes',
        value: true
    },
    {
        id: 2,
        name: 'No',
        value: false
    }
];

export const pageSize = [{
        id: 0,
        name: '12',
        value: 12
    },
    {
        id: 1,
        name: '20',
        value: 20
    },
    {
        id: 2,
        name: '40',
        value: 40
    },
    {
        id: 3,
        name: '60',
        value: 60
    },
    {
        id: 4,
        name: '80',
        value: 80
    },
    {
        id: 5,
        name: '100',
        value: 100
    },
];

export const sort = [{
        id: null,
        name: 'Relevance',
        value: 'Relevance'
    },
    {
        id: 2,
        name: 'Selling price (from highest to lowest)',
        value: 2
    },
    {
        id: 1,
        name: 'Selling price (from lowest to highest)',
        value: 1
    },
    /*
    {
      id: 4,
      name: 'Rent price (from highest to lowest)',
      value: 4
    },
    {
      id: 3,
      name: 'Rent price (from lowest to highest)',
      value: 3
    },
    */
    {
        id: 10,
        name: 'Finance price (from highest to lowest)',
        value: 10
    },
    {
        id: 9,
        name: 'Finance price (from lowest to highest)',
        value: 9
    },
    {
        id: 6,
        name: 'Year (from newest to oldest)',
        value: 6
    },
    {
        id: 5,
        name: 'Year (from oldest to newest)',
        value: 5
    },
    {
        id: 8,
        name: 'Mileage reading (from highest to lowest)',
        value: 8
    },
    {
        id: 7,
        name: 'Mileage reading (from lowest to highest)',
        value: 7
    },
];

export const sortReverse = [{
        id: null,
        name: 'Relevance',
        value: 'Relevance'
    },
    {
        id: 1,
        name: 'Selling price (from highest to lowest)',
        value: 1
    },
    {
        id: 2,
        name: 'Selling price (from lowest to highest)',
        value: 2
    },
    {
        id: 3,
        name: 'Mileage reading (from highest to lowest)',
        value: 3
    },
    {
        id: 4,
        name: 'Mileage reading (from lowest to highest)',
        value: 4
    },
];

export const statusOptions = [{
        name: 'All',
        value: null
    },
    {
        name: 'New',
        value: 1
    },
    {
        name: 'Used',
        value: 2
    }
];

export const pricingOptions = [{
        name: 'Price',
        value: 'Price'
    },
    {
        name: 'Finance',
        value: 'Finance'
    }
];

export const accountHolderType = [{
    name: 'Individual',
    value: 'individual'
},
{
    name: 'Company',
    value: 'company'
}];

export const currency = [{
    name: 'GBP',
    value: 'gbp'
}];
export const defaultCurrency = currency[0].value;

export const country = [{
    name: 'United Kingdom',
    value: 'gb'
}];
export const defaultCountry = country[0].value;