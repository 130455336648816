import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'causeOfDamage'
})
export class CauseOfDamagePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return 'N/A';
    } else {
      switch (value) {
        case 'A':
          return 'Accident';
        case 'F':
          return 'Fire';
        case 'W':
          return 'Water';
        case 'V':
          return 'Vandalism';
        default:
          return 'Unknown';
      }
    }
  }


}
