import { timer, throwError, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export type ShouldRetryFn = ({ status: number }) => boolean;

export interface RetryParams {
  maxAttempts?: number;
  scalingDuration?: number;
  shouldRetry?: ShouldRetryFn;
}

const defaultParams: RetryParams = {
  maxAttempts: 20,
  scalingDuration: 600,
  shouldRetry: ({ status }) => status === 502 || status === 503 || status === 504 || status === 408 || status === 429 || status === 0
};

export const genericRetryStrategy = (params: RetryParams = {}) => (attempts: Observable<any>) => attempts.pipe(
  mergeMap((error, i) => {
    const { maxAttempts, scalingDuration, shouldRetry } = { ...defaultParams, ...params };
    const retryAttempt = i + 1;

    if (retryAttempt > maxAttempts || !shouldRetry(error)) {
      return throwError(error);
    }
    console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);

    return timer(retryAttempt * scalingDuration);
  })
);
