import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { Plugins } from '@capacitor/core';
import { CatchAllErrors, errors } from '../static/errors';
import { MapsAPILoader } from '@agm/core';
import { from, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
const { Geolocation, Device } = Plugins;

interface LocationInterface {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private geocoder: google.maps.Geocoder;

  constructor(
    private diagnostic: Diagnostic,
    private locationAccuracy: LocationAccuracy,
    private toast: Toast,
    private nativeGeocoder: NativeGeocoder,
    private mapLoader: MapsAPILoader
  ) {
    Device.getInfo().then(info => {
      if (info.platform === 'android' || info.platform === 'ios') {
        this.initialiseGeocoding();
      }
    });
  }

  private async initialiseGeocoding(): Promise<boolean> {
    try {
      const isLocationAvailable: boolean = await this.diagnostic.isLocationAvailable();
      if (!isLocationAvailable) return false;

      const isLocationEnabled: boolean = await this.diagnostic.isLocationEnabled();
      const canRequest: boolean = await this.locationAccuracy.canRequest();
  
      if (canRequest && !isLocationEnabled) {
        await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
      }
  
      return true;
    } catch(err) {
      console.log(err);
      return false;
    }
  }

  @CatchAllErrors
  public async requestCurrentLocation(): Promise<LocationInterface | void> {
    const isLocationReady: boolean = await this.initialiseGeocoding();

    if (isLocationReady) {
      const geo = await Geolocation.getCurrentPosition();
      return {
        lat: geo.coords.latitude,
        lng: geo.coords.longitude
      };
    } else {
      this.toast.show(`Information regarding location is unavailble. Please, check your device settings`, '5000', 'center').toPromise();
    }
  }

  @CatchAllErrors
  public async reverseGeocode(geo: LocationInterface): Promise<string> {
    const info = await Device.getInfo();
    if (info.platform === 'android' || info.platform === 'ios') {
      let options: NativeGeocoderOptions = {
        useLocale: true,
        maxResults: 1
      };
    
      const result: NativeGeocoderResult[] = await this.nativeGeocoder.reverseGeocode(geo.lat, geo.lng, options);
      return result[0]?.postalCode;
    } else if (info.platform === 'web') {
      await this.initialiseWebGoogleServices().toPromise();
      return this.reverseGeocodeUsingWebGoogleServices(geo);
    }
    
  }

  @CatchAllErrors
  public async geocode(address: string, countryCode: string = 'UK'): Promise<{lat: string; lng: string}> {
    const info = await Device.getInfo();
    if (!address) {
      throw new Error(errors.noPostcodeProvided);
    }
    if (info.platform === 'android' || info.platform === 'ios') {
      let options: NativeGeocoderOptions = {
        useLocale: true,
        maxResults: 1
      };
    
      const result: NativeGeocoderResult[] = await this.nativeGeocoder.forwardGeocode(address + ', ' + countryCode, options);
      return {lat: result[0]?.latitude, lng: result[0]?.longitude};
    } else if (info.platform === 'web') {
      await this.initialiseWebGoogleServices().toPromise();
      return this.geocodeUsingWebGoogleServices(address + ', ' + countryCode);
    }
    
  }

  private initWebGeocoder(): void {
    this.geocoder = new google.maps.Geocoder();
  }

  private initialiseWebGoogleServices(): Observable<boolean> {
    if (!this.geocoder) {
      return from(this.mapLoader.load())
      .pipe(
        tap(() => this.initWebGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }

  private geocodeUsingWebGoogleServices(address: string): Promise<{lat: string; lng: string}> {
    return new Promise(async (resolve, reject) => {

      this.geocoder.geocode({address}, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status == google.maps.GeocoderStatus.OK) {
          resolve({
            lat: results[0].geometry.location.lat().toString(),
            lng: results[0].geometry.location.lng().toString()
          });
        } else {
          console.log('Error - ', results, ' & Status - ', status);
          reject(status);
        }
      });
    });
  }

  private reverseGeocodeUsingWebGoogleServices(geo: LocationInterface): Promise<string> {
    return new Promise(async (resolve, reject) => {

      this.geocoder.geocode({location: geo}, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status == google.maps.GeocoderStatus.OK) {
          resolve(results[0]?.formatted_address);
        } else {
          console.log('Error - ', results, ' & Status - ', status);
          reject(status);
        }
      });
    });
  }
}
