import { Injectable } from '@angular/core';
import { CarClass } from 'src/app/classes/CarClass';
import { CarAdvert } from 'src/app/interface/CarAdvert';
import { StoredDataService } from '../stored-data.service';

@Injectable({
  providedIn: 'root'
})
export class CompareCarsService {
  comparedCarIds: number[] = [];

  constructor(
    private storage: StoredDataService
  ) {
    this.getSavedCars().then(cars => this.comparedCarIds = cars.map(e => e.id));
  }

  public isCarCompared(id: number): boolean {
    return this.comparedCarIds.some(e => e === id);
  }

  public async addCarToCompare(item: CarClass): Promise<void> {
    const comparedCars = await this.getSavedCars();
    await this.storage.setParameter('ComparedCars', [...comparedCars, item.car]);
    this.comparedCarIds = [...this.comparedCarIds, item.id];
  }

  public async removeCarFromCompare(item: CarClass): Promise<void> {
    const comparedCars = await this.getSavedCars();
    await this.storage.setParameter('ComparedCars', comparedCars.filter(e => e.id !== item.id));
    this.comparedCarIds = this.comparedCarIds.filter(e => e !== item.id);
  }

  public async getSavedCars(): Promise<CarAdvert[]> {
    const comparedCars = await this.storage.getComparedCars();
    return comparedCars;
  }

  public toggleCar(item: CarClass, $event?: Event): void {
    if ($event) { $event.stopPropagation(); }
    if (this.isCarCompared(item.id)) {
      this.removeCarFromCompare(item);
    } else {
      this.addCarToCompare(item);
    }
  }
}
