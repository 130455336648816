import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { SearchClass } from 'src/app/classes/SearchClass';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { autoRefresh$, trackById } from 'src/app/static/utils';

@Component({
  selector: 'app-liked-cars',
  templateUrl: './liked-cars.page.html',
  styleUrls: ['./liked-cars.page.scss'],
})
export class LikedCarsPage extends SearchClass implements OnInit {
  private savedCarsSubject$ = new BehaviorSubject(undefined);
  public savedCars$ = combineLatest([
    autoRefresh$,
    this.savedCarsSubject$
  ]).pipe(
    switchMap(() => this.auth.isLoggedIn ? this.api.getSavedCars() : of({adverts: [], total: 0}).pipe(take(1)))
  );

  trackById = trackById;
  
  constructor(public auth: AuthService, private api: ApiService) {
    super();
  }

  ngOnInit(): void {
    // this.doRefresh();
  }

  doRefresh($event?: any): void {
    this.api.getSavedCars().subscribe(cars => {
      this.savedCarsSubject$.next(cars);
      if ($event) { $event.target.complete(); }
    });
  }

  

}
