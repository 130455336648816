import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Option } from '../../interface/Option';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.page.html',
  styleUrls: ['./select-modal.page.scss'],
})
export class SelectModalPage implements OnInit {
  @Input() title: string;
  @Input() options: Option[];
  @Input() type: 'list' | 'grid';
  @Input() formControl: FormControl;
  @Input() multiple: boolean = false;
  @Input() showDefaultOption = true;

  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  select(option: Option): void {
    const {value} = this.formControl;
    this.formControl.patchValue(this.multiple ? [...(value || []), option.value] : option.value);
  }
  
  reset(): void {
    this.formControl.reset();
  }

  close(): void {
    this.modal.dismiss();
  }

  getName(option: Option): string {
    if (option.startYear) {
      return `${option.startYear} - ${option.endYear ? option.endYear : 'now'} ${option.name ? ('(' + option.name + ')') : ''}`
    } else {
      return option.name;
    }
  }

  isChecked(option: Option): boolean {
    const {value} = this.formControl;
    if (Array.isArray(value) && value.includes(option.value)) {
      return true;
    } else if (value === option.value) {
      return true;
    } else {
      return false;
    }
  }

  isDefaultChecked(): boolean {
    const {value} = this.formControl;
    return (Array.isArray(value) && value.length === 0) || value === null || value === undefined;
  }
}
