import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-account-navigation',
  templateUrl: './account-navigation.page.html',
  styleUrls: ['./account-navigation.page.scss'],
})
export class AccountNavigationPage implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

}
