import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
const { Network } = Plugins;
@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private handler: any;
  public status = 'online';
  public status$ = new BehaviorSubject('online');

  constructor() {}

  public initialiseNetworkListeners() {
    this.handler = Network.addListener('networkStatusChange', (status) => {
      this.status = status.connected ? 'online' : 'offline';
      this.status$.next(this.status);
    });
  }

  public destroyNetworkListeners(): void {
    this.handler.remove();
  }
}
