import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokensResponse } from '../interface/AuthTokensResponse';
import { Brand, BodyType, FuelType, GearBox, Transmission, CarAdvert } from '../interface/CarAdvert';
import { ResponseWithHexFormatted } from '../interface/CommonResponses';
import { UserInfo } from '../interface/User';
import { CatchAllErrors } from '../static/errors';
import { ApiService } from './api.service';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StoredDataService {
  public colours: ResponseWithHexFormatted[] = [];
  public car: {
    brands: Brand[];
    brandsWithPopular: any[];
    bodyTypes: BodyType[];
    fuelTypes: FuelType[];
    gearBoxes: GearBox[];
    drivetrains: Transmission[];
    engines: any[];
  } = {
    brands: [],
    brandsWithPopular: [],
    bodyTypes: [],
    fuelTypes: [],
    gearBoxes: [],
    drivetrains: [],
    engines: [],
  };
  public year: any[] = [];
  public prices: any[] = [];
  public finance: any[] = [];
  public distance = [{id: 0, name: 'National', value: 'National'}];
  public acceleration: any[] = [];

  constructor(
    private api: ApiService
  ) {
    this.initialise();
  }

  @CatchAllErrors
  async initialise(): Promise<void> {
    const commonDataStorage = await Storage.get({key: 'CommonData'});
    const commonData = JSON.parse(commonDataStorage.value);

    if (commonData) {
      this.car.brands = commonData.car.brands;
      this.car.brandsWithPopular = commonData.car.brandsWithPopular;
      this.api.getBrands().subscribe(value => {
        this.car.brands = value;
        this.car.brandsWithPopular[0] = {name: 'Popular brands', options: this.car.brands.filter(e => e.popular)};
        this.car.brandsWithPopular[1] = {name: 'All brands', options: this.car.brands};
        this.car.brandsWithPopular = this.car.brandsWithPopular;
      });
      this.car.bodyTypes = commonData.car.bodyTypes;
      this.car.fuelTypes = commonData.car.fuelTypes;
      this.car.gearBoxes = commonData.car.gearBoxes;
      this.car.drivetrains = commonData.car.drivetrains;
      this.car.engines = commonData.car.engine;
      this.colours = commonData.colour;
      this.year = commonData.year;
      this.distance = commonData.distance;
      this.acceleration = commonData.acceleration;
      this.prices = commonData.price;
      this.finance = commonData.finance;
    } else {
      this.loadData();
    }
  }

  @CatchAllErrors
  private async loadData() {
    const results = await Promise.all([
      this.api.getBrands().toPromise(),
      this.api.getBodyTypes().toPromise(),
      this.api.getFuelTypes().toPromise(),
      this.api.getGearboxes().toPromise(),
      this.api.getTransmissions().toPromise(),
      this.api.getColours().toPromise()
    ]);
    this.car.brands = results[0];
    this.car.brandsWithPopular[0] = {name: 'Popular brands', options: results[0].filter(e => e.popular)};
    this.car.brandsWithPopular[1] = {name: 'All brands', options: results[0]};
    this.car.brandsWithPopular = this.car.brandsWithPopular;
    this.car.bodyTypes = results[1];
    this.car.fuelTypes = results[2];
    this.car.gearBoxes = results[3];
    this.car.drivetrains = results[4];
    this.colours = results[5];

    this.generateYears();
    this.generateDistance();
    this.generateCarEngine();
    this.generateAcceleration();
    this.generatePrice();
    this.generateFinance();

    Storage.set({key: 'CommonData', value: JSON.stringify({
      car: {
        brands: this.car.brands,
        brandsWithPopular: this.car.brandsWithPopular,
        bodyTypes: this.car.bodyTypes,
        fuelTypes: this.car.fuelTypes,
        gearBoxes: this.car.gearBoxes,
        drivetrains: this.car.drivetrains,
        engine: this.car.engines,
      },
      colour: this.colours,
      year: this.year,
      distance: this.distance,
      acceleration: this.acceleration,
      price: this.prices,
      finance: this.finance
    })});
    Storage.set({key: 'CommonDataCachedOn', value: new Date().toString()});
  }

  private generateYears = () => {
    const currentYear = new Date().getFullYear();
    for (let i = 0; 50 > i; i++) {
      this.year.push(
        {
          id: i + 1,
          name: '' + (currentYear - i),
          value: currentYear - i
        }
      );
    }
  }

  private generateDistance = () => {
    for (let i = 0; 101 > i; i++) {
      this.distance.push(
        {
          id: i + 1,
          name: `within ${i * 5} miles`,
          value: Number(i * 5 * 1609.34).toFixed(0)
        }
      );
    }
  }

  private generateCarEngine = () => {
    for (let i = 0; 6.9 > i; i += 0.1) {

      this.car.engines.push(
        {
          id: i + 1,
          name: `${Number(i + 0.1).toFixed(1) === '7.0' ? '7+' : Number(i + 0.1).toFixed(1)} l`,
          value: Number(i + 0.1).toFixed(1) === '7.0' ? 7 : Number(i + 0.1).toFixed(1)
        }
      );
    }
  }

  private generateAcceleration = () => {
    for (let i = 0; 20 > i; i += 0.1) {
      this.acceleration.push(
        {
          id: i + 1,
          name: `${Number(i + 0.1).toFixed(1)} s`,
          value: Number(i + 0.1).toFixed(1)
        }
      );
    }
  }

  private generatePrice = () => {
    const decimalPipe = new DecimalPipe('en');
    for (let i = 1; 101 > i; i++) {
      this.prices.push({
        id: i + 1,
        name: decimalPipe.transform(i * 500, '', 'en'),
        value: i * 500
      });
    }

    for (let i = 2; 11 > i; i++) {
      this.prices.push({
        id: this.prices.length + 1,
        name: decimalPipe.transform(i * 50000, '', 'en'),
        value: i * 50000
      });
    }

    for (let i = 2; 5 > i; i++) {
      this.prices.push({
        id: this.prices.length + 1,
        name: decimalPipe.transform(i * 500000, '', 'en'),
        value: i * 500000
      });
    }

  }

  private generateFinance = () => {
    const decimalPipe = new DecimalPipe('en');

    for (let i = 1; 4 > i; i++) {
      this.finance.push({
        id: i + 1,
        name: decimalPipe.transform(i * 25, '', 'en'),
        value: i * 25
      });
    }
    for (let i = 2; 10 > i; i++) {
      this.finance.push({
        id: this.finance.length + 1,
        name: decimalPipe.transform(i * 50, '', 'en'),
        value: i * 50
      });
    }
    for (let i = 5; 16 > i; i++) {
      this.finance.push({
        id: this.finance.length + 1,
        name: decimalPipe.transform(i * 100, '', 'en'),
        value: i * 100
      });
    }
  }

  public async authParams(): Promise<AuthTokensResponse> {
    const {value} = await Storage.get({key: 'authParams'});
    return value ? JSON.parse(value) : undefined;
  }

  public async userData(getAccount: Observable<UserInfo>): Promise<UserInfo> {
    let userData = JSON.parse((await Storage.get({key: 'userData'})).value);
    return userData || getAccount.toPromise();
  }

  public setParameter(key: string, value: any): Promise<any> {
    return Storage.set({key, value: JSON.stringify(value)});
  }

  public async clearSensitiveData(): Promise<any> {
    await Storage.clear();
    return await this.loadData();
  }

  public async getComparedCars(): Promise<CarAdvert[]> {
    const {value} = await Storage.get({key: 'ComparedCars'});
    return value ? JSON.parse(value) : [];
  }
  
}
