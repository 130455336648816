import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { AllChatsPageRoutingModule } from './all-chats-routing.module';

import { AllChatsPage } from './all-chats.page';
import { CommonKitModule } from 'src/app/kit/common-kit/common-kit.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    CommonKitModule,
    AllChatsPageRoutingModule
  ],
  declarations: [AllChatsPage]
})
export class AllChatsPageModule {}
