import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopPropogationDirective } from './stop-propogation.directive';
import { LocationBackDirective } from './location-back.directive';



@NgModule({
  declarations: [StopPropogationDirective, LocationBackDirective],
  imports: [
    CommonModule
  ],
  exports: [StopPropogationDirective, LocationBackDirective]
})
export class DirectiveKitModule { }
