import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceRatingPipe } from './price-rating.pipe';
import { DomainPipe } from './domain.pipe';
import { SuffixPipe } from './suffix.pipe';
import { YesOrNoPipe } from './yes-or-no.pipe';
import { FallbackPipe } from './fallback.pipe';
import { AccelerationPipe } from './acceleration.pipe';
import { BeautifyPhoneNumberPipe } from './beautify-phone-number.pipe';
import { CauseOfDamagePipe } from './cause-of-damage.pipe';
import { EnginePipe } from './engine.pipe';
import { FileReadPipe } from './file-read.pipe';
import { FilterByNamePipe } from './filter-by-name.pipe';
import { FilterByObjectToStringPipe } from './filter-by-object-to-string.pipe';
import { FilterOutByIdPipe } from './filter-out-by-id.pipe';
import { FinancePipe } from './finance.pipe';
import { FindInArrayOfObjectsPipe } from './find-in-array-of-objects.pipe';
import { ImagePipe } from './image.pipe';
import { MileagePipe } from './mileage.pipe';
import { OwnerPipe } from './owner.pipe';
import { PowerPipe } from './power.pipe';
import { PricePipe } from './price.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { StatusPipe } from './status.pipe';
import { UrlEncodePipe } from './url-encode.pipe';
import { UserTypePipe } from './user-type.pipe';
import { VideoUrlToIframeUrlPipe } from './video-url-to-iframe-url.pipe';
import { WebsitePipe } from './website.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { InitialsPipe } from './initials.pipe';



@NgModule({
  declarations: [PriceRatingPipe, DomainPipe, SuffixPipe, YesOrNoPipe, FallbackPipe,
    MileagePipe,
    OwnerPipe,
    FinancePipe,
    PricePipe,
    PowerPipe,
    AccelerationPipe,
    YesNoPipe,
    EnginePipe,
    ImagePipe,
    StatusPipe,
    SafeUrlPipe,
    VideoUrlToIframeUrlPipe,
    FindInArrayOfObjectsPipe,
    UserTypePipe,
    UrlEncodePipe,
    FilterOutByIdPipe,
    WebsitePipe,
    FilterByNamePipe,
    FilterByObjectToStringPipe,
    FileReadPipe,
    BeautifyPhoneNumberPipe,
    CauseOfDamagePipe,
    InitialsPipe],
  imports: [
    CommonModule
  ],
  exports: [
    PriceRatingPipe,
    DomainPipe,
    SuffixPipe,
    YesOrNoPipe,
    FallbackPipe,
    MileagePipe,
    OwnerPipe,
    FinancePipe,
    PricePipe,
    PowerPipe,
    AccelerationPipe,
    YesNoPipe,
    EnginePipe,
    ImagePipe,
    StatusPipe,
    SafeUrlPipe,
    VideoUrlToIframeUrlPipe,
    FindInArrayOfObjectsPipe,
    UserTypePipe,
    UrlEncodePipe,
    FilterOutByIdPipe,
    WebsitePipe,
    FilterByNamePipe,
    FilterByObjectToStringPipe,
    FileReadPipe,
    BeautifyPhoneNumberPipe,
    CauseOfDamagePipe,
    InitialsPipe
  ]
})
export class PipeKitModule { }
 