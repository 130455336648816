import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { CommonKitModule } from '../common-kit/common-kit.module';
import { SelectComponent } from './select/select.component';
import { MinMaxSelectComponent } from './min-max-select/min-max-select.component';
import { InputComponent } from './input/input.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TogglerComponent } from './toggler/toggler.component';
import { TextareaComponent } from './textarea/textarea.component';



@NgModule({
  declarations: [ImageUploaderComponent, SelectComponent, MinMaxSelectComponent, InputComponent, CheckboxComponent, TogglerComponent, TextareaComponent],
  imports: [
    CommonModule,
    CommonKitModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ImageUploaderComponent,
    SelectComponent,
    MinMaxSelectComponent,
    InputComponent,
    CheckboxComponent,
    TogglerComponent,
    TextareaComponent
  ]
})
export class FormKitModule { }
