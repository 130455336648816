import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByObjectToString'
})
export class FilterByObjectToStringPipe implements PipeTransform {

  transform(data: any[], value: string): any[] {
    if (!value) {
      return data;
    }
    return data.filter(e => JSON.stringify(e).toUpperCase().includes(value.toUpperCase()));
  }

}
