import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AddCardModalPage } from '../modals/add-card-modal/add-card-modal.page';
import { BrandSelectModalPage } from '../modals/brand-select-modal/brand-select-modal.page';
import { CarSearchFilterModalPage } from '../modals/car-search-filter-modal/car-search-filter-modal.page';
import { SuccessModalPage } from '../modals/success-modal/success-modal.page';
import { VerifyForOnlinePaymentFormModalPage } from '../modals/verify-for-online-payment-form-modal/verify-for-online-payment-form-modal.page';
import { VerifyForOnlinePaymentModalPage } from '../modals/verify-for-online-payment-modal/verify-for-online-payment-modal.page';
import { WarningModalPage } from '../modals/warning-modal/warning-modal.page';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalCtrl: ModalController) { }

  async openBrandSelect(page: typeof BrandSelectModalPage): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: page,
      cssClass: 'select-brand-modal-css'
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openFilterSelect(page: typeof CarSearchFilterModalPage): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: page,
      cssClass: 'car-search-modal-css'
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openSuccess(message: string): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SuccessModalPage,
      cssClass: 'success-modal-css',
      componentProps: {
        message
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openWarning(message: string, externalUrl?: string, externalUrlText?: string): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: WarningModalPage,
      cssClass: 'warning-modal-css',
      componentProps: {
        message,
        externalUrl,
        externalUrlText
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openPaymentVerificationMessage(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: VerifyForOnlinePaymentModalPage,
      cssClass: 'verify-for-online-modal-css'
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openPaymentVerificationFormMessage(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: VerifyForOnlinePaymentFormModalPage,
      cssClass: 'verify-for-online-form-modal-css'
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  public async openAddCard(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AddCardModalPage,
      cssClass: 'add-card-modal-css'
    });
    await modal.present();
    await modal.onWillDismiss();
  }
}
