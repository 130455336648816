import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { CarSearchFilterModalPageRoutingModule } from './car-search-filter-modal-routing.module';

import { CarSearchFilterModalPage } from './car-search-filter-modal.page';
import { FormKitModule } from 'src/app/kit/form-kit/form-kit.module';
import { CommonKitModule } from 'src/app/kit/common-kit/common-kit.module';

@NgModule({
  imports: [
    CommonModule,
    CommonKitModule,
    FormKitModule,
    IonicModule,
    CarSearchFilterModalPageRoutingModule
  ],
  declarations: [CarSearchFilterModalPage],
  entryComponents: [CarSearchFilterModalPage]
})
export class CarSearchFilterModalPageModule {}
