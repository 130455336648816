import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beautifyPhoneNumber'
})
export class BeautifyPhoneNumberPipe implements PipeTransform {

  transform(phoneNumber: string): string {
    return phoneNumber.replace('+44', '0').replace(/\s+/g, '').replace(/(.)(\d{4})(\d)/, '+44 ($1)$2 $3');
  }

}
