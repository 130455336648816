import { Component, OnInit } from '@angular/core';
import { BrandSelectModalPage } from 'src/app/modals/brand-select-modal/brand-select-modal.page';
import { CarSearchFilterModalPage } from 'src/app/modals/car-search-filter-modal/car-search-filter-modal.page';
import { ApiService } from 'src/app/services/api.service';
import { SearchCarService } from 'src/app/services/car/search-car.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  newCars$ = this.api.getShuffleCars(4, 1);
  usedCars$ = this.api.getShuffleCars(4, 2);

  constructor(
    private api: ApiService,
    public carSearch: SearchCarService,
    public modal: ModalService
  ) { }

  ngOnInit() {}

  searchByType(typeId: number): void {
    this.carSearch.formGroup.controls.typeId.patchValue(typeId);
    this.carSearch.search(true);
  }

  openFilter(): void {
    this.modal.openFilterSelect(CarSearchFilterModalPage);
  }

  openBrandPicker(): void {
    this.modal.openBrandSelect(BrandSelectModalPage);
  }

}
