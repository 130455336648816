import { Component, OnDestroy } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NetworkService } from './services/network.service';
import { NotificationsHubService } from './services/notifications-hub.service';
import { AuthService } from './services/auth.service';
import { ChatService } from './services/chat.service';
import { CombineSubscriptions, DestroySubscribers } from './static/destroySubscribers';
import { Unsubscribable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { StoredDataService } from './services/stored-data.service';
import { UtilsService } from './services/utils.service';
import { ModalService } from './services/modal.service';
import { messages } from './static/messages';
import { ApiService } from './services/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
@DestroySubscribers({
  destroyFunc: 'destroy'
})
export class AppComponent implements OnDestroy {
  @CombineSubscriptions()
  private subscriber!: Unsubscribable;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private network: NetworkService,
    private notificationHub: NotificationsHubService,
    private chat: ChatService,
    private auth: AuthService,
    private router: Router,
    private storage: StoredDataService,
    private utils: UtilsService,
    private modal: ModalService,
    private api: ApiService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    if (this.utils.getParamValueFromQueryString('onbording') === 'success') {
      this.api.completeOnlinePaymentVerification().subscribe(() => {
        this.auth.userData$.subscribe(() => {
          this.modal.openSuccess(messages.verificationSuccess);
        });
      }, error => {
        this.api.requestLinkForOnlinePaymentVerification().subscribe(response => {
          this.modal.openWarning(messages.verificationError, response.url, messages.continueWithVerification);
        });

      });
    }
    this.platform.ready().then(() => {
      this.attachRouterListener();
      this.network.initialiseNetworkListeners();
      this.initialiseAuthenticatedServices();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async initialiseAuthenticatedServices(): Promise<void> {
    await this.utils.waitFor(() => !this.auth.isInitialising);
    if (this.auth.isLoggedIn) {
      this.notificationHub.startConnection();
      this.chat.startConnection();
    }
  }

  private attachRouterListener() {
    this.subscriber = this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        this.checkIfLoggedIn();
      }
    })
  }

  private async checkIfLoggedIn(): Promise<void> {
    const authParams = await this.storage.authParams();
    if (!authParams) {
      this.chat.disconnect();
      this.notificationHub.disconnect();
    } else {
      this.initialiseAuthenticatedServices();
    }
  }

  destroy(): void {}

  ngOnDestroy(): void {
    this.network.destroyNetworkListeners();
    this.notificationHub.disconnect();
    this.chat.disconnect();
    this.destroy();
  }
}
