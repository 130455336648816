import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrandSelectModalPageModule } from './modals/brand-select-modal/brand-select-modal.module';
import { CarSearchFilterModalPageModule } from './modals/car-search-filter-modal/car-search-filter-modal.module';
import { TabsPageModule } from './tabs/tabs.module';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => TabsPageModule
  },
  {
    path: 'car',
    loadChildren: () => import('./scenes/car/car.module').then(m => m.CarModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./scenes/documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./scenes/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'seller',
    loadChildren: () => import('./scenes/seller/seller.module').then(m => m.SellerModule)
  },
  {
    path: 'trade-in',
    loadChildren: () => import('./scenes/trade-in/trade-in.module').then(m => m.TradeInModule)
  },
  {
    path: 'finance',
    loadChildren: () => import('./scenes/finance/finance.module').then(m => m.FinanceModule)
  },
  {
    path: 'compare',
    loadChildren: () => import('./scenes/compare/compare.module').then(m => m.CompareModule)
  },
  { 
    path: 'auth',
    loadChildren: () => import('./scenes/auth/auth.module').then(m => m.AuthModule)
  },
  { 
    path: 'account',
    loadChildren: () => import('./scenes/account/account.module').then(m => m.AccountModule)
  },
  { 
    path: 'chat',
    loadChildren: () => import('./scenes/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: '',
    redirectTo: 'tabs/home',
    pathMatch: 'full'
  },
  {
    path: 'account-navigation',
    loadChildren: () => import('./scenes/account-navigation/account-navigation.module').then( m => m.AccountNavigationPageModule)
  },
  {
    path: 'all-chats',
    loadChildren: () => import('./scenes/all-chats/all-chats.module').then( m => m.AllChatsPageModule)
  },
  {
    path: 'pay-for-report-modal',
    loadChildren: () => import('./modals/pay-for-report-modal/pay-for-report-modal.module').then( m => m.PayForReportModalPageModule)
  },
  {
    path: 'change-profile-modal',
    loadChildren: () => import('./modals/change-profile-modal/change-profile-modal.module').then( m => m.ChangeProfileModalPageModule)
  },
  {
    path: 'create-or-edit-appointment-modal',
    loadChildren: () => import('./modals/create-or-edit-appointment-modal/create-or-edit-appointment-modal.module').then( m => m.CreateOrEditAppointmentModalPageModule)
  },
  {
    path: 'add-card-modal',
    loadChildren: () => import('./modals/add-card-modal/add-card-modal.module').then( m => m.AddCardModalPageModule)
  },
  {
    path: 'create-vehicle-payment-modal',
    loadChildren: () => import('./modals/create-vehicle-payment-modal/create-vehicle-payment-modal.module').then( m => m.CreateVehiclePaymentModalPageModule)
  },
  {
    path: 'pay-for-vehicle-payment-modal',
    loadChildren: () => import('./modals/pay-for-vehicle-payment-modal/pay-for-vehicle-payment-modal.module').then( m => m.PayForVehiclePaymentModalPageModule)
  },
  {
    path: 'car-search-filter-modal',
    loadChildren: () => CarSearchFilterModalPageModule
  },
  {
    path: 'brand-selectt-modal',
    loadChildren: () => BrandSelectModalPageModule
  },
  {
    path: 'select-modal',
    loadChildren: () => import('./modals/select-modal/select-modal.module').then( m => m.SelectModalPageModule)
  },
  {
    path: 'finance-request-modal',
    loadChildren: () => import('./modals/finance-request-modal/finance-request-modal.module').then( m => m.FinanceRequestModalPageModule)
  },
  {
    path: 'trade-in-request-modal',
    loadChildren: () => import('./modals/trade-in-request-modal/trade-in-request-modal.module').then( m => m.TradeInRequestModalPageModule)
  },
  {
    path: 'verify-for-online-payment-modal',
    loadChildren: () => import('./modals/verify-for-online-payment-modal/verify-for-online-payment-modal.module').then( m => m.VerifyForOnlinePaymentModalPageModule)
  },
  {
    path: 'verify-for-online-payment-form-modal',
    loadChildren: () => import('./modals/verify-for-online-payment-form-modal/verify-for-online-payment-form-modal.module').then( m => m.VerifyForOnlinePaymentFormModalPageModule)
  },
  {
    path: 'success-modal',
    loadChildren: () => import('./modals/success-modal/success-modal.module').then( m => m.SuccessModalPageModule)
  },
  {
    path: 'warning-modal',
    loadChildren: () => import('./modals/warning-modal/warning-modal.module').then( m => m.WarningModalPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
