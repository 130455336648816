import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'owner'
})
export class OwnerPipe implements PipeTransform {

  transform(value: number, status: number, reverseBuy?: boolean): unknown {
    if (status === 1) {
      return 'No previous owners';
    } else {
      if (!value || value <= 0) {
        return reverseBuy ? 'No previous owners' : 'Contact seller';
      } else if (value > 0) {
        return value + ' ' + (value === 1 ? 'owner' : 'owners');
      }
    }
    return null;
  }

}
