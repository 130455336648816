import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { LoadingClass } from 'src/app/classes/LoadingClass';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { InAppBrowserService } from 'src/app/services/in-app-browser.service';
import { ModalService } from 'src/app/services/modal.service';
import { messages } from 'src/app/static/messages';

@Component({
  selector: 'app-verify-for-online-payment-modal',
  templateUrl: './verify-for-online-payment-modal.page.html',
  styleUrls: ['./verify-for-online-payment-modal.page.scss'],
})
export class VerifyForOnlinePaymentModalPage extends LoadingClass implements OnInit, OnDestroy {

  interval: any;

  constructor(private modalCtrl: ModalController, private iab: InAppBrowserService, private api: ApiService, private auth: AuthService, private modal: ModalService, loadingCtrl: LoadingController) {
    super(loadingCtrl);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
  
  close(): void {
    this.modalCtrl.dismiss();
  }

  verifyForPayment(): void {
    const windowReference: any = window.open("about:blank","_blank");
    this.api.requestLinkForOnlinePaymentVerification().subscribe(response => {
      windowReference.location = response.url;
      this.interval = setInterval(() => {
        this.auth.userData$.subscribe(account => {
          if (account.stripeOnboardingComplete) {
            this.close();
            this.modal.openSuccess(messages.verificationSuccess);
            clearInterval(this.interval);
          }
        });
      }, 5000);
    });
  }
}
