import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileRead'
})
export class FileReadPipe implements PipeTransform {

  transform(value: any): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        // convert image file to base64 string
        resolve(reader.result.toString());
      }, false);

      if (value) {
        reader.readAsDataURL(value);
      }
    });
  }

}
